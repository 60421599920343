.terms-conditions-page {
	.list-unstyled {
		li {
			margin-bottom: 30px;
			&::before{
				content: unset !important;
			} 

			i {
				display: inline;
				color: #33bea6;
				font-size: 25px;
			}
			p {
				display: inline;
				padding-left: 10px;
			}
		}
	}
}