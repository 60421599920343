.aboutus-page {
	.about-us-row{
		margin-top: 20px;
		.about-left-image{
			position: relative;
            z-index: 2;
            border-radius: 20px;
            box-shadow: 0px 3px 30px 0px rgba(0,0,0,.11);
			overflow: hidden;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.about-us-page-desc{
			padding-left: 50px;
			.desc{
			color: $paragraph-color;
            font-size: 16px;
            line-height: 1.6;
			font-weight: 500;
			margin-bottom: 25px;
			}
		}
	}
}