.store-owner-top-products-category-section {
    margin-left: 0;
    margin-top: 30px;
    background: #f8f7fc5e;
    > .card {
      background: transparent;
      box-shadow: none;
      margin-top: 0;
      box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
      border-radius: 6px;
      padding: 15px;
    }
    .card-header {
      padding: 0;
      .store-owner-top-products-category-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        .nav-item {
          margin-right: 10px;
          min-width: 140px;
          .nav-link {
            font-family: "Poppins";
            font-weight: 600;
            padding: 10px 20px 10px 20px;
            color: $title-color-dark;
            box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
            justify-content: center;
            text-align: center;
            position: relative;
            overflow: hidden;
            border: transparent !important;
            font-size: 13.5px;
            background: #f8f7fc;
            cursor: pointer;
            span {
              color: #242f67;
              i{
                margin-right: 5px;
              }
            }
            &::before {
              height: 100%;
              width: 0;
              left: 0;
              top: 0;
              content: "";
              bottom: 1px;
              display: block;
              position: absolute;
              transition: 0.35s;
              transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
              z-index: 0;
              background-color: $details-btn !important;
            }
            &:hover,
            &:active,
            &:visited {
              border: transparent !important;
            }
          }
          .nav-link.active {
            background-image: none !important;
            color: #fff;
            border-color: unset !important;
            background: unset;
            span {
              z-index: 2;
              color: #fff;
              position: relative;
            }
            &::before {
              transform: scale(1);
              background-color: $title-color-dark !important;
              width: 100%;
            }
          }
        }
      }
    }
    .card-body {
      background: $white;
      margin-top: 20px;
      .store-owner-top-products-category-content .tab-pane.active {
        animation: tabs-fade 0.5s cubic-bezier(0.58, 0.3, 0.15, 0.46);
      }
    }
}

//top products card design
.store-owner-top-products-category-sec{
    background: #f8f7fc5e;
    box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
    border-radius: 6px;
    padding: 15px 20px;
    .title{
        color: $logo-color;
        font-size: 14px;
        font-family: "Poppins";
        line-height: 1.2;
        margin-bottom: 20px;
        text-transform:  capitalize;
    }
    .store-owner-top-products-category-inner-sec{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        .store-owner-top-products-category-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: $white;
            border: 1px solid #f8f7fc;
            min-height: 60px;
            margin-bottom: 15px;
            width: 100%;
            padding: 5px 10px;
            .top-products-category-img{
                height: 65px;
            }
            .top-products-category-title{
                color: $paragraph-color;
                font-size: 16px;
                font-family: "Poppins";
                line-height: 1.2;
                margin: 0 10px;
            }
            .top-products-category-amount{
                color: $sixth-color;
                font-size: 15px;
                font-family: "Poppins";
                line-height: 1.2;
                font-weight: 600;
                margin: 0 10px;
                display: inline-flex;
                background: #4498ff2e;
                padding: 5px 10px;
                border-radius: 30px;
            }

        }
    }
}



@keyframes tabs-fade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }