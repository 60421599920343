.details-table-sec {
    .order-search-sec{
        margin-bottom: 20px;
        .MuiInput-underline {
            &::after {
                border-bottom-color: $title-color-dark;
            }
            &::before {
                border-bottom-color: $title-color-dark;
                // border-width: 2px;
            }
        }
        input{
            font-weight: 500;
            color: $title-color-dark;
            font-family: "Poppins";
            &::placeholder{
                font-weight: 500;
                color: $title-color-dark;
                font-family: "Poppins";
            }
        }
    }
    .details-data-card {
        .order-img {
            float: left;
            img {
                width: 60px;
                height: 60px;
                margin-right: 10px;
            }
        }
        .order-name {
            display: inline-flex;
            padding: 20px 0;
        }
    }
    .order-details-table{
        thead{
            th{
                min-width: 100px;
            }
        }
    }
}
