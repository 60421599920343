.modal .Product-info-card-modal{
    transform: translate(0, 10%) !important;
}
.modal.show .Product-info-card-modal{
    transform: translate(0, 10%);
}
.Product-info-card-modal{
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12);
    max-width: 65%;
    max-height: 80vh;
    .product-info-modal-header{
        .product-info-modal-title{
            position: relative;
            color: #34B9A9;
            padding-left: 35px;
            &:before{
                content: '';
                position: absolute;
                width: 35px;
                height: 2.5px;
                background-image: linear-gradient(120deg, #3ddcc2 0%, #07a088 100%);
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            >span{
                color: $fifth-color;
                padding-left: 5px;
            }
        }
        .close{
            border: 1px solid #fb6e51;
            padding: 0;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            color: #fb6e51;
        }
    }
    .product-info-modal-body{
        margin-top: 10px;
        .modal-item-desc{
            max-height: 59vh;
            overflow-y: auto;
            .product-delete-alert{
                margin: 10px 0 0 10px;
                display: flex;
                align-items: center;
                transition: 2s ease;
                p{
                    color: #f36a5d;
                    font-size: 15px;
                    line-height: 1.4;
                    font-weight: 500;
                    font-family: 'Poppins';
                    margin-left: 10px;
                }
                &:before{
                    content: "\ea17";
                    font: bold normal normal 15px "Nucleo";
                    color: #f36a5d; 
                }
            }
            .title-with-prize{
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                justify-content: center;
                .title{
                    font-size: 21px;
                    color: $fifth-color;
                    line-height: 1.3;
                    font-family: 'Poppins';
                    text-transform: capitalize;
                    margin-bottom: 0;
                }
            }
            .remove-notification-sec{
                height: 27px;
            }
            .color-available-sec{
                .title{
                    color: $nine-color;
                    font-size: 14.5px;
                    line-height: 1.5;
                    margin-bottom: 10px;
                    font-family: 'Poppins';
                    position: relative;
                    margin-left: 10px;
                    margin-top: 10px !important;
                    &:before{
                        content: '';
                        width: 40px;
                        height: 2px;
                        background: #09bda0;
                        position: absolute;
                        bottom: -4px;
                        left: 2px;
                    }
                }
                .check-subtitle{
                    color: $fifth-color;
                    font-size: 14px;
                    line-height: 1.5;
                    margin-bottom: 10px;
                    font-weight: 500;
                    text-transform: lowercase;
                    margin-top: 5px;
                    margin-left: 10px;
                    .product-img-check{
                        height: 20px;
                        display: inline-block;
                        padding: 0 4px;
                    }
                }
                .color-lists-with-short-image{
                    padding-left: 0;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    list-style: none;
                    .empty-msg{
                        margin-left: 13px;
                        color: $fifth-color;
                        font-size: 15px;
                        font-weight: 400;
                        margin: 12px 0 0 10px;
                    }
                    .color-item{
                        width: calc(20% - 25px);
                        border: 1px solid #85919a1f;
                        padding: 15px;
                        border-radius: 4px;
                        margin: 12.5px;
                        position: relative;
                        cursor: pointer;
                        box-shadow: 0 2px 10px rgba(0,0,0,0.1);
                        .custom-checkbox-design-sec{
                            position: absolute;
                            right: 10px;
                            top: 10px;
                            @include Check_all;
                            .custom-checkbox-label{
                                > span{
                                    margin-right: 0 !important;
                                }
                            }
                            .custom-checkbox-design:checked + .custom-checkbox-label > span:before{
                                top: -2px;
                                left: 2px;
                            }
                        }
                        .short-image{
                            margin-bottom: 10px;
                            img{
                                height: 110px;
                                margin: 8px auto 5px;
                            }
                        }
                        .color-image-with-title{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img{
                                width: 23px;
                                height: 23px;
                                border-radius: 50%;
                                object-fit: fill;
                            }
                            .color-name{
                                color: $fifth-color;
                                font-size: 13.5px;
                                font-weight: 600;
                                line-height: 1.2;
                                margin-left: 5px;
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
        }
    }
    .product-info-modal-footer{
        margin-top: 12px;
        .modal-add-to-product{
            font-size: 16.5px;
            border-radius: 30px;
            @include button_green;
            i{
                margin-right: 15px;
                font-weight: 600;
            }
        }
    }
}