.video-section {
    .video-content {
        padding: 28px 120px 28px 0;
        .video-data {
            p {
                font-size: 20px;
                color: #2c2c2c;
                margin-bottom: 30px;
                line-height: 1.5;
                font-weight: 400;
            }
        }
        .video-title {
            margin-bottom: 10px;
            strong {
                font-size: 30px;
                font-weight: bold;
            }
        }
        .video-button {
            .btn {
                background: -moz-linear-gradient(
                    90deg,
                    rgba(253, 78, 117, 1) 0%,
                    rgba(252, 120, 88, 1) 100%
                );
                background: -webkit-gradient(
                    linear,
                    left top,
                    left bottom,
                    color-stop(0%, rgba(252, 120, 88, 1)),
                    color-stop(100%, rgba(253, 78, 117, 1))
                );
                background: -webkit-linear-gradient(
                    90deg,
                    rgba(253, 78, 117, 1) 0%,
                    rgba(252, 120, 88, 1) 100%
                );
                background: -o-linear-gradient(
                    90deg,
                    rgba(253, 78, 117, 1) 0%,
                    rgba(252, 120, 88, 1) 100%
                );
                background: -ms-linear-gradient(
                    90deg,
                    rgba(253, 78, 117, 1) 0%,
                    rgba(252, 120, 88, 1) 100%
                );
                background: linear-gradient(
                    0deg,
                    rgba(253, 78, 117, 1) 0%,
                    rgba(252, 120, 88, 1) 100%
                );
                padding: 20px 40px;
                font-size: 20px;
                font-weight: 500;
                color: #fff;
                border-radius: 32px;
                -webkit-border-radius: 32px;
                -moz-border-radius: 32px;
            }
        }
    }
}
