.customer-shipping-address-section{
    .all-customers-shipping-address-card{
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
        //animation: tabs-fade .5s cubic-bezier(0.58, 0.3, 0.15, 0.46);
        animation-name: bottomToTop;
        animation-duration: 1s;
    }
    .shipping-card{
        background: #fff;
        box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 #185ee026;
        margin-bottom: 10px;
        margin-top: 10px;
        border: 1px solid #185ee026;
        display: flex;
        flex-wrap: wrap;
        height: 93%;
        .shipping-card-card-content{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .shipping-card-thumb{
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                img{
                    height: 22px;
                    width: auto;
                    object-fit: cover;
                    margin-right: 5px;
                }
                .shipping-card-title{
                    font-family: 'poppins';
                    font-size: 16.5px;
                    font-weight: 600;
                    color: $title-color-dark;
                    display: flex;
                    text-transform: capitalize;
                    align-items: center;
                    flex-wrap: wrap;
                    width: calc(100% - 30px);
                    
                    .material_checkbox_term{
                        background: none;
                        cursor: unset;
                        margin-left: auto;
                        img{
                            margin: 0;
                        }
                    }
                }
            }
            .shipping-card-desc{
                .zip{
                    background: #f8f7fc;
                    color: #141414;
                    display: inline-block;
                    margin: 15px 0px;
                    font-size: 14px;
                    padding: 3px 10px;
                    font-weight: 600;
                    font-family: 'Poppins';
                    border-radius: 30px;
                }
                .other {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 2px 0;
                    line-height: 1.6;
                    span{
                        color: $paragraph-color;
                        display: inline-flex;
                        margin: 0 2px;
                        font-size: 14px;
                        text-transform: capitalize;
                    }
                    &:last-child{
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .shipping-card-footer{
            border-top: 1px solid #9595958c;
            display: flex;
            align-items: center;
            padding-top: 10px;
            margin-bottom: -5px;
            width: 100%;
            .shipping-btn-sec{
                width: 80px;
                margin: 0 auto;
                .btn.shipping-card-delete-btn,
                .btn.shipping-card-manage-btn {
                    width: 35px;
                    padding: 0;
                    height: 35px;
                    margin-right: 5px;
                    background: unset;
                    color: $black-color;
                    background: $details-btn;
                    border-radius: 25px;
                    margin: 2px !important;
                    i {
                        padding: 12px;
                    }
                }
                .btn.shipping-card-manage-btn {
                    &:hover {
                        background-image: none !important;
                        background: #238be0d1 !important;
                        color:$white;
                        box-shadow: 1px 2px 5px 0px #52b0fd6b;
                    }
                    &:active, &:focus{
                        color: $black-color;
                        background: $details-btn !important;
                    }
                }
                .btn.shipping-card-delete-btn {
                    &:hover {
                        background-image: none !important;
                        background-color: #f44336d6 !important;
                        color: $white;
                        box-shadow: 1px 2px 5px 0px #f4cac29c;
                    }
                    &:active, &:focus{
                        color: $black-color;
                        background: $details-btn !important;
                    }
                }
            }
            .shipping-btn-sec.set-as-default-sec{
                margin: unset;
            }
            .set-as-default{
                font-size: 11px;
                background: #ffb10061;
                display: inline-block;
                padding: 3px 5px;
                cursor: pointer;
                border-radius: 3px;
                color: #141414;
                margin-left: auto;
            }
        }
    }
    .shipping-card.active{
        background: #ffe9b72e;
        box-shadow: 0 0 1px 0 #ffe9b799, 0 1px 3px 0 #e4b85a7a;
        border: 1px solid #ffae0061;
        .shipping-card-card-content{
            .shipping-card-title{
                color: #141414;
            }
        }
        .shipping-btn-sec{
            text-align: center;
        }
    }
    .shipping-new-col{
        align-self: stretch;
        >.shipping-card{
            height: 93%;
        }
        .add-new-content{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            height: 100%;
            img{
                height: 110px;
                width: auto;
                margin: 0 auto;
    
            }
            .shipping-card-new-addr-btn{
                @include button_blue;
                margin: 10px 0;
                font-size: 14px;
                text-transform: capitalize;
                border-radius: 30px;
            }
        }
    }
}
@keyframes bottomToTop {
    from{
      opacity: 0;
      transform: translateY(200%);
    }
    to{
      opacity: 1;
      transform: translateY(0);
    }
}

@import "./personal-info.scss";
@import "./new-shipping-address.scss";