//button style for green

@mixin button_green{
    display: flex !important;
    align-items: center;
    color: $fifth-color !important;
    font-weight: 600;
    background-image: linear-gradient(120deg, #27ccb2 0%, #09bda0 100%) !important;
    box-shadow: 3px 6px 10px 0px #13b0973d;
    transition: 0.3 ease;
    &:hover,
    &:active,
    &:focus{
        background-color: unset !important;
        background-image: linear-gradient(120deg, #27ccb2 0%, #09bda0 100%) !important;
        box-shadow: 3px 6px 10px 0px #13b0973d !important;
        border-color: transparent !important;
        color: $fifth-color;
    }
}