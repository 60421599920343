.style-section-main {
	position: relative;

	.sticky-sidebar.sticky{
		position: fixed;
		top: 245px;
		.refine-search-sec{
			height: 60vh;
			overflow-y: auto;
			overflow-x: hidden;
		}
	}

	.style-sec-filter-sec {
		border-radius: 10px;
		background: $filter-background;
		margin-bottom: 10px;
		
		.style-sec-filter-title {
			color: $brand-color;
		    padding: 10px;
		    margin-bottom: 0;
		    text-transform: uppercase;
		    font-weight: 600;
		    font-size: 16px;
		}
		.clear-filter-btn {
			font-size: 16px;
		    line-height: 1.4em;
		    color: $clear-btn !important;
		    font-weight: 400;
		    padding: 0;
		    margin: 0;
		}
		.MuiTreeView-root {
			padding-bottom: 10px;
		}
		.MuiTreeItem-content {
			padding: 5px 10px;
			.MuiTypography-body1 {
				background-color: transparent;
				font-size: 15px;
				color: $select-para;
				font-weight: 500;
			}
			.MuiTreeItem-iconContainer {
				color: $select-para;
			}
		}
		.MuiTypography-body1 {
			color: $select-para;
		}
		.MuiFormControlLabel-root {
			width: 100%;
		} 
		.MuiCheckbox-root {
			color: $select-para;
		}
	}
	.search-item-filter-count-sec p{
		color: $clear-btn;
		font-size: 15px;
		font-weight: 400;
		margin-bottom: 0;
		margin-right: 5px;
		display: inline-block;
	}
	.size-filter-sec{
		.size-head-sec {
			align-items: center;
		    justify-content: space-between;
		    display: flex;
		    padding-right: 10px;
		}
		.size-list {
			padding: 0 10px 10px;
			.row {
				margin: 0;
			}
	        .col-lg-2,
	        .col-md-2 {
	            flex: 0 0 20%;
	            max-width: 20%;
	            display: contents;
	        }
	        .btn {
	            width: 40px;
	            color: $nav-color;
	            box-shadow: none;
	            background: $brand-color;
	            padding: 6px;
	            &:hover,
	            &:active {
	                background-color: $btn-act !important;
	                background-image: none !important;
	                color: $nav-color;
	                transform: none;
	            }
	        }
	    }
	}
	.color-filter-sec {
		.color-filter-head-sec {
			align-items: center;
		    justify-content: space-between;
		    display: flex;
		    padding-right: 10px;
		}
	}
	.search-item-sec {
		margin: 30px 0;
		.search-item-card {
			position: relative;
			background: $nav-color;
			box-shadow: 0 0.14px 2.29266px rgba(0,0,0,.03231), 0 0.37px 4.42626px rgba(0,0,0,.04769), 0 3px 7px rgba(0,0,0,.09);
			// border: 1px solid #dbe0e4;
			margin: 15px 0;
			border-radius: 3px;
			overflow: hidden;
			.badge-added-section{
				background: $orrange_color;
				display: flex;
				height: 34px;
				line-height: 34px;
				padding: 0 8px 0 10px;
				border-radius: 4px;
				color: #2d313a;
				position: absolute;
				z-index: 99;
				width: auto;
				max-width: 115px;
				justify-content: center;
				align-items: center;
				top: 6px;
				right: 6px;
				transition: 0.3s ease;
				visibility: hidden;
				p{
					color: $fifth-color;
					margin-bottom: 0;
					font-size: 16px;
					font-weight: 500;
				}
				&:before{
					content: '';
					display: block;
					height: 26px;
					width: 26px;
					position: absolute;
					left: -12px;
					top: 4px;
					border-radius: 4px;
					background: $orrange_color;
					z-index: -1;
					-webkit-transform: rotate(45.1deg);
					transform: rotate(45.1deg);
				}
				&:after{
					content: '';
					display: block;
					background: #2d313a;
					height: 8px;
					width: 8px;
					position: absolute;
					left: -5px;
					top: 13px;
					border-radius: 100%;
				}
			}
			.brand-logo-style {
			  text-align:center;
			  line-height:30px;
			  width:100px;
			  margin-top: 5px;
			  opacity: 0.6;
			  position: relative;
			  z-index: 1;
			  img{
				  height: 32px;
			  }
			}
			.badge-success {
				color: $nav-color;
				background-color: $eight-color;
				display: block;
				width: fit-content;
				margin: 10px auto 20px;
				font-size: 16px;
				padding: 10px 15px;
				border-style: double;
			}
			.category-title {
				color: $select-para;
				font-size: 15px;
				display: block;
				padding: 10px 10px 0;
				text-align: center;
				line-height: 1.3;
				min-height: 55px;
			}
			.category {
				position: relative;
				z-index: 3;
				img {
					margin: 0 auto 10px;
					display: block;
					height: 150px;
				}
			}
			.hover-plus-icon{
				position: absolute;
				top: auto;
				bottom: 0;
				opacity: 1;
				//visibility: hidden;
				left: 0;
				width: calc(100% + 2px);
				height: calc(100% + 1px);
				background-color: #474a4c9e;
				padding: 15px;
				-webkit-transform: translateY(100%);
				transform: translateY(100%);
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
				-webkit-transition: opacity 0.1s 0.3s, -webkit-transform 0.5s;
				transition: opacity 0.1s 0.3s, -webkit-transform 0.5s;
				transition: transform 0.5s, opacity 0.1s 0.3s;
				transition: transform 0.5s, opacity 0.1s 0.3s, -webkit-transform 0.5s;
				z-index: 90;
				.icon-wrapper{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					span{
						display: block;
						i{
							background: $eight-color;
							color: white;
							font-size: 25px;
							cursor: pointer;
							font-weight: bold;
							padding: 7px;
							border-radius: 30px;
							width: 40px;
							height: 40px;
							transition: all 0.3s ease-in-out;
							position: relative;
							text-align: center;
							&:hover {
								transform: scale(1.15);
								background: $first-color-light;
								color: $fifth-color;
							}
						}
					}
					span:nth-child(2){
						margin-top: 15px;
					}
				}
			}
			&:hover{
				.hover-plus-icon{
					visibility: visible;
					-webkit-transform: translateY(0px);
							transform: translateY(0px);
					-webkit-transition: opacity 0.1s, -webkit-transform 0.5s;
					transition: opacity 0.1s, -webkit-transform 0.5s;
					transition: transform 0.5s, opacity 0.1s;
					transition: transform 0.5s, opacity 0.1s, -webkit-transform 0.5s;
				}
			}
		}
		.search-item-card.selected{
			border: 2px solid $eight-color !important;
			transition: all 0.3s;
			.badge-added-section{
				visibility: visible;
			}
		}
		.btn-show-more{
			color: $fifth-color;
			font-size: 16px;
			margin: 20px 0;
			@include navlink_under_design;
		}
		.no-product-found-notification{
			font-size: 18px;
			color: $fifth-color;
			line-height: 1.4;
			font-family: "Poppins";
			font-weight: 500;
			text-transform: capitalize;
		}
	}
	.create-store-back {
		float: right !important;
		font-size: 20px;
		@include red_button;
		i {
			margin-right: 15px;
			margin-left: 0;
		}
	}
	.create-store-save-next-last {
		float: left;
		border-color: $fifth-color;
		font-weight: 600;
		font-size: 20px;
		background-color: unset !important;
		border-color:transparent;
		@include button_blue;
	}
	.search-item-filter-count-sec{
		.react-select__menu{
			z-index: 99;
		}
		.category-sub-caption{
			font-size: 15px;
			font-family: 'Poppins';
			margin-bottom: 30px;
			font-weight: 500;
		}
		.react-select__control{
			border: 1.5px solid #6b6b6ba6;
			.react-select__placeholder{
				font-size: 14px;
			}
		}
		.react-select__control.react-select__control--is-focused{
			border: 1.5px solid $eight-color;
		}
		.react-select__menu-list{
			.react-select__option{
				font-size: 13.5px;
				font-family: 'Poppins';
				line-height: 1.2;
			}
		}
		.sort-by-select{
			.react-select__single-value{
				color: #15212c;
			}
		}
	}
	// .manufacturer-filter-sec {
	// 	.MuiFormControlLabel-root {
	// 		margin-left: 0;
	// 		.MuiCheckbox-root {
	// 			color: $nine-color;
	// 		}
	// 		.Mui-checked {
	// 			color: $brand-color;
	// 		}
	// 	}
	// }
	.MuiFormControlLabel-root {
		margin-left: 0;
		.MuiCheckbox-root {
			color: $nine-color;
		}
		.Mui-checked {
			color: $brand-color;
		}
	}
}
#color-picker-1 {
	color: #000;
    background: #000;
    width: 30px;
    padding: 5px;
    text-align: center;
    margin-bottom: 10px;
}
.style-header{
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
	width: 100%;
	margin-bottom: 30px;
	//animation: fadeIn ease 2s;
    .title{
        @include ribon_header;
    }
    .header-button-sec{
        align-self: center;
        .create-store-save-next{
            border-color: $fifth-color;
            font-weight: 600;
            background-color: unset !important;
			border-color:transparent;
			transition: unset !important;
			@include button_blue;
			font-size: 15px;
            i{
                margin-left: 15px;
            }
        }
        .create-store-back{
            border-color: $fifth-color;
			font-size: 0.875rem;
            background-color: unset !important;
            color: $fifth-color;
			border-color:transparent;
			float: unset !important;
			font-size: 15px;
			@include red_button;
            i{
                margin-right: 15px;
            }
        }
    }
    .sub-title{
        color: #111111;
        font-size: 19px;
        line-height: 1.6;
        font-weight: 500;
        margin: 40px 0;
	}
	.category-sub-title{
		color: $fifth-color;
		font-size: 22px;
		line-height: 1.3;
		font-weight: 600;
		font-family: 'Poppins';
		text-transform: capitalize;
	}
}
.sticky-wrapper-row{
	//animation: fadeIn ease .9s;
	.sticky-wrapper-section.sticky{
		position: fixed;
		top: 95px;
		width: 100%;
		z-index: 100;
		background-color: #fff;
		left: 50%;
		transform: translateX(-50%);
		padding: 18px 0;
		//animation: fadeIn ease .9s;
		.style-header{
			max-width: calc(80% - 30px);
			margin: 0 auto;
		}
	}
}



@keyframes fadeIn{
	to {
	  opacity:1;
	}
}