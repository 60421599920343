.content-section{
	background: url('../../../../img/content-background.png') repeat center center;
  background-size: 100%;
  margin-top: -4px;
  padding: 70px 50px;
  display: flex;
  .content-1,.content-2,.content-3{
  	display: flex;
  	width: 33.33%;
  	flex-direction: column;
  	.content-title{
  		margin-bottom: 10px;
  		strong{
  			color: #e7e7e7;
  			font-size: 30px;
    		font-weight: bold;
  		}
  	}
  	.content-data{
  		p{
  			font-size: 18px;
    		color: rgba(255,255,255,0.5);
    		font-weight: 400;
    		line-height: 1.5;
  		}
  	}
  }
  .content-1{
  	padding-right: 86px;
  }
  .content-2{
  	padding: 0 43px;
  }
  .content-3{
  	padding-left: 86px;
  }
}