.details-table-sec {
    .customer-search-sec{
        margin-bottom: 20px;
        .MuiInput-underline {
            &::after {
                border-bottom-color: $title-color-dark;
            }
            &::before {
                border-bottom-color: $title-color-dark;
            }
        }
        input{
            font-weight: 500;
            color: $title-color-dark;
            font-family: "Poppins";
            &::placeholder{
                font-weight: 500;
                color: $title-color-dark;
                font-family: "Poppins";
            }
        }
    }
    .details-data-card {
        margin: 0;
        .card-header {
            padding-top: 0;
            padding-bottom: 15px;
            .card-title {
                color: $fifth-color;
                font-size: 25px;
                font-weight: 500;
                font-family: 'Poppins';
                line-height: 1.3;
                margin-bottom: 20px;
                span{
                    color: $first-color;
                }
            }
        }
        .details-table-cardbody {
            box-shadow: 0 0.14px 2.29266px rgba(0, 0, 0, 0.03231), 0 0.37px 4.42626px rgba(0, 0, 0, 0.04769), 0 3px 7px rgba(0, 0, 0, 0.09);
            border-radius: 20px;
            .table-responsive {
                margin-bottom: 0 !important;
            }
            tr {
                th {
                    text-align: center;
                    color: $title-color-dark !important;
                    text-transform: uppercase;
                    font-weight: 600 !important;
                    font-size: 15px !important;
                    padding: 12px 7px;
                }
                td {
                    text-align: center;
                    color: $black-color !important;
                    font-weight: 500 !important;
                    font-size: 14px !important;
                    border-top: 0 !important;
                    padding: 12px 7px;
                }
            }
            
            tbody {
                tr {
                    cursor: pointer;
                    td{
                        min-width: 100px;
                        position: relative;
                        .customer-first-col{
                            display: flex;
                            align-items: center;
                            .customer-list-info{
                                .customer-name {
                                    display: flex;
                                    padding: 5px 15px 0;
                                }
                                .customer-email {
                                    display: flex;
                                    padding: 5px 15px 0;
                                    color: $clear-btn;
                                    font-size: 12px;
                                }
                            }
                            .customer-img {
                                width: 60px;
                                height: 60px;
                                float: left;
                                img {
                                    border-radius: 30px;
                                }
                            }
                        }
                    }
                    
                    td:first-child{
                        position: relative;
                        display: flex;
                        flex-wrap: wrap-reverse;
                        &::before{
                            content: '';
                            width: 4px;
                            height: 0;
                            display: block;
                            position: absolute;
                            transition: 0.3s;
                            left: 0;
                            transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
                            border-radius: 20px;
                        }
                    }
                    &:hover{
                        border-left: 0 !important;
                        background-color: #f8f7fc !important;
                        td:first-child{
                            &::before{
                                transform: scale(1);
                                background-color: #242f67de;
                                height: 100%;
                                left: 0;
                                top: 0;
                            }
                        }
                    }
                    .customer-address {
                        min-width: 180px !important;
                        span:not(:last-child):after {
                            content: ", ";
                        }
                    } 
                }
                .details-data-btn {
                    width: 35px;
                    padding: 0;
                    height: 35px;
                    margin-right: 5px;
                    background: unset;
                    color: $black-color;
                    background: $details-btn;
                    border-radius: 25px;
                    margin: 2px !important;
                    i {
                        padding: 12px;
                    }
                }
                .details-edit-btn {
                    &:hover {
                        background-image: none !important;
                        background: #238be0d1 !important;
                        color:$white;
                        box-shadow: 1px 2px 5px 0px #52b0fd6b;
                    }
                    &:active, &:focus{
                        color: $black-color;
                        background: $details-btn !important;
                    }
                }
                .details-trash-btn {
                    &:hover {
                        background-image: none !important;
                        background-color: #f44336d6 !important;
                        color: $white;
                        box-shadow: 1px 2px 5px 0px #f4cac29c;
                    }
                    &:active, &:focus{
                        color: $black-color;
                        background: $details-btn !important;
                    }
                }
            }
            .customer-details-table{
                thead th,
                tbody td{
                    min-width: 120px;
                }
                thead th:first-child,
                tbody td:first-child{
                    min-width: 140px;
                }
                
                
            }
        }
        .orders-table-pagination{
            .MuiTablePagination-caption{
                color: $paragraph-color;
                font-size: 13.5px;
                font-family: "Poppins";
                text-transform: capitalize;
                font-weight: 500;
            }
        }
    }
}
.customer-info-modal {
    max-width: 700px;
    transform: none !important;
    .modal-content {
        border: none;
        border-radius: 15px;
        box-shadow: 0 0.14px 2.29266px rgba(0, 0, 0, 0.03231), 0 0.37px 4.42626px rgba(0, 0, 0, 0.04769), 0 3px 7px rgba(0, 0, 0, 0.09);
        .modal-header {
            justify-content: center;
            .modal-title {
                color: $md-title;
                font-weight: 600;
                text-transform: capitalize;
                line-height: 1.3;
                margin-bottom: 10px;
                margin-top: 10px;
                font-family: 'Poppins';
                position: relative;
                font-size: 20px;
                &::before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    display: inline-block;
                    background: $orange-btn;
                    position: relative;
                    top: -3px;
                    margin-right: 8px;
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 80px;
                    height: 4px;
                    background-image: linear-gradient(120deg, #efd5a8 0%, #ffae00 100%);
                    left: 1px;
                    bottom: -8px;
                    border-radius: 4px;
                }
            }
            .close{
                font-size: 24px;
                font-weight: 500;
            }
        }
        .modal-body {
            padding: 35px;  
            .edit-customer-image {
                display: block !important;
                .thumbnail {
                    border-radius: 60px;
                    .uploaded-image {
                        height: 100px;
                        width: 100px;
                        object-fit: cover;
                    }
                }
                .edit-select-btn {
                    background: $orange-btn;
                    font-size: 12px;
                    padding: 10px 20px;
                    font-weight: 600;
                    border-radius: 30px;
                    color: $black-color;
                    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
                    &:hover, &:focus {
                        background: $orange-btn !important;  
                    }    
                }
            }
            .MuiGrid-root {
                margin: 2px auto;
                .MuiIcon-root {
                    color: $input-color;
                    font-size: 1.2rem;
                    width: 1.2em;
                }
                label {
                    .Mui-focused {
                        color: $title-color-light;
                    }
                }
                .MuiFormLabel-root {
                    color: $input-color ;
                    font-size: 14px;
                    font-weight: 600;
                    font-family: 'Poppins';
                }
                .MuiFormLabel-filled {
                    font-weight: 600;
                    color: $title-color;
                }
                .MuiInput-underline {
                    &::after {
                        border-bottom-color: $input-color;
                    }
                    &::before {
                        border-bottom-color: $input-color;
                    }
                }
            }
            .custom-radio-item{
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                margin-top: 12px;
                .custom-radio-button{
                    width: calc(50% - 5px);
                    height: 100%;
                    appearance: none;
                    outline: none;
                    cursor: pointer;
                    border-radius: 2px;
                    padding: 4.5px 18.5px;
                    margin-right: 10px;
                    background: #efefef;
                    color: $fifth-color;
                    font-size: 14px;
                    font-weight: 600;
                    transition: all 100ms linear;
                    box-shadow: 1 1px 5px #efefef;
                    &::before{
                        content: attr(label);
                        display: inline-block;
                        text-align: center;
                        width: 100%;
                    }
                    &:checked{
                        background: #ffae00;
                        color: $fifth-color;
                        box-shadow:1px 1px 5px #ffae0078;
                        
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            .address-list-sec {
                display: flex;
                align-items: center;
                margin-top: 30px;
                margin-bottom: 25px;
                h3 {
                    color: $fifth-color;
                    font-weight: 600;
                    font-size: 17px;
                    font-family: "Poppins";
                    position: relative;
                    i{
                        margin-right: 10px;
                    }
                    &::before{
                        content: '';
                        position: absolute;
                        width: 80px;
                        height: 4px;
                        background-image: linear-gradient(120deg, #efd5a8 0%, #ffae00 100%);
                        left: 1px;
                        bottom: -8px;
                        border-radius: 4px;
                    }
                }
            }
            .add-address-btn {
                @include button_blue;
                margin-left: auto;
                text-transform: uppercase;
                border-radius: 50px;
                font-size: 15px;
            }
            .edit-customer-address-section {
                width: calc(100% - 30px);
                padding:0 10px 20px;
                border: 1px solid #ddd;
                margin: 0 auto 30px;
                position: relative;
                border-radius: 4px;
                legend {
                    width: fit-content;
                    padding: 0 5px;
                    font-size: 16px;
                    color: $paragraph-color;
                    font-weight: 600;
                    margin: 0 0 0 15px;
                    font-family: 'Poppins';
                    span{
                        display: inline-block;
                        line-height: 0.9;
                        background: #ffae00;
                        border-radius: 30px;
                        padding: 5px 15px;
                        margin-left: 5px;
                        color: $fifth-color;
                    }
                }
                .selected-address{
                    padding: 0 15px;
                    margin: 8px auto -18px;
                    display: flex;
                    flex-flow: row-reverse;
                    position: relative;
                    right: -4px;
                    z-index: 9;
                    .selected-address-label{
                        display: flex;
                        cursor: pointer;
                        font-weight: 500;
                        position: relative;
                        overflow: hidden;
                        margin-bottom: 0.375em;
                        border: 1px solid #ffae00;
                        border-radius: 3px;
                        .selected-adress-radio{
                            position: absolute;
                            left: -9999px;
                            &:checked + span {
                                background-color: #ffae00b0;
                                color: $fifth-color;
                                box-shadow: 1px 2px 5px #ffae00;
                                &:before {
                                    box-shadow: inset 0 0 0 0.4375em $fifth-color;
                                }
                            }
                        }
                        span {
                            display: flex;
                            align-items: center;
                            padding: 0.375em 0.75em 0.375em 0.375em;
                            transition: 0.25s ease;
                            color: $paragraph-color;
                            font-size: 12.5px;
                            font-weight: 600;
                            &:hover {
                                background-color: #ffae0038;
                            }
                            &:before {
                                display: flex;
                                flex-shrink: 0;
                                content: "";
                                background-color: #fff;
                                width: 18px;
                                height: 18px;
                                border-radius: 50%;
                                margin-right: 0.6em;
                                margin-left: 2px;
                                transition: 0.25s ease;
                                top: 0.5px;
                                box-shadow: inset 0 0 0 0.125em #6b6b6b;
                                position: relative;
                            }
                        }
                    }
                }
                .edit-customer-address-field {
                    display: inline-block;
                }
                .address-remove-btn {
                    position: absolute;
                    right: -13px;
                    top: 0;
                    color: #ef6863;
                    padding: 0;
                    background: #fff;
                    .MuiSvgIcon-root{
                        width: 28px;
                        height: 28px;
                    }
                }
                .Mui-disabled {
                    cursor: not-allowed;
                    pointer-events: fill;
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }

        } 
        .modal-footer {
            padding: 0 35px 25px;
            .edit-customer-btn {
                background: $orange-btn;
                font-size: 18px;
                font-weight: 600;
                color: $black-color;
                min-width: 200px;
                box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
                &:hover , &:active, &:focus {
                    background: $orange-btn!important;
                    transform: none !important; 
                    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4); 
                }
            }
        }
    }
} 
