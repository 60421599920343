.material_checkbox_term {
    .material_checkbox {
      position: relative;
      margin: 25px 15px;
      &::before {
        border: 2px solid #ccc;
        content: "";
        cursor: pointer;
        display: block;
        height: 18px;
        position: absolute;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        width: 18px;
        z-index: 1;
      }
      &::after {
        background: #fff;
        content: "";
        display: block;
        height: 18px;
        position: absolute;
        top: 0;
        width: 18px;
        z-index: 0;
      }
      &:checked:before {
        border-top-style: none;
        border-right-style: none;
        height: 10px;
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        border-color: $sixth-color;
      }
    }
    label {
      font-size: 15px;
      font-weight: 600;
      font-family: "Poppins";
      color: #111111;
      padding-left: 5px;
    }
    .material_checkbox:checked ~ label {
      color: $sixth-color;
    }
  }