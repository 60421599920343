// jamaloq site color

$first-color: #34B9A9;
$first-color-light: #32bea6;

$second-color: #F28454;
$second-color-light: #F79489;

$third-color: #245C4C;
$third-color-light: #81B622;

$fourth-color: #040404;
$fifth-color: #111111;
$sixth-color: #5881f8;
$seven-color: #4498FF;
$eight-color: #09bda0;
$input-color: #4c4c4c ;
$nine-color: #85919a;

$title-color-light: #4674c6;
$title-color: #414c84;
$title-color-dark: #242F67;

$orrange_color: #ffae00;

$subtitle-color: #8fc53b;
//$paragraph-color: #282a6a;
$paragraph-color: #6b6b6b;

$logo-color: #7980A4;

$logo-secondary-color: #bedd8e;

$menu-color: #93c744;

$nav-color: #ffffff;
$brand-color: #00bf9a;
$filter-background: #f5f5f5;
$clear-btn: #6b6b6b;
$select-para: #15212c;
$btn-act: #2ea18c;
$card-border: #ddd;
$shape-border: #5bc0de;
$orange-btn : #ffae00;
$black-color: #000000;
$content-color: #444444;
$red-color: #ca0202;
$blue-color: #52b0fd;
$details-btn: #f8f7fc;
$md-title: #312929; 
$pink-color: #fd5d93;
$ash-color: #f3f2f7;

$ipad-pro: 991px;

@mixin ipad-pro {
	@media (max-width: #{$ipad-pro}) {
		@content
	}
}


@keyframes shrink-bounce{
	0%{
		transform: scale(1);
	}
	33%{    
		transform: scale(.85);
	}
	100%{
		transform: scale(1);    
	}
}

@keyframes checkbox-check{
0%{
	width: 0;
	height: 0;
	border-color: #212121;
	transform: translate3d(0,0,0) rotate(45deg);
}
33%{
	width: .5em;
	height: 0;
	transform: translate3d(0,0,0) rotate(45deg);
}
100%{    
	width: .5em;
	height: .9em;    
	border-color: #212121;
	transform: translate3d(0,-.5em,0) rotate(45deg);
}
}