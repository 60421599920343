.all-pages {
	.all-pages-header h1 {
		color:$fifth-color;
		font-size: 50px;
		line-height: 1.2;
		font-weight: 200;
		font-family: 'Poppins';
		position: relative;
		text-align: center;
		margin-bottom: 40px;
		span:nth-child(1){
			display: inline-block;
			font-weight: bold;
			padding-left: 100px;
			position: relative;
			color: $fifth-color;
			&:before{
				content: '';
				position: absolute;
				width: 95px;
				height: 5px;
				background-image: linear-gradient(120deg, #5878f7 0%, #52b0fd 100%);
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
	.all-pages-title h3 {
		color: $fifth-color;
		font-weight: 600;
		font-size: 22px;
		margin-bottom: 20px;
		font-family: 'Poppins';
		position: relative;
		text-transform: capitalize;
		&:before{
			content: '';
			width: 10px;
			height: 10px;
			border-radius: 50%;
			display: inline-block;
			background: #ffae00;
			position: relative;
			top: -2px;
			margin-right: 8px;
		}
		&:after{
			content: '';
			position: absolute;
			width: 75px;
			height: 3.5px;
			background-image: linear-gradient(120deg, #efd5a8 0%, #ffae00 100%);
			left: 1px;
			bottom: -5px;
			border-radius: 4px;
		}
	}
	.all-pages-content {
		margin-bottom: 40px;
		p {
			color: $content-color;
			line-height: 1.5;
			font-size: 16px;
			font-weight: 400;
			margin-bottom: 10px;
		}
		.space-10 {
			height: 10px;
			display: block;
			width: 100%;
		}
		a{
			font-size: 15px;
			font-weight: 600;
			text-align: right;
			padding-right: 0;
			padding-left: 0;
			padding-top: 3px;
			position: relative;
			display: inline-block;
			cursor: pointer;
			color: #2196F3;
			&::after{
				height: 2px;
				width: 0;
				left: 50%;
				content: "";
				bottom: -1px;
				display: block;
				position: absolute;
				transition: 0.3s;
				transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
			}
			&:hover{
				color: #09bda0;
				&::after{
					transform: scale(1);
					background-color: #09bda0;
					color: #09bda0;
					width: 100%;
					left: 0;
				}
			}
		}
		ul{
			list-style: none;
			padding-left: 0;
			li{
				color: $content-color;
				font-size: 16px;
				line-height: 1.5;
				margin-bottom: 10px;
				padding-left: 10px;
				font-weight: 500;
				&::before{
					content: "\ea24";
					color: #34B9A9;
					font: bold normal normal 1em/1 "Nucleo";
					margin-right: 5px;
					font-size: 12px;
					top: 1px;
					position: relative;
				}
			}
		}
	}
}
