.contactus-1{
    .info{
        padding: 0;
        margin: 0;

        &:first-child{
            margin-top: 30px;
        }

        .info-title{
            margin-top: 20px;
            color: $white;
        }

        .icon{
            margin-top: 19px;
            color: $white;
        }
    }

    .card-contact{
        margin-top: 30px;
    }
}


.contactus-2{
    padding: 0;
    position: relative;

    .card-contact{
        max-width: 560px;
        margin: 80px 0 80px 150px;

        .info{
            padding: 0;
            margin: 0;
        }
    }
    .map{
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .info-horizontal .icon{
        margin-top: 28px;

        > i{
            font-size: 2.2em;
            max-width: 45px;

        }
    }
}

.contactus-3{
  .title{
    font-size: 5em;
  }

  .page-header-image{
    height: 400px !important;
  }

  .page-header .container, .page-header .container-fluid{
    padding-top: 13vh;
  }

  .page-header{
    height: 400px;
    min-height: 400px;
    margin-bottom: 50px;
  }

[class*="col-"]{
    border-right: 1px solid;
    &:last-child{
      border: none;
    }
  }
}

.contactus-4{
  .map{
    position: absolute;
    width: 100%;
    height: 700px;
    &:after{
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background: radial-gradient(400px circle at 70% 50%,transparent 0%, $info 100%);
      background-position: 23% 10%;
      left: 0;
      top: 0;
    }
  }

  .title{
    margin-top: 250px;
  }

  .description{
    margin-bottom: 75px;
  }

  .info{
    height: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 35px;
    .info{
      padding: 0;
      max-height: 80px;
      .info-title{
        margin-top: 30px;
      }
      .icon{
        color: $white;
      }
    }
  }
}

@media screen and (max-width:991px){

.contactus-3 [class*=col-]{
  border: none;
}
.contactus-4 .info .info{
  max-height: 120px;
}
}

@media screen and (max-width:768px){

  .contactus-4 .info{
    border-top-left-radius: 3px;
    border-bottom-right-radius: 0px;
  }
}
