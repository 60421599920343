.registration-sec{
    .page-title {
        margin-bottom: 40px;
        h1 {
            color: #3d97f5;
            letter-spacing: 0.25px;
            text-align: center;
            text-transform: uppercase;
            font-family: 'Poppins';
            font-weight: 200;
            margin: 25px 0;
            line-height: 1.3;
            font-size: 45px;
            span:last-child{
                display: inline-block;
                color: $fifth-color;
                font-weight: bold;
                position: relative;
                padding-left: 100px;
                left: -50px;
                &:before{
                    content: '';
                    position: absolute;
                    width: 95px;
                    height: 5px;
                    background-image: linear-gradient(120deg, #5878f7 0%, #52b0fd 100%);
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 10px;
                }
            }
        }
        p{
            color: $input-color;
            letter-spacing: 0.5px;
            font-size: 19px;
            font-weight: 500;
            line-height: 1.5;
        }
    }
    .card{
        background-color: #fff;
    }
    .auth-card {
        background: white;
        border: 1px solid #dadce0;
        padding: 25px;
        max-width: 460px;
        border-radius: 3px !important;
        padding-top: 0px;
        box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.1);

        .auth-card-header {
            padding-top: 30px;
            padding-bottom: 10px;
            margin-bottom: 20px;
            overflow: visible;
            .card-title {
                font-size: 25px;
                margin-bottom: 0;
                text-transform: capitalize;
                color: $fifth-color;
                font-weight: 500;
                position: relative;
            }
            .sub-title{
                font-size: 15px;
                margin-bottom: 0;
                line-height: 1.5;
                color: $input-color;
                text-align: center;
                font-weight: 500;
                margin-top: 35px;
            }
            .signup-social-section {
                p {
                    color: $input-color;
                    font-weight: 500;
                    margin: 5px 0;
                    margin-top: 15px;
                    font-size: 15px;
                    text-transform: capitalize;
                }
            }
            .signup-social-account {
                .btn {
                    background-image: none;
                    margin: 5px;
                    &:hover {
                        background-image: none !important;
                    }
                }
                .apple {
                    background-color: black;
                }
            }
            .hr-line {
                display: flex;
                color: $input-color;
                margin-top: 20px;
                margin-left: auto;
                margin-right: auto;
                width: 90%;
                margin-bottom: 0;
                &:before,
                &:after {
                    content: "";
                    flex: 1;
                    border-bottom: 2px solid $input-color;
                    margin: auto 0.25em;
                }

            }
        }
        .auth-card-body {
            min-height: 150px;
            .sub-title{
                font-size: 15px;
                margin-bottom: 0;
                line-height: 1.5;
                color: $input-color;
                text-align: center;
                font-weight: 500;
            }
            .MuiGrid-root {
                margin: 3px auto;
                .MuiIcon-root {
                    color: $input-color;
                    font-size: 1.2rem;
                    width: 1.2em;
                }
                label {
                    .Mui-focused {
                        color: $title-color-light;
                    }
                }
                .MuiFormLabel-root {
                    color: $input-color ;
                    font-size: 14px;
                    font-weight: 600;
                    font-family: 'Poppins';
                }
                .MuiFormLabel-filled {
                    font-weight: 600;
                    color: $title-color;
                }
                .MuiInput-underline {
                    &::after {
                        border-bottom-color: $input-color;
                    }
                    &::before {
                        border-bottom-color: $input-color;
                    }
                }
            }
            .MuiFormControlLabel-root {
                .MuiTypography-body1 {
                    color: $input-color;
                    font-size: 15px;
                    font-weight: 600;
                }
                .MuiCheckbox-root{
                    color: $input-color;
                }
            }
            .forget-pass {
                color: $input-color;
                font-size: 15px;
                font-weight: 600;
                text-align: right;
                padding-right: 0;
                padding-left: 0;
                padding-top: 3px;
                position: relative;
                cursor: pointer;
                &::after {
                    height: 2px;
                    width: 0;
                    left: 50%;
                    content: "";
                    bottom: 3px;
                    display: block;
                    position: absolute;
                    -webkit-transition: 0.3s;
                    -o-transition: 0.3s;
                    transition: 0.3s;
                    -webkit-transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
                    -o-transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
                    transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
                }
                &:hover {
                    color: $eight-color;
                    &::after {
                        -webkit-transform: scale(1);
                        -ms-transform: scale(1);
                        transform: scale(1);
                        background-color: $eight-color;
                        color: $eight-color;
                        width: 100%;
                        left: 0;
                    }
                }
            }
        }
        .auth-card-footer {
            margin-top: 15px;
            padding-top: 0;
            position: relative;
            left: 4px;
            .auth-btn {
                color: $fifth-color;
                box-shadow: 3px 6px 10px 0px #13b0973d;
                background: linear-gradient(120deg, #27ccb2 0%, #09bda0 100%) !important;
                border-color: rgb(47, 63, 140);
                padding: 12px 35px;
                font-size: 18px;
                border-radius: 5px;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.5s ease;
                &:hover {
                    transition: 0.5s ease;
                    background: linear-gradient(120deg, #27ccb2 0%, #09bda0 100%) !important;
                    //background-image: none !important;
                    color: $fifth-color;
                }
            }
            .go-back{
                color: $input-color;
                font-size: 15px;
                font-weight: 500;
                position: relative;
                text-transform: capitalize;
                padding: 0;
                margin-left: 5px;
                cursor: pointer;
                display: inline-block;
                margin-top: 15px;
                &::after {
                    height: 2px;
                    width: 0;
                    left: 50%;
                    content: "";
                    bottom: -5px;
                    display: block;
                    position: absolute;
                    -webkit-transition: 0.3s;
                    -o-transition: 0.3s;
                    transition: 0.3s;
                    -webkit-transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
                    -o-transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
                    transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
                }
                &:hover {
                    color: $eight-color;
                    &::after {
                        -webkit-transform: scale(1);
                        -ms-transform: scale(1);
                        transform: scale(1);
                        background-color: $eight-color;
                        color: $eight-color;
                        width: 100%;
                        left: 0;
                    }
                }
            }
        }
        .create-acount-link {
            h5 {
                color: $paragraph-color;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 15px;

                a {
                    color: $title-color;
                    font-size: 15px;
                    font-weight: 500;
                    position: relative;
                    text-transform: capitalize;
                    padding: 0;
                    margin-left: 5px;
                    cursor: pointer;
                    &::after {
                        height: 2px;
                        width: 0;
                        left: 50%;
                        content: "";
                        bottom: 5px;
                        display: block;
                        position: absolute;
                        -webkit-transition: 0.3s;
                        -o-transition: 0.3s;
                        transition: 0.3s;
                        -webkit-transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
                        -o-transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
                        transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
                    }
                    &:hover {
                        color: $title-color;
                        &::after {
                            -webkit-transform: scale(1);
                            -ms-transform: scale(1);
                            transform: scale(1);
                            background-color: $title-color;
                            color: $title-color;
                            width: 100%;
                            left: 0;
                        }
                    }
                }
            }
        }
        .alert-section{
            margin-top: 15px;
            .alert-danger{
                background-color: #cc0808;
            }
            .alert-primary{
                background-color: #087d08;
            }
        }
    }
}

.card-registration{
    animation-name: growZerotoFullStore;
    animation-duration: 0.5s !important;
}

.registration-sec .form-control{
    color: #000;
}

.registration-sec .card-change-pass{
    margin: 0 auto;
}
.registration-sec .card-registration .alert-section{
    margin-top: -30px;
}

.missing-section-main{
    margin: 50px 0;
    .row{
        align-items: center;
        h1{
            font-size: 65px;
            color: $title-color-dark;
            line-height: 1.2;
            font-weight: 700;
            margin-bottom: 35px;
            span{
                font-weight: 200;
                display: inline-block;
                position: relative;
                &:before{
                    content: '';
                    width: 4px;
                    height: 100%;
                    background-color: #96c849;
                    display: block;
                    position: absolute;
                    border-radius: 10px;
                    left: -10px;
                }
            }
        }
        p{
            color: $fifth-color;
            font-size: 20px;
            line-height: 1.6;
            font-weight: 500;
            font-family: 'Poppins';
            margin-bottom: 35px;
        }
        .missing-btn{
            color: rgb(255, 255, 255);
            box-shadow: rgba(50, 50, 93, 0.11) 0px 4px 6px, rgba(0, 0, 0, 0.08) 0px 1px 3px;
            background: $title-color-dark !important;
            border-color: rgb(47, 63, 140);
            padding: 12px 50px;
            font-size: 20px;
            border-radius: 35px;
            &:hover {
                background-color: $title-color-dark !important;
                background-image: none !important;
                color: $white;
            }
        }
    }
}
@keyframes growZerotoFullStore{
    from {
        opacity: 0;
        transform: scale(0);
    }
    to{
        opacity: 1;
        transform: scale(1);
    }
}