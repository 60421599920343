.btn.back-store-info {
  @include button_blue;
  background-image: none !important;
  background-color: unset !important;
  background: none !important;
  box-shadow: none !important;
  color: $fifth-color !important;
  border-bottom: 2px dashed $fifth-color;
  border-radius: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 5px !important;
  margin-bottom: 30px;
  font-size: 16px;
  i {
    margin-right: 10px;
  }
  &:hover,
  &:active,
  &:focus,
  &active:focus {
    background-color: unset !important;
    background-image: none !important;
    background: none !important;
    box-shadow: none !important;
    color: $fifth-color !important;
    border-bottom: 2px dashed $fifth-color !important;
  }
}
.selected-image-main-sec {
  .selected-image-sec {
    border: 1px solid #efefef;
    padding: 5px;
    border-radius: 6px;
  }
}

.logo-tab-section {
  margin-left: 5px;
  > .card {
    background: transparent;
    box-shadow: none;
    margin-top: 0;
    box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
    border-radius: 6px;
    padding: 15px;
  }
  .card-header {
    padding: 0;
    .logo-design-info-tab {
      display: flex;
      align-items: center;
      justify-content: center;
      .nav-item {
        margin-right: 10px;
        min-width: 200px;
        .nav-link {
          img {
            height: 30px;
            margin-right: 5px;
          }
          font-size: 16px;
          font-family: "Poppins";
          font-weight: 600;
          padding: 10px 35px 10px 25px;
          color: $title-color-dark;
          box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
          justify-content: center;
          text-align: center;
          position: relative;
          overflow: hidden;
          border: transparent !important;
          cursor: unset;
          &::before {
            height: 100%;
            width: 0;
            left: 0;
            top: 0;
            content: "";
            bottom: 1px;
            display: block;
            position: absolute;
            transition: 0.35s;
            transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
            z-index: -1;
            background-color: $details-btn !important;
          }
          &:hover, &:active, &:visited{
            border: transparent !important;
          }
        }
        .nav-link.active {
          background-image: none !important;
          color: #fff;
          //background: $title-color-dark !important;
          border-color: unset !important;
          &::before {
            transform: scale(1);
            background-color: $title-color-dark !important;
            width: 100%;
          }
        }
      }
    }
  }
  .card-body {
    background: $white;
    margin-top: 0;
    .logo-design-content .tab-pane.active{
      animation: tabs-fade .5s cubic-bezier(0.58, 0.3, 0.15, 0.46);
    }
  }
}

@keyframes tabs-fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@import "./design-sec.scss";
@import "./personalize-sec.scss";
