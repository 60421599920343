.custom-footer {
    border-top: 2px solid $brand-color;
    border-bottom: 2px solid $brand-color;
    background: $black-color;
    text-align: center;
    position: relative;
    padding: 0;
    .container{
        max-width: 80%;
    }
    .subscribe-line {
        padding: 25px 0;
        background: #080808;
        .card{
            background-color: transparent;
            box-shadow: none;
            margin-bottom: 30px;
            margin-top: 40px;

            .input-group{
                border-radius: 5px;

                .input-group-text { 
                    background: $nav-color;
                    border: none;

                    i {
                        color: $paragraph-color;
                        opacity: unset;
                        font-weight: 600;
                    }
                }
                .subscribe-field {
                    border: none;
                    background: $nav-color;
                    color: $black-color;
                    padding: 20px 15px;
                    font-size: 12px;
                }
            }
        }
        .btn{
            background-color: $orange-btn;
            background-image: none;
            border-radius: 5px;
            padding: 12px 0;
            text-transform: uppercase;
            font-weight: 600;
        } 
    }
    .footer-content {
        padding: 25px 0;
        border-bottom: 1px solid;
        .footer-content-col {
            flex: 0 0 23%;
            max-width: 23%;
            margin: 0 10px;
            min-height: 200px;
            padding-top: 20px;
            padding-bottom: 20px;

            h3.footer-title {
                text-transform: uppercase;
                font-family: 'Poppins';
                font-weight: bold;
                color: $orange-btn;
                font-size: 20px;
                padding: 5px;
                margin: 0;
                display: flex;
                position: relative;
                text-align: left;
                margin-bottom: 20px;
                &::before{
                    content: "";
                    position: absolute;
                    background: #ffae00;
                    border-radius: 20px;
                    bottom: -5px;
                    height: 5px;
                    left: 0;
                    width: 9px;
                    opacity: .9;
                }
                &::after{
                    content: "";
                    position: absolute;
                    background: #ffae00;
                    border-radius: 20px;
                    bottom: -5px;
                    height: 5px;
                    left: 15px;
                    width: 45px;
                }
            }
            .nav{
                display: flex;
                text-align: left;
                padding-top: 5px;
                position: relative;
                .nav-item{
                    width: 100%;
                    line-height: 1.3;
                    margin-bottom: 10px;
                    .nav-link {
                        font-size: 14px;
                        color: $nav-color;
                        transition: color .2s ease;
                        font-family: 'Poppins';
                        font-weight: 600;
                        padding-left: 0;
                        transition: 0.4s ease;
                        display: inline-block;
                        &::before{
                            content:"\ea35";
                            font-family: "Nucleo";
                            font-size: 12px;
                            position: relative;
                            top: 1px;
                            margin-right: 5px;
                        }
                        &:hover {
                            color: $eight-color;
                            padding-left: 8px;
                        }
                    }
                }
            }
        }
    }
    .footer-profile {
        text-align: center !important;
        padding: 25px 0;

        a {
            border-color: $orange-btn;
            background-color: $orange-btn;
            margin: 0 10px;
            height: 35px;
            width: 35px;
            font-size: 15px;
            color: $fifth-color;
            &:hover {
                border-color: $eight-color;
                background-color: $eight-color !important;
            }

            i {
                line-height: 1.79rem !important;
            }
        }
    }
    .footer-newsletter-section{
        .title{
            @include ribon_header;
            text-align: right !important;
            font-size: 30px;
            span{
                &:last-child{
                    color:#ffae00;
                }
            }
        }
        .subscribe-form-group{
            background: #fff;
            border-radius: 30px;
            overflow: hidden;
            margin-left: 20px;
            >.input-group{
                width: calc(100% - 150px);
                .input-group-prepend .input-group-text{
                    border-radius: 0;
                    i{
                        width: 25px;
                        font-size: 20px;
                    }
                }
                .subscribe-field{
                    height: 60px;
                    font-size: 16px !important;
                    font-family: 'Poppins';
                }
            }
            .subscribe-btn{
                position: absolute;
                top: 8px;
                right: 25px;
                border-radius: 30px;
                min-width: 130px;
                @include button_blue;
                background-image: linear-gradient(0deg, #ffae00 0%, #ffae00 100%) !important;
                box-shadow: 3px 6px 10px 0px #ffae0054;
                color: #111 !important;
                justify-content: center;
                font-size: 16px;
                line-height: 1.2;
            }
        }
        
    }
}
