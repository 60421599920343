.store-org-details-form{
    .store-form-sec{
        .header-title{
            text-align:center;
            .title{
                color: $fifth-color;
                font-weight: 600;
                text-transform: capitalize;
                line-height: 1.3;
                margin-bottom: 10px;
                margin-top: 10px;
                font-family: 'Poppins';
                position: relative;
                font-size: 20px;
                display: inline-block;
                &:before{
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    display: inline-block;
                    background: #ffae00;
                    position: relative;
                    top: -3px;
                    margin-right: 8px;
                }
                &:after{
                    content: '';
                    position: absolute;
                    width: 80px;
                    height: 4px;
                    background-image: linear-gradient(120deg, #efd5a8 0%, #ffae00 100%);
                    left: 1px;
                    bottom: -8px;
                    border-radius: 4px;
                }
            }
            .sub-title{
                font-size: 15px;
                font-family: 'Poppins';
                color: $fifth-color;
                line-height: 1.6;
                font-weight: 500;
                margin: 20px 0;
            }
        }
        .organization_info_form{
            padding: 10px;
            .MuiGrid-root {
                margin: 2px auto;
                .MuiIcon-root {
                    color: $input-color;
                    font-size: 1.2rem;
                    width: 1.2em;
                }
                label {
                    .Mui-focused {
                        color: $title-color-light;
                    }
                }
                .MuiFormLabel-root {
                    color: $input-color ;
                    font-size: 14px;
                    font-weight: 600;
                    font-family: 'Poppins';
                }
                .MuiFormLabel-filled {
                    font-weight: 600;
                    color: $title-color;
                }
                .MuiInput-underline {
                    &::after {
                        border-bottom-color: $input-color;
                    }
                    &::before {
                        border-bottom-color: $input-color;
                    }
                }
                .MuiInputBase-input{
                    font-family: 'Poppins';
                    font-weight: 500;
                    color: $fifth-color;
                }
            }
            .custom-select-desgin{
                >.MuiInputBase-root{
                    margin-top: 16px;
                    option[disabled]{
                        font-family: 'Poppins';
                        font-weight: bold;
                    }
                }
            }
            .btn-org-save{
                margin: 0 auto;
                margin-top: 30px;
                @include button_blue;
                background-image: none !important;
                background: none !important;
                color: $fifth-color !important;
                border-radius: 30px;
                border: 2px solid #ffae00;
                text-transform: uppercase;
                box-shadow: none !important;
                transform:none !important;

                &:hover, &:active, &:focus{
                    border: 2px solid transparent !important;
                    background-image: none !important;
                    background: #ffae00 !important;
                    color: $fifth-color !important;
                    box-shadow: 3px 6px 10px 0px #ffae0054 !important;
                }
            }
        }

        
    }
}