.upload-mascot-finalize{
    .mascot-finalize-img-sec{
        p.subtitle{
            display: flex;
            font-size: 12px;
            font-family: 'Poppins';
            font-weight: 400;
            background: #efefef;
            padding: 5px;
            border-radius: 5px;

            .icon-image{
                width: 30px;
                height: 30px;
            }
        }
        .selected-img-sec{
            padding: 5px;
            border: 1px solid #efefef;
            border-radius:5px;
        }
    }
    .mascot-finalize-desc{
        .title{
            color: $fifth-color;
            font-size: 18px;
            font-family: 'Poppins';
            font-weight: 600;
            line-height: 1.2;
            margin-bottom: 10px;
        }
        .sub-title{
            color: $paragraph-color;
            font-size: 14px;
            font-family: 'Poppins';
            line-height: 1.5;
            font-weight: 500;
        }
        .finalize-color-select{
            .color-row,
            .color-header-row{
                display: flex;
                flex-wrap: wrap;
                text-align: center;
                align-items: center;
                margin-bottom: 15px;
            }
            .color-col{
                width: 33%;
                display: flex;
                align-items: center;
                justify-content: center;
                .current-color{
                    width: 45px;
                    height: 25px;
                    background: red;
                    border-radius: 4px;
                    margin: 0 auto;
                    cursor: pointer;
                }
                
                .btn.upload-mascot-delet-btn{
                    width: 35px;
                    padding: 0;
                    height: 35px;
                    margin-right: 5px;
                    background: unset;
                    color: $black-color;
                    background: $details-btn;
                    border-radius: 25px;
                    margin: 2px !important;
                    i {
                        padding: 12px;
                    }
                    &:hover {
                        background-image: none !important;
                        background-color: #f44336d6 !important;
                        color: $white;
                        box-shadow: 1px 2px 5px 0px #f4cac29c;
                    }
                    &:active, &:focus{
                        color: $black-color;
                        background: $details-btn !important;
                    }
                }
            }
            .alter-color{
                position: relative;
                >div{
                    display: flex;
                    >div{
                        padding: unset !important;
                        background: unset !important;
                        border-radius: 4px !important;
                        box-shadow: unset !important;
                        display: inline-block !important;
                        left: 30px;
                        top: 30px;
                        >div{
                            min-width: 45px;
                            min-height: 25px;
                        }
                    }
                }
            }
            .color-header-row{
                margin-top: 10px;
            }
            .color-header-col{
                width: 33%;
                align-items: center;
                justify-content: center;
                display: flex;
                h4{
                    color: $fifth-color;
                    font-size: 14px;
                    font-weight: 600;
                    font-family: 'Poppins';
                    text-transform: capitalize;
                }
            }
            
        }
    }
}
.back-mascot-btn{
    transform: unset !important;
    padding: 0 0 5px 0;
    background: transparent;
    color: #242F67;
    border-radius: 25px;
    margin: 2px !important;
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins";
    text-align: left;
    line-height: 1;
    border-radius:0;
    border-bottom: 1px dashed $title-color-dark;
    overflow: visible;
    i {
      color: $title-color-dark;
      margin-right: 5px;
      padding-left: 0;
      position: relative;
      transition: .3s;
      left: 0;
    }
    &:hover,
    &:active,
    &:focus {
      background-image: none !important;
      background: transparent !important;
      color: $title-color-dark;
      box-shadow: none !important;
      i{
        left: -5px;
      }
    }
}
