.admin-portal-dashboard {
    .admin-portal-dashboard-heading {
        margin: 0 0 30px;
        h4 {
            color: #0D47A1;
            font-weight: 700;
            font-size: 35px;
            font-family: 'Poppins';
            line-height: 1.3;
        }
    }
    .admin-todays-ratio {
        box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
        border-radius: 10px;
        padding: 20px;
        text-align: center;
        .admin-todays-ratio-name {
            color: #1f2251;
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
        }
        .admin-todays-ratio-digit {
            color: $black-color;
            font-size: 20px;
            font-weight: 600;
        }
    }
    .admin-todays-ratio-one {
        background: #F8BBD0;
    }
    .admin-todays-ratio-two {
        background: #E1BEE7;
    }
    .admin-todays-ratio-three {
        background: #D1C4E9;
    }
    .admin-todays-ratio-four {
        background: #C5CAE9;
    }
    .admin-portal-earning-revenue-chart {
        box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
        background: $nav-color;
        border-radius: 10px;
        padding: 15px;
        margin: 30px 0 0;
    }
    .admin-portal-top-selling {
        box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
        background: $nav-color;
        border-radius: 10px;
        padding: 15px;
        margin: 30px 0 0;
        text-align: center;
        .top-selling-title {
            color: #1f2251;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            margin: 15px 0 30px;
        }
        .top-selling-setion {
            margin: 10px 0;
            img {
                width: 60px;
                height: 60px;
                object-fit: cover;
                border-radius: 30px;
                padding: 5px;
                box-shadow: 0 0 3px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
            }
            .top-selling-setion-info {
                margin: 0 20px;
                display: inline-block;
                vertical-align: middle;
                .top-selling-setion-name {
                    color: $black-color;
                    font-size: 17px;
                    font-weight: 600;
                }
                .top-selling-setion-count {
                    color: $nine-color;
                    font-size: 12px;
                    font-weight: 500;
                    float: left;
                }
            }
        }
    }
}