.store-owner-portal{
    background: #f8f7fc;
    .account-info-main-sec{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 40px;
        .account-info-sec{
            background: #fff;
            padding: 10px;
            border-radius: 6px;
            display: flex;
            flex-wrap: wrap;
            min-height: 160px;
            .img-sec{
                width: 80px;
                padding: 5px;
                margin: 0 auto;
                img{
                    height: 60px;
                    width: 60px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
            .account-info-details{
                padding: 10px;
                width: 100%;
                text-align: center;
                .userName{
                    font-family: 'Poppins';
                    color: $fifth-color;
                    font-size: 18px;
                    font-weight: 600;
                    text-transform: capitalize;
                    margin: 0 0 5px;
                    line-height: 1.2;
                }
                .user-type{
                    color: $fifth-color;
                    font-size: 15px;
                    font-weight: 500;
                    text-transform: capitalize;
                    margin: 0 0 5px;
                    line-height: 1.3;
                }
                .edit-sec{
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    margin-top: 15px;
                    .nav-link{
                        padding: 0;
                        margin-bottom: 5px;
                        font-size: 13.5px;
                        font-family: 'Poppins';
                        font-weight: 400;
                        line-height: 1.4;
                        color: $title-color-dark;
                        width: 100%;
                        cursor: pointer;
                    }
                }
            }
        }
        .store-info-header{
            display: flex;
            min-height: 150px;
            align-items: center;
            justify-content: center;
            height: 100%;
            background: $white;
            border-radius: 6px;
            padding: 10px;
            .title{
                color: $fifth-color;
                font-size: 50px;
                line-height: 1.2;
                text-transform: capitalize;
                font-family: 'Poppins';
                font-weight: 600;
                letter-spacing: 0.5px;
                span:first-child{
                    color: #3d97f5;
                    font-weight: 300;
                }
            }
        }
    }
    .store-owner-portal-tab-sec{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .store-owner-portal-sidebar{
            background: $white;
            padding: 15px 20px;
            border-radius: 6px;
            .nav-item{
                &:last-child .nav-link{
                    margin-bottom: 0 !important;
                }
                .nav-link{
                    background-image: none !important;
                    text-align: left;
                    background: none !important;
                    color: $paragraph-color;
                    font-size: 17px;
                    font-weight: 600;
                    box-shadow: none;
                    padding: 15px 10px;
                    margin-bottom: 10px !important;
                    display: flex;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
                    transition: 0.5s ease;
                    flex-wrap: wrap;
                    justify-content: center;
                    border-radius: 6px;
                    position: relative;
                    i{
                        width: 45px;
                        height: 45px;
                        font-size: 18px;
                        line-height: 24px;
                        background: #f8f7fc;
                        border-radius: 50%;
                        margin-right: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    span{
                        width: 100%;
                        display: block;
                        text-align: center;
                    }
                    &:after{
                        height: 4px;
                        width: 0;
                        left: 50%;
                        content: "";
                        bottom: 1px;
                        display: block;
                        position: absolute;
                        transition: 0.3s;
                        transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
                        border-radius: 30px;
                    }
                    &:hover{
                        background: #f8f7fc !important;
                    }                    
                }
                .nav-link.active{
                    color: $title-color-dark;
                    font-weight: 600;
                    transition: 0.5s ease;
                    background: #f8f7fc !important;
                    &:after{
                        transform: scale(1);
                        background-color: $title-color-dark;
                        width: 100%;
                        left: 0;
                    }
                }
            }
        }
        .store-owner-portal-desc-sec{
            background: $white;
            border-radius: 10px;
            height: 100%;
            padding: 25px;
        }
    }
}

.change-password-owner-modal {
    max-width: 500px;
    transform: translate(0, 25%) !important;
    .modal-content {
        border: none;
        border-radius: 15px;
        box-shadow: 0 0.14px 2.29266px rgba(0, 0, 0, 0.03231), 0 0.37px 4.42626px rgba(0, 0, 0, 0.04769), 0 3px 7px rgba(0, 0, 0, 0.09);
        .modal-header {
            justify-content: center;
            .modal-title {
                color: $md-title;
                font-weight: 600;
                text-transform: capitalize;
                line-height: 1.3;
                margin-bottom: 10px;
                margin-top: 10px;
                font-family: 'Poppins';
                position: relative;
                font-size: 20px;
                &::before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    display: inline-block;
                    background: $orange-btn;
                    position: relative;
                    top: -3px;
                    margin-right: 8px;
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 80px;
                    height: 4px;
                    background-image: linear-gradient(120deg, #efd5a8 0%, #ffae00 100%);
                    left: 1px;
                    bottom: -8px;
                    border-radius: 4px;
                }
            }
            .close{
                font-size: 24px;
                font-weight: 500;
            }
        }
        .modal-body {
            padding: 35px;  
            .edit-customer-image {
                display: block !important;
                .thumbnail {
                    border-radius: 60px;
                    .uploaded-image {
                        height: 100px;
                        width: 100px;
                        object-fit: cover;
                    }
                }
                .edit-select-btn {
                    background: $orange-btn;
                    font-size: 12px;
                    padding: 10px 20px;
                    font-weight: 600;
                    border-radius: 30px;
                    color: $black-color;
                    &:hover, &:focus {
                        background: $orange-btn !important;  
                    }    
                }
            }
            .MuiGrid-root {
                margin: 2px auto;
                .MuiIcon-root {
                    color: $input-color;
                    font-size: 1.2rem;
                    width: 1.2em;
                }
                label {
                    .Mui-focused {
                        color: $title-color-light;
                    }
                }
                .MuiFormLabel-root {
                    color: $input-color ;
                    font-size: 14px;
                    font-weight: 600;
                    font-family: 'Poppins';
                }
                .MuiFormLabel-filled {
                    font-weight: 600;
                    color: $title-color;
                }
                .MuiInput-underline {
                    &::after {
                        border-bottom-color: $input-color;
                    }
                    &::before {
                        border-bottom-color: $input-color;
                    }
                }
            }
        } 
        .modal-footer {
            padding: 0 35px 25px;
            .change-pass-btn {
                background: $orange-btn;
                font-size: 14px;
                font-weight: 600;
                color: $black-color;
                box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
                &:hover , &:active, &:focus{
                    background: $orange-btn!important;
                    transform: none !important; 
                    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
                }
            }
        }
    }
} 


@import "./store-details/store-details.scss";
@import "./customer-details/customer-details.scss";
@import "./orders-details/orders-details.scss";
@import "./my-account-info/my-account-info.scss";
@import "./gdpr-tools/gdpr-tools.scss";
@import "./store-owner-dashboard/store-owner-dashboard.scss";