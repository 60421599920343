.checkbox-position-sec{
    .custom-checkbox-design-another-sec{
        right: 23px;
        top: 37px;
        position: absolute;
        z-index: 99;
        .custom-checkbox-design-another{ 
            visibility: hidden;
            width: 0;
            height: 0; 
        }
        .custom-checkbox-design-another + .custom-checkbox-label-another{
            position: relative;
            display: inline-flex;
            align-items: center;
            color: #9197b6;
            transition: color 250ms cubic-bezier(.4,.0,.23,1);
            font-size: 20px;
            cursor: pointer;
            margin-bottom: 0;
        }
        .custom-checkbox-design-another + .custom-checkbox-label-another > span{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1.2em;
            height: 1.2em;
            background: #fff;
            border: 2px solid #9197b6;
            border-radius: 50%;
            cursor: pointer;  
            transition: all 250ms cubic-bezier(.4,.0,.23,1);
        }
        .custom-checkbox-design-another + .custom-checkbox-label-another:hover, 
        .custom-checkbox-design-another:focus + .custom-checkbox-label-another{
            color: #9e9e9e;
        }
        .custom-checkbox-design-another + .custom-checkbox-label-another:hover > span, 
        .custom-checkbox-design-another:focus + .custom-checkbox-label-another > span{
            background: rgba(255,255,255,.1);
        }
        .custom-checkbox-design-another:checked + .custom-checkbox-label-another > span{
            border: .1em solid #6fa026;
            animation: shrink-bounce 200ms cubic-bezier(.4,.0,.23,1);
            background: #92c74478;
        }
        .custom-checkbox-design-another:checked + .custom-checkbox-label-another > span:before{
            content: "";
            position: absolute;
            top: 2px;
            left: 4px;
            border-right: 3px solid $title-color-dark !important;
            border-bottom: 3px solid $title-color-dark !important;
            transform: rotate(45deg);
            transform-origin: 0% 100%;
            animation: checkbox-check 125ms 250ms cubic-bezier(.4,.0,.23,1) forwards;
        }
    }
    .category-card{
        position: relative;
        overflow: hidden;
        box-shadow: 1px 1px 2px rgba(0,0,0,.12) !important;
        background-color:#fff;
        border: 1px solid #dbe0e4 !important;
        border-radius: 3px;
        margin-bottom: 30px;
        cursor: pointer;
        .category-card-content{
            padding: 12.5px !important;
            position: relative;
            
            .category-thumb{
                text-align: center;
                margin-bottom: 35px;
                position: relative;
                z-index: 9;
                img{
                    height: 150px;
                    width: auto;
                    object-fit: cover;
                    transform: scale(1);
                    transition: 0.8s ease;
                }
            }
            .category-card-title{
                color: $fifth-color;
                font-size: 16.5px;
                font-weight: 500;
                line-height: 1.4;
                margin: 10px 0 0;
                font-family: 'Poppins';
                text-align: center;
                padding: 3px 5px;
                background: transparent;
                border: 1.5px solid $eight-color;
                border-radius: 12px 0;
                transition: 0.4s ease;
                
            }
    
            &:hover .category-thumb img{
                transform: scale(1.15);
            }
            &:hover .category-card-title{
                border: 1.5px solid transparent;
                background: $eight-color;
                color: $white;
            }
        }
    }
    .category-card.checked{
        border: 1px solid $eight-color !important;
        .category-card-title{
            border: 1.5px solid transparent;
            background: $eight-color;
            color: $white;
        }
    }
}
