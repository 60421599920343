.headroom {
    will-change: transform;
    background-color: inherit;
    @include transition($transition-base);
}
.headroom--pinned {
	@extend .position-fixed;
    transform: translateY(0%);
}
.headroom--unpinned {
	@extend .position-fixed;
    transform: translateY(-100%);
}
