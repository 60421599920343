.fund-raising-page {
    .fund-raising-content-section{
        margin-top: 70px;
        .combined-img-sec{
            .fund-raising-img-sec{
                position: relative;
                z-index: 2;
                border-radius: 20px;
                box-shadow: 0px 3px 30px 0px rgba(0,0,0,.11);
                overflow: hidden;
                img{
                    min-height: 450px;
                    object-fit: cover;
                }
            }
            &:before{
                content: '';
                width: 100%;
                height: 100%;
                background: #4bc6b07a;
                position: absolute;
                border-radius: 5px;
                left: -60px;
                top: -60px;
            }
        }
        .fund-raising-desc-sec{
            padding-left: 50px;
            .title{
                color: $fifth-color;
                font-weight: 400;
                margin-bottom: 20px;
                font-size: 25px;
                line-height: 1.3;
                font-family: 'Poppins';
                position: relative;
                padding-left: 20px;
                &:before{
                    content: '';
                    width: 5px;
                    height: 85%;
                    background: #ffae00;
                    display: block;
                    position: absolute;
                    top: 10%;
                    left: 0;
                    border-radius: 10px;
                    transform: translateX(-50%);
                }
            }
            .desc{
                color: $paragraph-color;
                font-size: 16px;
                line-height: 1.6;
                font-weight: 500;
                margin: 30px 0 40px;
                display: block;
            }
            .build-store {
                background: $orange-btn;
                box-shadow: 3px 6px 10px 0px #ffae0054;
                padding: 13px 30px;
                border-radius: 50px;
                color: #000 !important;
                transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
                font-weight: 600;
                position: relative;
                font-size: 18.5px;
                letter-spacing: 0.25px;
                border: none;
                font-family: "Poppins", sans-serif;
                display: inline-block;
                img{
                    height: 32px;
                    margin-right: 10px;
                }
                &::after{
                    content: none;
                }
            }
            
        }   
    }
}