.color-picker-sec {
    .container {
        max-width: 100% !important;
        .color-picker-col {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            padding: 0;
            .color-picker-wrap {
                width: 28px;
                height: 28px;
                transform: scale(1);
                -ms-transform: scale(1);
                -moz-transform: scale(1);
                -o-transform: scale(1);
                -webkit-transform: scale(1);
                transition: transform 100ms ease 0s;
                -ms-transition: transform 100ms ease 0s;
                -ms-transition: transform 100ms ease 0s;
                -o-transition: transform 100ms ease 0s;
                -webkit-transition: transform 100ms ease 0s;
                .color {
                    background: transparent;
                    height: 100%;
                    width: 100%;
                    cursor: pointer;
                    position: relative;
                    outline: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}
