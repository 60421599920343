.create-print-section{
	.print-1,.print-2, .print-3{
		display: flex;
		text-align: center;
		justify-content: center;
		.print-image,
		.print-content{
			display: flex;
			width: 50%;
		}
		.print-image{
			position: relative;
			img{
				width: 100%;
				height: auto;
			}
			.print-number{
				position: absolute;
				padding: 40px 40px;
				top: 38%;
				right: -50px;
				background-color: #f25d29;
				strong{
					font-size: 20px;
			    color: #2c2c2c;
			    font-weight: bold;
			    position: relative;
			    &::after{
			    	content: "";
	    	    position: absolute;
	    	    background-color: rgba(0,0,0,0.3);
	    	    height: 4px;
	    	    width: 100%;
	    	    border-radius: 2px;
	    	    left: 0;
	    	    bottom: -5px;
			    }
				}
			}
		}
		.print-content{
			padding: 7% 8% 5% 100px;
			flex-direction: column;
			text-align: left;
			.print-title{
				margin-bottom: 10px;
				width: 100%;
				display: flex;
				strong{
					font-size: 30px;
					font-weight: bold;
				}
			}
			.print-text{
				margin-bottom: 30px;
				p{
					font-size: 20px;
			    font-weight: normal;
			    color: #2c2c2c;
			    line-height: 1.5;
				}
			}
			.print-button{
				.btn {
				    background: -moz-linear-gradient(
				        90deg,
				        rgba(253, 78, 117, 1) 0%,
				        rgba(252, 120, 88, 1) 100%
				    );
				    background: -webkit-gradient(
				        linear,
				        left top,
				        left bottom,
				        color-stop(0%, rgba(252, 120, 88, 1)),
				        color-stop(100%, rgba(253, 78, 117, 1))
				    );
				    background: -webkit-linear-gradient(
				        90deg,
				        rgba(253, 78, 117, 1) 0%,
				        rgba(252, 120, 88, 1) 100%
				    );
				    background: -o-linear-gradient(
				        90deg,
				        rgba(253, 78, 117, 1) 0%,
				        rgba(252, 120, 88, 1) 100%
				    );
				    background: -ms-linear-gradient(
				        90deg,
				        rgba(253, 78, 117, 1) 0%,
				        rgba(252, 120, 88, 1) 100%
				    );
				    background: linear-gradient(
				        0deg,
				        rgba(253, 78, 117, 1) 0%,
				        rgba(252, 120, 88, 1) 100%
				    );
				    padding: 20px 40px;
				    font-size: 20px;
				    font-weight: 500;
				    color: #fff;
				    border-radius: 32px;
				    -webkit-border-radius: 32px;
				    -moz-border-radius: 32px;
				}
			}
		}
	}
	.print-2{
		.print-image{
			.print-number{
				left: -55px;
	    	right: auto;
			}
		}
	}
}