.logo-header{
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    width: 100%;
    .title{
        @include ribon_header;
    }
    .header-button-sec{
        align-self: center;
        .create-store-save-next{
            border-color: $fifth-color;
            background-color: unset !important;
            border-color:transparent;
            font-size: 15px;
            i{
                margin-left: 15px;
            }
            @include button_blue;
        }
        .create-store-back{
            border-color: $fifth-color;
            background-color: unset !important;
            border-color:transparent;
            float: unset !important;
            font-size: 15px;
            i{
                margin-right: 15px;
            }
            @include red_button;
        }
    }
    .sub-title{
        color: #111111;
        font-size: 19px;
        line-height: 1.6;
        font-weight: 500;
        margin: 40px 0;
    }
}
.btn.create-store-save-next-last{
    border-width: 2px;
    font-size: 20px;
    background-color: transparent;
    border: none;
    padding: 12px 40px;
    text-transform: capitalize;
    @include button_blue;
    i{
        margin-left: 15px;
    }
}



@import './logo-selection/organization-details/organization-details.scss';
@import './logo-selection/logo-design/logo-design.scss';