@keyframes modalFadeIn {
    0%{opacity: 0;}
    50%{opacity: 0.6;}
    100%{opacity: 1;}
}
@keyframes modalFadeOut {
    0%{opacity: 1;}
    50%{opacity: 0.6;}
    100%{opacity: 0;}
}
.modal {
    animation-name: modalFadeOut;
    animation-duration: 0.3s;
}
.modal .Product-added-card-modal{
    transform: translate(0, 10%) !important;
}
.modal.show .Product-added-card-modal{
    transform: translate(0, 10%);
}
.modal-open .modal{
    background: #0000002e;
    animation-name: modalFadeIn;
    animation-duration: 0.3s;
}
.Product-added-card-modal{
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12);
    max-width: 65%;
    max-height: 80vh;
    .product-added-modal-header{
        .product-added-modal-title{
            position: relative;
            color: #34B9A9;
            padding-left: 35px;
            &:before{
                content: '';
                position: absolute;
                width: 35px;
                height: 2.5px;
                background-image: linear-gradient(120deg, #3ddcc2 0%, #07a088 100%);
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            >span{
                color: $fifth-color;
                padding-left: 5px;
            }
        }
        .close{
            border: 1px solid #fb6e51;
            padding: 0;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            color: #fb6e51;
        }
    }
    .product-added-modal-body{
        margin-top: 10px;
        .modal-item-img{
            padding: 10px;
            border-radius: 2px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .size-range{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
            .title{
                color: $nine-color;
                font-size: 15px;
                line-height: 1.5;
                font-family: 'Poppins';
                margin-bottom: 0;
            }
            .value{
                color: $fifth-color;
                font-size: 15px;
                line-height: 1.6;
                font-family: 'Poppins';
                font-weight: 600;
                margin-left: 10px;
            }
        }
        .product-added-checkall{
            font-size: 16px !important;
        }
        .product-added-alert{
            margin: 10px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                color: green;
                font-size: 15px;
                line-height: 1.4;
                font-weight: 500;
                font-family: 'Poppins';
                margin-left: 10px;
            }
            &:before{
                content: "\ea17";
                font: bold normal normal 15px "Nucleo";
                color: green; 
            }
        }
        .modal-item-desc{
            padding-left: 40px;
            max-height: 59vh;
            overflow-y: auto;
            .title-with-prize{
                display: flex;
                align-items: center;
                margin-bottom: 22px;
                .title{
                    font-size: 21px;
                    color: $fifth-color;
                    line-height: 1.3;
                    font-family: 'Poppins';
                    text-transform: capitalize;
                    width: calc(100% - 120px);
                    margin-bottom: 0;
                }
                .dollar-sec{
                    width: 120px;
                    display: flex;
                    align-items: center;
                    img{
                        height: 35px;
                    }
                    .dollar{
                        color: $fifth-color;
                        font-size: 22px;
                        margin: 0;
                    }
                }
            }
            
            .color-available-sec{
                .title{
                    color: $nine-color;
                    font-size: 14.5px;
                    line-height: 1.5;
                    margin-bottom: 10px;
                    font-family: 'Poppins';
                    position: relative;
                    &:before{
                        content: '';
                        width: 40px;
                        height: 2px;
                        background: #09bda0;
                        position: absolute;
                        bottom: -4px;
                        left: 2px;
                    }
                }
                .check-subtitle{
                    color: $fifth-color;
                    font-size: 14px;
                    line-height: 1.5;
                    margin-bottom: 10px;
                    font-weight: 500;
                    text-transform: lowercase;
                    margin-top: 5px;
                    .product-img-check{
                        height: 20px;
                        display: inline-block;
                        padding: 0 4px;
                    }
                }
                .color-lists-with-short-image{
                    padding-left: 0;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    list-style: none;
                    .no-product-available{
                        color: $fifth-color;
                        font-size: 16px;
                        font-family: 'Poppins';
                        line-height: 1.4;
                        font-weight: 500;
                        margin-bottom: 30px;
                    }
                    .color-item{
                        width: calc(20% - 20px);
                        position: relative;
                        margin: 10px;
                        cursor: pointer;
                        .custom-checkbox-design-sec{
                            position: absolute;
                            right: 6px;
                            top: 6px;
                            z-index: 2;
                            @include Check_all;
                            .custom-checkbox-label{
                                > span{
                                    margin-right: 0 !important;
                                    width: 16px !important;
                                    height: 16px !important;
                                    border: 2px solid #85919a38 !important;
                                }
                            }
                            .custom-checkbox-design:checked + .custom-checkbox-label > span:before{
                                top: -2px;
                                left: 2px;
                            }
                            .custom-checkbox-design:checked + .custom-checkbox-label > span{
                                border: 8px solid #11bfa45e !important;
                            }
                        }
                        .content-wrapper{
                            border: 1px solid #85919a38;
                            padding: 6px;
                            border-radius: 2px;
                            position: relative;
                            z-index: 1;
                            .short-image{
                                margin-bottom: 10px;
                                img{
                                    height: 75px;
                                    margin: 5px auto 0;
                                }
                            }
                            .color-image-with-title{
                                display: flex;
                                align-items: center;
                                img{
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 50%;
                                    object-fit: fill;
                                }
                                .color-name{
                                    color: $fifth-color;
                                    font-size: 12px;
                                    font-weight: 600;
                                    line-height: 1.2;
                                    margin-left: 5px;
                                    text-transform: capitalize;
                                }
                            }
                        }
                        
                    }
                }
            }
            .product-desc{
                margin-top: 10px;
                .title{
                    color: $nine-color;
                    font-size: 14.5px;
                    line-height: 1.5;
                    margin-bottom: 10px;
                    font-family: 'Poppins';
                    position: relative;
                    &:before{
                        content: '';
                        width: 40px;
                        height: 2px;
                        background: #09bda0;
                        position: absolute;
                        bottom: -4px;
                        left: 2px;
                    }
                }
                ul{
                    padding-left: 0;
                    list-style: none;
                    margin-top: 10px;
                    li{
                        color: $fifth-color;
                        font-size: 14px;
                        line-height: 1.6;
                        margin-bottom: 2px;
                        padding-left: 10px;
                        &:before{
                            content: "\ea24";
                            color: $first-color;
                            font: bold normal normal 1em/1 "Nucleo";
                            margin-right: 5px;
                            font-size: 10px;
                            top: 1px;
                            position: relative;
                        }
                    }
                }
            }

        }
    }
    .product-added-modal-footer{
        margin-top: 12px;
        .modal-add-to-product{
            font-size: 16.5px;
            border-radius: 30px;
            @include button_green;
            i{
                margin-right: 15px;
                font-weight: 600;
            }
        }
    }
}


                            