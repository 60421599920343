.admin-customer-info-sec {
    .admin-details-table-cardbody {
        tbody {
            tr {
                .admin-customer-roles {
                    max-width: 120px;
                    position: relative;
                    word-break: break-word;
                }
            }
        }

    }
    .admin-customer-first-col{
        display: flex;
        align-items: center;
        .customer-list-info{
            .customer-name {
                display: flex;
                padding: 5px 15px 0;
            }
            .customer-email {
                display: flex;
                padding: 5px 15px 0;
                color: $clear-btn;
                font-size: 12px;
            }
        }
        .customer-img {
            width: 60px;
            height: 60px;
            float: left;
            img {
                border-radius: 30px;
            }
        }
    }
    .admin-portal-customer-address {
        width: 190px;
        word-break: break-word;
        span:not(:last-child):after {
            content: ", ";
        }
    }
    .admin-portal-details-btn {
        width: 35px;
        padding: 0;
        height: 35px;
        margin-right: 5px;
        background: unset;
        color: $black-color;
        background: $details-btn;
        border-radius: 25px;
        margin: 2px !important;
        i {
            padding: 12px;
        }
    }
    .admin-portal-edit-btn {
        &:hover {
            background-image: none !important;
            background: #238be0d1 !important;
            color:$white;
            box-shadow: 1px 2px 5px 0px #52b0fd6b;
        }
        &:active, &:focus{
            color: $black-color;
            background: $details-btn !important;
        }
    }
    .admin-portal--trash-btn {
        &:hover {
            background-image: none !important;
            background-color: #f44336d6 !important;
            color: $white;
            box-shadow: 1px 2px 5px 0px #f4cac29c;
        }
        &:active, &:focus{
            color: $black-color;
            background: $details-btn !important;
        }
    }
}


