.admin-portal-settings-content-main {
    box-shadow: 0 0.14px 2.29266px rgba(0, 0, 0, 0.03231), 0 0.37px 4.42626px rgba(0, 0, 0, 0.04769), 0 3px 7px rgba(0, 0, 0, 0.09);
    border-radius: 20px;
    margin: 30px;
    padding: 20px;
    .admin-portal-settings-content {
      padding: 25px 10px;
      .admin-portal-settings-content-heading {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 20px;
        color: #222a42;
        position: relative;
        &::before {
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 50%;
          display: inline-block;
          background: $orrange_color;
          position: relative;
          top: 0;
          margin-right: 8px;
        }
        &::after {
            content: '';
            position: absolute;
            width: 60px;
            height: 4px;
            background-image: linear-gradient(120deg, #efd5a8 0%, $orrange_color 100%);
            left: 5px;
            bottom: -8px;
            border-radius: 4px;
        }
      }
      .admin-portal-settings-content-body {
          .admin-portal-label {
              width: 33%;
              .admin-portal-radio-input {
                position: absolute;
                left: -9999px;
                &:checked + span {
                  color: $fifth-color;
                  &:before {
                    box-shadow: inset 0 0 0 0.3375em $title-color-dark;
                  }
                }
              }
              span {
                display: flex;
                align-items: center;
                padding: 0.275em 0.55em 0.275em 0.355em;
                transition: 0.25s ease;
                color: $black-color;
                font-size: 15px;
                font-weight: 500;
                font-family: "Poppins";
                line-height: 1.1;
                &:before {
                  display: flex;
                  flex-shrink: 0;
                  content: "";
                  background-color: $nav-color;
                  width: 16px;
                  height: 16px;
                  border-radius: 50%;
                  margin-right: 0.6em;
                  margin-left: 2px;
                  transition: 0.25s ease;
                  box-shadow: inset 0 0 0 0.125em $paragraph-color;
                  position: relative;
                }
              }
          }
          .MuiGrid-root {
              margin: 2px auto;
              width: 100%;
              .MuiIcon-root {
                color: $input-color;
                font-size: 1.2rem;
                width: 1.2em;
              }
              label {
                .Mui-focused {
                  color: $title-color-light;
                }
              }
              .MuiFormLabel-root {
                color: $input-color;
                font-size: 14px;
                font-weight: 600;
                font-family: "Poppins";
              }
              .MuiFormLabel-filled {
                font-weight: 600;
                color: $title-color;
              }
              .MuiInput-underline {
                &::after {
                  border-bottom-color: $input-color;
                }
                &::before {
                  border-bottom-color: $input-color;
                }
              }
              .MuiInputBase-input {
                font-family: "Poppins";
                font-weight: 500;
                color: $fifth-color;
              }
          }
      }
      
    }
}