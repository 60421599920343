.all-orders-tab-section {
    > .card {
      background: transparent;
      box-shadow: none;
      margin-top: 0;
      border-radius: 6px;
      padding: 15px;
    }
    .card-header {
      padding: 10px 5px;
      box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
      border-radius: 5px;
      .orders-info-tab {
        display: flex;
        align-items: center;
        .nav-item {
          margin-right: 10px;
          min-width: 150px;
          .nav-link {
            font-size: 16px;
            font-family: "Poppins";
            font-weight: 600;
            padding: 10px;
            color: $paragraph-color;
            justify-content: center;
            text-align: center;
            position: relative;
            border: transparent !important;
            cursor: pointer;
            &::before {
              height: 2px;
              width: 0;
              left: 50%;
              bottom: 0;
              content: "";
              bottom: 5px;
              display: block;
              position: absolute;
              transition: 0.35s;
              transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
              background-color: $details-btn !important;
              transform: translateX(-50%) !important;
            }
            &:hover, &:active, &:visited{
              border: transparent !important;
            }
          }
          .nav-link.active {
            background-image: none !important;
            color: $sixth-color;
            border-color: unset !important;
            &::before {
              transform: scale(1);
              background-color: $sixth-color !important;
              width: 45px;
              margin: 0 auto;
            }
          }
        }
      }
    }
    .card-body {
      background: $white;
      margin-top: 15px;
      border-radius: 5px;
      box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
      .orders-info-content .tab-pane.active{
        animation: tabs-fade .5s cubic-bezier(0.58, 0.3, 0.15, 0.46);
      }
      .orders-main-sec{
        .orders-search-sec{
          margin-bottom: 20px;
          .MuiInput-underline {
              &::after {
                  border-bottom-color: $title-color-dark;
              }
              &::before {
                  border-bottom-color: $title-color-dark;
              }
          }
          input{
              font-weight: 500;
              color: $title-color-dark;
              font-family: "Poppins";
              &::placeholder{
                  font-weight: 500;
                  color: $title-color-dark;
                  font-family: "Poppins";
              }
          }
        }
        .orders-table-sec{
          .orders-table{
            border-collapse: separate;
            border-spacing: 0 10px;
            thead{
              th{
                text-align: center;
                color: $title-color !important;
                text-transform: uppercase;
                font-weight: 600 !important;
                font-size: 15px !important;
                padding: 20px 7px;
                font-family: revert;
                letter-spacing: 0.5px;
              }
            }
            tbody{
              tr{
                background: #f8f7fc;
                cursor: pointer;
                td{
                  text-align: center;
                  color: #4d5959 !important;
                  font-weight: 500 !important;
                  font-size: 14.5px !important;
                  border-top: 0 !important;
                  padding: 12px 7px;
                  font-family: "Poppins";
                  .orders-table-first-col{
                    display: flex;
                    align-items: center;
                    .orders-list-info{
                        .product-name {
                            display: flex;
                            padding: 5px 15px 0;
                            font-size: 15px;
                            text-transform: capitalize;
                            color: #08085b;
                        }
                        .brand-name {
                            display: flex;
                            padding: 0 15px 0;
                            color: $fifth-color;
                            font-size: 13px;
                            text-transform: capitalize;
                            font-weight: 400;
                        }
                    }
                    .orders-img {
                        width: auto;
                        height: 55px;
                        float: left;
                        img {
                          border-radius: 4px;
                          height: 55px;
                        }
                    }
                  }
                  .size{
                    text-transform: uppercase;
                    font-weight: 600;
                    letter-spacing: 0.5px;
                    margin: 0 auto;
                    background: #ffae00;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 20px;
                    color: $fifth-color;
                    line-height: 1.3;
                  }
                  .pending{
                    color: #8a5f05;
                    background: #fbefd0;
                    padding: 3px 10px;
                    width: fit-content;
                    margin: 0 auto;
                    border-radius: 20px;
                    text-transform: uppercase;
                    font-weight: 600;
                    letter-spacing: 0.5px;
                  }
                  .deliver{
                    color: $sixth-color;
                    background: #b8c5ea3d;
                    padding: 3px 10px;
                    width: fit-content;
                    margin: 0 auto;
                    border-radius: 20px;
                    text-transform: uppercase;
                    font-weight: 600;
                    letter-spacing: 0.5px;
                  }
                  .cancelled{
                    color: #ec2424;
                    background: #f28b9445;
                    padding: 3px 10px;
                    width: fit-content;
                    margin: 0 auto;
                    border-radius: 20px;
                    text-transform: uppercase;
                    font-weight: 600;
                    letter-spacing: 0.5px;
                  }
                  .orders-trash-btn{
                    width: 35px;
                    padding: 0;
                    height: 35px;
                    margin-right: 5px;
                    background: unset;
                    color: $black-color;
                    background: $white;
                    border-radius: 25px;
                    margin: 2px !important;
                    i {
                        padding: 12px;
                    }
                    &:hover {
                      background-image: none !important;
                      background-color: #f44336d6 !important;
                      color: $white;
                      box-shadow: 1px 2px 5px 0px #f4cac29c;
                    }
                    &:active, &:focus{
                        color: $black-color;
                        background: $details-btn !important;
                    }
                    &:disabled{
                      cursor: not-allowed;
                      pointer-events: visible;
                    }
                  }
                }
                td:first-child{
                  position: relative;
                  min-width: 120px;
                  &:before{
                    content: '';
                    width: 4px;
                    height: 0;
                    display: block;
                    position: absolute;
                    transition: 0.3s;
                    left: 0;
                    transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
                    border-radius: 20px;
                  }
                }
                &:hover{
                  background: $white;
                  transform: scale(1, 1);
                  -webkit-transform: scale(1, 1);
                  -moz-transform: scale(1, 1);
                  box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
                  -webkit-box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
                  -moz-box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);

                  td:first-child:before{
                    transform: scale(1);
                    background-color: #242f67de;
                    height: 100%;
                    left: 0;
                    top: 0;
                  }
                }
              }
            }
          }
        }
        .orders-table-pagination{
          .MuiTablePagination-caption{
              color: $paragraph-color;
              font-size: 13.5px;
              font-family: "Poppins";
              text-transform: capitalize;
              font-weight: 500;
          }
        }
      }
    }
}
.customer-swal-container{
  .swal2-title.customer-delete-title{
    font-size: 22px;
    font-family: "Poppins";
    font-weight: 600;
    color: $fifth-color;
    margin-bottom: 15px;
  }
  .swal2-html-container{
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 500;
    color: $paragraph-color;

  }
  .btn.btn-confirm-delete,
  .btn.btn-confirm{
    @include button_blue;
    font-size: 15px;
    margin-right: 10px;
    border-radius: 30px;
  }
  .btn.btn-delete{
    @include red_button;
    font-size: 15px;
    border-radius: 30px;
  }
}



@keyframes tabs-fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}