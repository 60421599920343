.admin-portal-settings-inner-sec {
  .card {
    background: white;
    padding: 30px;
    .nav-tabs-info {
      .nav-link {
        color: #242f67;
        font-weight: 600;
        font-size: 15px;
        box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
        margin-right: 15px;
        padding: 10px 15px;
        cursor: pointer;
        .settings-tab-icon {
          padding: 7px;
          color: white;
          margin-right: 5px;
          border-radius: 25px;
          font-size: 14px;
          background: #4b6cb7;  /* fallback for old browsers */
          background: -webkit-linear-gradient(to right, #182848, #4b6cb7);  /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(to right, #182848, #4b6cb7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        }
        &:hover {
          border-color: #242f67;
        }
      }
      .active {
        border-color: #242f67;
        border-color: #242f67 !important;
        color: white !important;
        background: #4b6cb7;  /* fallback for old browsers */
          background: -webkit-linear-gradient(to right, #182848, #4b6cb7);  /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(to right, #182848, #4b6cb7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        .settings-tab-icon {
          color: #242f67;
          background: white;
        }
      }
    }
    .admin-settings-tabcontent {
      .admin-details-data-card {
        .card-header {
          display: none;
        }
      }
      .admin-profile-details-main {
        padding: 50px !important;
      }
    }
  }
}

@import './general-settings/general-settings.scss';
@import './privacy-settings/privacy-settings.scss';