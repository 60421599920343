.addlogo-backbtn{
margin-bottom: 20px;
	.col-md-2{
		padding: 0;
		.save-btn{
			margin: 0;
			text-align: left;
    		display: block;
		}
	}
}