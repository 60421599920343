@mixin red_button {
    display: flex !important;
    align-items: center;
    color: $white !important;
    font-weight: 600;
    background-image: linear-gradient(120deg, #fb6e51 0%, #ec6766 100%) !important;
    transition: 0.3 ease;
    box-shadow: 3px 6px 10px 0px #f4cac29c;
    &:hover,
    &:active,
    &:focus{
        background-color: unset !important;
        background-image: linear-gradient(120deg, #fb6e51 0%, #ec6766 100%) !important;
        box-shadow: 3px 6px 10px 0px #f4cac29c !important;
        border-color: transparent !important;
        color: $white;
    }
}