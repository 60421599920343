.custom-navbar {
    .container{
        max-width: 90%;
    }
    .campuswear-nav {
        background: $nav-color !important;
        min-height: 100px;
    }
    .navbar {
        padding: 0;
        .navbar-brand {
            .logo {
                // width: 35%;
                height: 50px;
                object-fit: cover;
            }
        }
        .navbar-toggler {
            background-color: $menu-color;
            margin: 20px 0;
        }
        .navbar-toggler-bar {
            height: 2px;
        }
        .navbar-nav {
            display: flex;
            align-items: center;
            .nav-item {
                cursor: pointer;
                .nav-link {
                    padding: 10px;
                    color: $fifth-color;
                    font-weight: 600;
                    position: relative;
                    font-size: 18.5px;
                    padding: 12px 20px;
                    letter-spacing: 0.25px;
                    border-radius: 5px;
                    border: none;
                    font-family: "Poppins", sans-serif;
                    display: flex;
                    align-items: center;
                    img{
                        height: 30px;
                        margin-right: 10px;
                    }

                    i {
                        // font-style: italic;
                        font-size: 17px;
                        font-weight: normal;
                        margin-right: 5px;
                        @include ipad-pro {
                            width: 25px;
                        }
                    }
                    &::after {
                        height: 2px;
                        width: 0;
                        left: 50%;
                        content: "";
                        bottom: 1px;
                        display: block;
                        position: absolute;
                        -webkit-transition: 0.3s;
                        -o-transition: 0.3s;
                        transition: 0.3s;
                        -webkit-transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
                        -o-transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
                        transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
                    }
                    &:hover {
                        color: $eight-color;
                        &::after {
                            -webkit-transform: scale(1);
                            -ms-transform: scale(1);
                            transform: scale(1);
                            background-color: $eight-color;
                            width: 90%;
                            left: 8px;
                        }
                    }
                    @include ipad-pro {
                        color: $nav-color;
                        border: none;
                    }
                }
                // .build-store {
                //     border: 1px solid $menu-color;
                //     border-radius: 5px;
                //     background-color: $menu-color;
                //     background-image: $menu-color;
                //     background-image: -moz-linear-gradient(top, $nav-color 0%, $menu-color 100%); 
                //     background-image: -webkit-linear-gradient(top, $nav-color 0%,$menu-color 100%); 
                //     background-image: linear-gradient(to bottom, $nav-color 0%,$menu-color 100%); 
                //     background-size: 300px;
                //     background-repeat: no-repeat;
                //     background-position: 0%;
                //     -webkit-transition: background 300ms ease-in-out;
                //     transition: background 300ms ease-in-out;
                //     text-align: center;
                //     font-weight: 600;
                //     transform: skew(160deg);
                //     font-style: normal;
                //     i {
                //         font-style: normal;
                //         @include ipad-pro {
                //             font-style: italic;
                //         }
                //     }
                //     &:hover {
                //         background-position: -225%;
                //         transition: background 300ms ease-in-out;
                //         color: $title-color;
                //         &::after {
                //             content: none;
                //         }
                //     }
                //     @include ipad-pro {
                //         background: none;
                //         border: none;
                //         transform: unset;
                //         font-style: italic;
                //         font-weight: 500;
                //     }
                // }
                .build-store {
                    //background: linear-gradient(120deg, #27ccb2 0%, #09bda0 100%);
                    background: $orange-btn;
                    box-shadow: 3px 6px 10px 0px #ffae0054;
                    padding: 13px 35px;
                    border-radius: 50px;
                    color: #000 !important;
                    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
                    img{
                        height: 33px;
                        margin-right: 10px;
                    }
                    &::after{
                        content: none;
                    }
                }
            }
        }
        .collapse-brand {
            @include ipad-pro {
                display: none;
            }
        }
        .collapse-close {
            @include ipad-pro {
                position: absolute;
                right: 3%;
                top: 5%;
            }
        }
    }
    .bg-white {
        // box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
        // -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
        // -moz-box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
        box-shadow: 0px 8px 24px 0px rgba(58, 98, 152, 0.1);
        .navbar-nav .nav-item{
            .nav-link:hover{
                color: $eight-color !important;
            }
            .build-store:hover{
                color: $fifth-color !important;
            }
        }
    }
    .navbar-collapse {
        vertical-align: middle;
        height: 100%;
        .navbar-collapse-header {
            @include ipad-pro {
                display: contents !important;
            }
        }
    }
}


