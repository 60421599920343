.admin-portal-main-section {
    background: $details-btn;
    .container {
        max-width: 90%;
    }
    .admin-portal-inner-section {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
        .admin-portal-left-section {
            background: $nav-color;
            border-radius: 10px 0 0 10px;
            .admin-portal-sidebar {
                padding-top: 20px;
                .nav-item {
                    .nav-link {
                        margin: 0 auto 15px;
                        background: $nav-color;
                        color: #1f2251;
                        box-shadow: none;
                        font-size: 16px;
                        font-weight: 600;
                        border-radius: 10px;
                        cursor: pointer;
                        i {
                            font-size: 30px;
                            line-height: 50px;
                        }
                        &:hover {
                            background: $ash-color;
                        }
                    }
                    .active {
                        background: $ash-color;
                    }
                }
            }
        }   
        .admin-portal-right-section {
            background: $ash-color;
            border-radius: 0 10px 10px 0;
            .admin-portal-right-inner {
                padding: 30px;
                .admin-portal-right-header {
                    display: flex;
                    position: relative;
                    .admin-search-sec {
                        display: flex;
                        position: relative;
                        i {
                            position: absolute;
                            top: 11px;
                            color: #1f2251;
                            left: 12px;
                            z-index: 1;
                        }
                        .MuiInput-underline {
                            &::after {
                                border-bottom: unset;
                            }
                            &::before {
                                border-bottom: unset;
                                // border-width: 2px;
                            }
                        }
                        input{
                            font-weight: 500;
                            color: $title-color-dark;
                            font-family: "Poppins";
                            background: $nav-color;
                            border-radius: 30px;
                            padding: 10px 10px 10px 30px;
                            &::placeholder{
                                font-weight: 500;
                                color: $title-color-dark;
                                font-family: "Poppins";
                            }
                        }
                    }
                    .admin-portal-notification {
                        margin: 0 25px;
                        i {
                            cursor: pointer;
                            font-size: 16px;
                            padding: 10px;
                            border-radius: 25px;
                            background: $nav-color;
                            box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
                        }
                    }
                    .admin-portal-details-sec {
                        display: flex;
                        position: relative;
                        .admin-details-img {
                            img {
                                width: 40px;
                                height: 40px;
                                border-radius: 30px;
                            }
                        }
                        .admin-details-sec-info {
                            .admin-details-sec-username {
                                color: $black-color;
                                margin-bottom: 0;
                                padding: 5px 10px 0;
                                font-size: 14px;
                                font-weight: 600;
                            }
                            .admin-details-sec-designation {
                                color: $logo-color;
                                padding: 0 10px;
                            }
                        }
                        .admin_nav__submenu {
                            display: none;
                        }                   
                        &:hover {
                            .admin_nav__submenu {
                              display: block;
                            }
                        }
                        .admin_nav__submenu {
                            position: absolute;
                            width: 180px;
                            padding: 10px;
                            margin-top: 25%;
                            background: white;
                            box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
                            border-radius: 5px;
                            z-index: 1;
                        }     
                        .admin_nav__submenu-item {
                            list-style: none;
                            color: #0D47A1;
                            padding: 7px;
                            a {
                                font-size: 15px;
                                font-weight: 400;
                            }
                            &:hover {
                              background: #f3f2f7;
                              border-radius: 5px;
                            }
                            i {
                                font-size: 15px;
                                padding-right: 8px;
                            }
                        }
                    }
                }
            }
        } 
    }
}

@import './order-list/order-list.scss';
@import './customer-list/customer-list.scss';
@import './dashboard/dashboard.scss';
@import './admin-settings/admin-settings.scss';
@import './account/account.scss';

