//ribon header style
@mixin ribon_header {
    color: #3d97f5;
    font-size: 40px;
    line-height: 1.2;
    font-weight: 200;
    font-family: 'Poppins';
    position: relative;
    span:last-child{
        display: inline-block;
        font-weight: bold;
        padding-left: 100px;
        position: relative;
        color: $fifth-color;
        &:before{
            content: '';
            position: absolute;
            width: 95px;
            height: 5px;
            background-image: linear-gradient(120deg, #5878f7 0%, #52b0fd 100%);
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}



@mixin ribon_header {
    color: #3d97f5;
    font-size: 40px;
    line-height: 1.2;
    font-weight: 200;
    font-family: 'Poppins';
    position: relative;
    span:last-child{
        display: inline-block;
        font-weight: bold;
        padding-left: 100px;
        position: relative;
        color: $fifth-color;
        &:before{
            content: '';
            position: absolute;
            width: 95px;
            height: 5px;
            background-image: linear-gradient(120deg, #5878f7 0%, #52b0fd 100%);
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}