.loader{
    display: block;
    width: 100%;
    text-align: center;
    img{
        width: 30%;
    }
}
// .custom-pagination{
//     justify-content: center;
//     li{
//         a{
//             color: #000;
//             &:hover{
//                 color: #000 !important;
//             }
//         }
//     }
// }
.pagination{
    flex-wrap: wrap;
}
.list-group-item{
    color: #000;
}
nav .pagination > li a{
    color: blue !important;
}