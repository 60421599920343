.customer-reg-sec .page-title h1{
    color: $eight-color;
    span:last-child{
        padding-left: 5px;
        &:before{
            content: none;
        }
    }
    span:first-child{
        position: relative;
        padding-left: 100px;
        left: -50px;
        &::before{
            content: '';
            position: absolute;
            width: 95px;
            height: 5px;
            background-image: linear-gradient(120deg, #0ebfa3 0%, #1bdcbd 100%);
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 10px;
        }
    }
}
.customer-login-main-section{
    .auth-card{
        .auth-card-body{
            .MuiGrid-grid-sm-1{
                max-width: 10%;
                flex-basis: 10%;
            }
            .MuiGrid-grid-sm-11{
                max-width: 90%;
                flex-basis: 90%;
            }
            .cus_address_sec{
                .MuiGrid-grid-sm-1{
                    max-width: 4.333333% !important;
                    flex-basis: 4.333333% !important;
                }
                .MuiGrid-grid-sm-11{
                    max-width: 95.666667% !important;
                    flex-basis: 95.666667% !important;
                }
            }
            .material_checkbox_term {
                .material_checkbox {
                  position: relative;
                  margin: 30px 15px 0 3px;
                  &::before {
                    border: 2px solid #ccc;
                    content: "";
                    cursor: pointer;
                    display: block;
                    height: 18px;
                    position: absolute;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    -webkit-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                    width: 18px;
                    z-index: 1;
                  }
                  &::after {
                    background: #fff;
                    content: "";
                    display: block;
                    height: 18px;
                    position: absolute;
                    top: 0;
                    width: 18px;
                    z-index: 0;
                  }
                  &:checked:before {
                    border-top-style: none;
                    border-right-style: none;
                    height: 10px;
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                    border-color: $sixth-color;
                  }
                }
                label {
                  font-size: 15px;
                  font-weight: 600;
                  font-family: "Poppins";
                  color: $input-color;
                }
                .material_checkbox:checked ~ label {
                  color: $sixth-color;
                }
              }
        }
        .auth-card-footer .auth-btn{
            @include button_blue;
        }
    }
    .card-registration{
        max-width: 100%;
        animation-name: growZerotoFull;
        animation-duration: 0.5s;
    }
}

@keyframes growZerotoFull{
    from {
        opacity: 0;
        transform: scale(0);
    }
    to{
        opacity: 1;
        transform: scale(1);
    }
}