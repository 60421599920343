.personal-info-sec,
.location-info-sec{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .title{
        width: 100%;
        padding: 0 5px;
        font-size: 16px;
        color: $fifth-color;
        font-weight: 600;
        margin: 0 0 0 15px; 
        font-family: 'Poppins';
        position: relative;
        &:before{
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            background: #ffae00;
            position: relative;
            top: 0;
            margin-right: 8px;
        }
        &::after{
            content: '';
            position: absolute;
            width: 60px;
            height: 4px;
            background-image: linear-gradient(120deg, #efd5a8 0%, #ffae00 100%);
            left: 5px;
            bottom: -8px;
            border-radius: 4px;
        }
    }
}
.location-info-sec{
    margin-top: 50px;
}
.customer-my-details-sec{
    position: relative;
    animation-name: topToBottom;
    animation-duration: 1s;
}

@keyframes topToBottom {
    from{
      opacity: 0;
      transform: translateY(-200%);
    }
    to{
      opacity: 1;
      transform: translateY(0);
    }
}