.personalization-header-section {
  .billing-header {
    width: 100%;
    .perosonalization-btn {
      right: 15px;
      left: unset !important;
      i {
        margin-right: unset;
        margin-left: 15px;
      }
    }
  }
}
.personalize-content {
    position: relative;
    animation: animatop 0.9s cubic-bezier(0.425, 1.14, 0.47, 1.125) forwards;
    width: 100%;
    margin-bottom: 50px;
    .personalize-card-content {
        width: 100%;
        height: 320px;
        padding: 20px;
        border-radius: 3px;
        background-color: $nav-color;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
        position: relative;
        overflow: hidden;
        padding-left: 20%;
        z-index: 9;   
        &::after {
            content: '';
            display: block;
            width: 230px;
            height: 500px;
            background: $first-color-light;
            opacity: 0.4;
            position: absolute;
            animation: rotatemagic 0.75s cubic-bezier(0.425, 1.04, 0.47, 1.105) 1s both;
        }
        .personalize-content-title {
          padding: 0;
          h2.title {
            color: $fifth-color;
            font-weight: 600;
            text-transform: capitalize;
            line-height: 1.3;
            margin-bottom: 10px;
            margin-top: 10px;
            font-family: 'Poppins';
            position: relative;
            &::before { 
              content: '';
              width: 10px;
              height: 10px;
              border-radius: 50%;
              display: inline-block;
              background: #ffae00;
              position: relative;
              top: -3px;
              margin-right: 8px;
            }
            &::after{
              content: '';
              position: absolute;
              width: 80px;
              height: 4px;
              background-image: linear-gradient(120deg, #efd5a8 0%, #ffae00 100%);
              left: 1px;
              bottom: -8px;
              border-radius: 4px;
            }
          }
        }
        .personalize-info {
          display: flex;
          flex-wrap: wrap;
          p.sub-title {
              color: $paragraph-color;
              font-size: 15px;
              font-weight: 400;
              font-family: 'Poppins';
              margin: 0 0 15px;
          }
          p.sub-title:first-child{
            margin-top: 20px;
          }
        }
        .personalization-upload-image {
          .thumbnail {
            min-height: 100px;
            display: flex;
            justify-content: center;
            box-shadow: unset;
            .uploaded-image {
              object-fit: contain;
              max-width: 60%;
              width: 240px;
            }
          }
          .btn-round {
            padding: 10px 20px;
          }
          .btn-primary {
            background-color: #ffae00 !important;
            background-image: unset !important;
            font-weight: 600;
          }
        }
        .personalize-banner-container {
          justify-content: center;
          display: flex;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
          margin-top: 20px;
          border-radius: 5px;
          .personalization-upload-image {
            .thumbnail{
              max-height: 130px;
              max-width: 100%;
            }
            .uploaded-image {
              max-width: 100%;
              width: 100%;
              object-fit: unset;
              border-radius: 5px 5px 0px 0px !important;
            }
          }
        }
        .domain-name-sec {
          margin-top: -15px;
          p {
            color: $paragraph-color;
            padding: 5px;
            font-size: 15px;
            font-weight: 500;
          }
        } 
        .domain-info-msg {
          border: 1px solid $card-border;
          border-radius: 3px;
          padding: 10px;
          margin: 20px 0 0 0;
          max-width: 80%;
          background: $filter-background;
          p {
            font-size: 14px;
            color: $black-color;
            font-weight: 500;
          }
        }
        .MuiGrid-root {
          margin: 2px 0;
          .MuiIcon-root {
              color: $input-color;
              font-size: 1.2rem;
              width: 1.2em;
          }
          label {
              .Mui-focused {
                  color: $title-color-light;
              }
          }
          .MuiFormLabel-root {
              color: $input-color ;
              font-size: 14px;
              font-weight: 600;
              font-family: 'Poppins';
          }
          .MuiFormLabel-filled {
              font-weight: 600;
              color: $title-color;
          }
          .MuiInput-underline {
              &::after {
                  border-bottom-color: $input-color;
              }
              &::before {
                  border-bottom-color: $input-color;
              }
          }
        }
    }
}
.social-media-sec {
    .personalize-info {
      margin-top: 0 !important;
      p {
        margin-bottom: 0;
      }
    }
    .domain-name-sec {
      padding: 0 10px !important;
    }
}
.personalization-footer-section{
  .personalize-btn{
    border-color: $fifth-color;
    background-color: unset !important;
    border-color:transparent;
    float: unset !important;
    font-size: 15px;
    margin-left: auto !important;
    i{
        margin-left: 15px;
    }
    @include button_blue;
  } 
}
@keyframes animatop {
  0% {
    opacity: 0;
    bottom: -500px;
  }
  100% {
    opacity: 1;
    bottom: 0px;
  }
}
@keyframes animainfos {
  0% {
    bottom: 10px;
  }
  100% {
    bottom: -42px;
  }
}
@keyframes rotatemagic {
  0% {
    opacity: 0;
    transform: rotate(0deg);
    top: -24px;
    left: -253px;
  }
  100% {
    transform: rotate(-30deg);
    top: -24px;
    left: -78px;
  }
}
