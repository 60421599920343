.step-section {
    // float: left;
    // margin-left: calc(-1 * ((100vw - 100%) / 2));
    background-color: white;
    padding: 40px 0;
    .container {
        background: url("../../../../img/map_1.png")
            no-repeat center center;
    }
    .step-section-title {
        margin-bottom: 40px;
        //text-align: center;
        strong {
            font-size: 30px;
            font-weight: bold;
        }
    }
    .step-1,
    .step-2,
    .step-3 {
        // width: 33.33%;
        // float: left;
        .step-image {
            margin-bottom: 20px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .step-title {
            font-size: 20px;
            font-weight: 600;
            color: #2c2c2c;
            margin-bottom: 30px;
            padding: 0 10px;
            text-align: center;
        }
    }
    // .step-1,
    // .step-2 {
    //     .step-image {
    //         &::before {
    //             content: "";
    //             position: absolute;
    //             background-color: #2e2e2e;
    //             height: 2px;
    //             width: 250px;
    //             right: -126px;
    //             top: 40%;
    //         }
    //     }
    // }
    .step-text {
        font-size: 17px;
        color: #2c2c2c;
        margin-bottom: 25px;
        // float: left;
        // width: 100%;
        line-height: 30px;
        text-align: center;
    }
    .step-button {
        // text-align: center;
        .btn {
            background: -moz-linear-gradient(
                90deg,
                rgba(88, 120, 247, 1) 0%,
                rgba(82, 176, 253, 1) 100%
            );
            background: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(0%, rgba(82, 176, 253, 1)),
                color-stop(100%, rgba(88, 120, 247, 1))
            );
            background: -webkit-linear-gradient(
                90deg,
                rgba(88, 120, 247, 1) 0%,
                rgba(82, 176, 253, 1) 100%
            );
            background: -o-linear-gradient(
                90deg,
                rgba(88, 120, 247, 1) 0%,
                rgba(82, 176, 253, 1) 100%
            );
            background: -ms-linear-gradient(
                90deg,
                rgba(88, 120, 247, 1) 0%,
                rgba(82, 176, 253, 1) 100%
            );
            background: linear-gradient(
                0deg,
                rgba(88, 120, 247, 1) 0%,
                rgba(82, 176, 253, 1) 100%
            );
            padding: 20px 40px;
            font-size: 20px;
            font-weight: 500;
            color: #fff;
            border-radius: 32px;
            -webkit-border-radius: 32px;
            -moz-border-radius: 32px;
        }
    }
}
