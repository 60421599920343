.Careers-page {
    .carrer-sec-row{
        margin-top: 20px;
        .carrer-left-image{
            box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.11);
            border-radius: 20px;
            overflow: hidden;
        }
        .fund-raising-desc{
            padding-left: 50px;
            .title{
                color: $fifth-color;
                font-size: 30px;
                font-family: 'Poppins';
                line-height: 1.3;
                margin-bottom: 30px;
                position: relative;
                &::before{
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    display: inline-block;
                    background: #ffae00;
                    position: relative;
                    top: -4px;
                    margin-right: 8px;
                }
                &::after{
                    content: '';
                    position: absolute;
                    width: 95px;
                    height: 5px;
                    background-image: linear-gradient(120deg, #efd5a8 0%, #ffae00 100%);
                    left: 1px;
                    bottom: -5px;
                    border-radius: 4px;
                }
            }
            .desc{
                color: $paragraph-color;
                font-size: 16px;
                line-height: 1.6;
                font-weight: 500;
                margin-bottom: 30px;
            }
            .desc.red-text{
                color: $red-color;
                font-size: 18px;
            }
        }
    }
    
}
