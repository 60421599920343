//top item in dashboard
.store-owner-dashboard-main-sec {
  .store-owner-dashboard-header {
    .stores-main-title {
      color: $fifth-color;
      font-size: 25px;
      font-weight: 500;
      font-family: "Poppins";
      line-height: 1.3;
      margin-bottom: 20px;
      span {
        color: $first-color;
      }
    }
  }
  .dashboard-card-items-sec {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    .dashboard-item-card {
      padding: 20px 15px;
      border-radius: 5px;
      display: flex;
      flex-wrap: wrap;
      min-height: 135px;
      margin-bottom: 30px;
      position: relative;
      background: #f8f7fc5e;
      .background-overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('../../../../../img/background_store_owner.png');
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.3;
      }
      
      .dashboard-item-img-sec {
        width: 70px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 70px;
        &:before {
          content: "";
          width: 70px;
          position: absolute;
          height: 70px;
          border-radius: 50%;
        }
        .img-overlay {
          background: $white;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 32px;
            height: 32px;
          }
        }
      }
      .dashboard-item-content {
        width: calc(100% - 70px);
        padding-right: 15px;
        .dashboard-item-title {
          color: #444455;
          font-family: "Poppins";
          font-size: 18px;
          font-weight: 500;
        }
        .dashboard-item-value {
          font-family: "Poppins";
          font-size: 25px;
          font-weight: 600;
        }
      }
    }
    .products_item {
      box-shadow: 0px 1px 5px 0 #4fa0f547;
      .dashboard-item-img-sec:before {
        background: #4fa0f5a8;
      }
      .dashboard-item-content .dashboard-item-value {
        color: #4fa0f5;
      }
    }
    .orders_item {
      box-shadow: 0px 1px 5px 0 #05445e36;
      .dashboard-item-img-sec:before {
        background: #05445ebf;
      }
      .dashboard-item-content .dashboard-item-value {
        color: #05445e;
      }
    }
    .customers_item {
      box-shadow: 0px 1px 5px 0 #00173757;
      .dashboard-item-img-sec:before {
        background: #001737d9;
      }
      .dashboard-item-content .dashboard-item-value {
        color: #001737;
      }
    }
    .units_item {
      box-shadow: 0px 1px 5px 0 #09bda047;
      .dashboard-item-img-sec:before {
        background: #09bda07d;
      }
      .dashboard-item-content .dashboard-item-value {
        color: $eight_color;
      }
    }
    .total_orders_item {
      box-shadow: 0px 1px 5px 0 #a3710552;
      .dashboard-item-img-sec:before {
        background: #a3710585;
      }
      .dashboard-item-content .dashboard-item-value {
        color: #a37105;
      }
    }
    .fundraise_item {
      box-shadow: 0px 1px 5px 0 #1c0a304f;
      .dashboard-item-img-sec:before {
        background: #050a30bf;
      }
      .dashboard-item-content .dashboard-item-value {
        color: #050a30;
      }
    }
  }
}

// best products viewed and sell dashboard
.store-owner-best-products-main-sec{
  background: #f8f7fc5e;
  box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
  border-radius: 6px;
  padding: 10px 15px;
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  min-height: 89%;
  .store-owner-best-product-card{
    background: $white;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    width: 100%;
    border: 1px solid #f8f7fc;
    border-radius: 10px;
    padding: 15px;
    min-height: 80px;
    .title{
      color: $logo-color;
      font-size: 14px;
      font-family: "Poppins";
      line-height: 1.2;
      margin-bottom: 20px;
      text-transform:  capitalize;
    }
    .store-owner-best-product-inner{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .best-products-img-section{
        width: 30%;
        img{
          width: auto;
          height: 70px;
          object-fit: cover;
        }
      }
      .best-products-content-section{
        width: 70%;
        display: flex;
        flex-wrap: wrap;
        align-self: center;
        .value{
          font-size: 24px;
          font-weight: 600;
          font-family: "Poppins";
          line-height: 1.2;
          margin-bottom: 10px;
          color: $title-color-dark;
          width: 100%;
          i{
            margin-right: 25px;
          }
        }
        .product-title{
          color: $logo-color;
          font-size: 14px;
          font-family: "Poppins";
          line-height: 1.2;
          margin-bottom: 5px;
          font-weight: 400;
          width: 100%;
        }
      }
    }
    
  }
}



@import "./store-owner-dasboard-chart.scss";
@import "./store-owner-top-product-category.scss";
