.color-filter-sec {
    // .color-head-sec {
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     margin-top: 20px;
    //     margin-bottom: 10px;
    //     .color-title {
    //         color: $subtitle-color;
    //         margin-bottom: 0;
    //     }
    //     .clear-filter-btn {
    //         font-size: 18px;
    //         line-height: 1.4em;
    //         color: $paragraph-color;
    //         margin-bottom: 10px;
    //         font-weight: 400;
    //         padding: 0;
    //         margin: 0;
    //         &:hover,
    //         &:active,
    //         &:focus {
    //             color: $title-color !important;
    //         }
    //     }
    // }
    // .color-advance-filter {
    //     display: flex;
    //     align-items: center;
    //     justify-content: flex-start;
    //     .btn {
    //         font-size: 18px;
    //         line-height: 1.4em;
    //         color: $paragraph-color;
    //         margin-bottom: 10px;
    //         font-weight: 400;
    //         padding: 0;
    //         margin-top: 10px;
    //         &:hover,
    //         &:active,
    //         &:focus {
    //             color: $title-color !important;
    //         }
    //     }
    // }
    .color-picker{
        padding-top: 10px;
    }
}
