.contact-page-header-section{
	padding-bottom: 0;
}
.contact-us-page{
	background: #efefef4f;
	padding-bottom: 200px;
	.contact-page-first-section{
		margin-top: 50px;
		.contact-header-left-sec{
			position: relative;
			.main-title-sec{
				position: relative;
				.sub-title{
					color: $paragraph-color;
					font-size: 18px;
					font-family: "Poppins";
					margin-bottom: 5px;
					text-transform: uppercase;
				}
				.main-title{
					color: $fifth-color;
					font-size: 35px;
					font-family: "Poppins";
					margin-bottom: 20px;
					line-height: 1.5;
					font-weight: 800;
					position: relative;
					&::before{
						content: '';
						width: 10px;
						height: 10px;
						border-radius: 50%;
						display: inline-block;
						background: #ffae00;
						position: relative;
						top: -2px;
						margin-right: 8px;
					}
					&::after{
						content: '';
						position: absolute;
						width: 75px;
						height: 3.5px;
						background-image: linear-gradient(120deg, #efd5a8 0%, #ffae00 100%);
						left: 1px;
						bottom: -5px;
						border-radius: 4px;
					}
				}
				.namaste-logo-section{
					position: absolute;
					top: 50%;
					right: 14%;
					transform: translateY(-50%);
				}
			}
			.contact-details-sec-group{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-top: 40px;
				.single-item{
					width: 49%;
					display: flex;
					flex-wrap: wrap;
					justify-self: center;
					margin-bottom: 15px;
					padding: 15px;
					.img-sec{
						height: 75px;
						width: 75px;
						text-align: center;
						line-height: 65px;
						border-radius: 68% 32% 27% 73% / 60% 62% 38% 40%;
						transition: .3s;
						display: inline-block;
						background-color: #f7c3602e;
						position: relative;
						padding: 10px;
						margin: 15px auto;
						img{
							height: 32px;
						}
					}
					.des{
						width: 100%;
						text-align: center;
						.title{
							font-size: 18px;
							color: $paragraph-color;
							line-height: 1.3;
							margin-bottom: 10px;
						}
						p{
							color: $fifth-color;
							font-size: 16px;
							font-weight: 400;
							line-height: 1.5;
							word-break: break-word;
							overflow-wrap: break-word;
						}
					}
				}
			}
		}
		.contact-form-section{
			padding: 0 40px 0 0;
			.title{
				color: $fifth-color;
				font-size: 35px;
				font-family: "Poppins";
				margin-bottom: 20px;
				line-height: 1.5;
				font-weight: 800;
				position: relative;
				margin-top: 0;
				&::before{
					content: '';
					width: 10px;
					height: 10px;
					border-radius: 50%;
					display: inline-block;
					background: #ffae00;
					position: relative;
					top: -2px;
					margin-right: 8px;
				}
				&::after{
					content: '';
					position: absolute;
					width: 75px;
					height: 3.5px;
					background-image: linear-gradient(120deg, #efd5a8 0%, #ffae00 100%);
					left: 1px;
					bottom: -5px;
					border-radius: 4px;
				}
			}
			.sub-title{
				color: $paragraph-color;
				font-size: 18px;
				font-family: "Poppins";
				margin-bottom: 5px;
			}
			.MuiGrid-root {
				margin: 3px auto;
				.MuiIcon-root {
					color: $input-color;
					font-size: 1.2rem;
					width: 1.2em;
				}
				label {
					.Mui-focused {
						color: $title-color-light;
					}
				}
				.MuiFormLabel-root {
					color: $input-color ;
					font-size: 14px;
					font-weight: 600;
					font-family: 'Poppins';
				}
				.MuiFormLabel-filled {
					font-weight: 600;
					color: $title-color;
				}
				.MuiInput-underline {
					&::after {
						border-bottom-color: $input-color;
					}
					&::before {
						border-bottom-color: $input-color;
					}
				}
			}
			.MuiFormControlLabel-root {
				.MuiTypography-body1 {
					color: $input-color;
					font-size: 15px;
					font-weight: 600;
				}
				.MuiCheckbox-root{
					color: $input-color;
				}
			}
			.custom-material-textarea-section{
				position: relative;
				display: flex;
				flex-wrap: wrap;
				align-items: stretch;
				width: 100%;
				justify-content: left;
				margin: 25px auto;
				.custom-material-textarea{
					outline: none;
					display: block;
					background: none;
					padding: .125rem .125rem .0625rem;
					font-size: 1rem;
					border: 0 solid transparent;
					line-height: 1.5;
					width: 100%;
					color: #333;
					box-shadow: none;
					opacity: .001;
					transition: opacity .28s ease;
					will-change: opacity;
				}
				.form-element-bar{
					position: relative;
					height: 1px;
					background: $input-color;
					display: block;
					width: 100%;
					&:after{
						content: "";
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						background: $input-color;
						height: 2px;
						display: block;
						transform: rotateY(90deg);
						transition: transform .28s ease;
						will-change: transform;
					}
				}
				.form-element-label{
					position: absolute;
					top: .75rem;
					line-height: 1.5rem;
					pointer-events: none;
					padding-left: .125rem;
					z-index: 1;
					font-weight: 400;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin: 0;
					transform: translateY(-50%);
					transform-origin: left center;
					transition: transform .28s ease,color .28s linear,opacity .28s linear;
					will-change: transform,color,opacity;
					color: $input-color ;
					font-size: 14px;
					font-weight: 600;
					font-family: 'Poppins';
				}
				.custom-material-textarea:focus{
					opacity: unset;
				}
				.custom-material-textarea:focus~.form-element-label {
					transform: translateY(-100%) translateY(-.5em) translateY(-2px) scale(.9);
					cursor: pointer;
					pointer-events: auto;
				}
				.custom-material-textarea:focus~.form-element-bar:after{
					transform: rotateY(0deg);
				}
			}
			.contact-btn{
				border-width: 2px;
				font-size: 18px;
				background-color: transparent;
				border: none;
				padding: 12px 30px;
				min-width: 250px;
				text-transform: capitalize;
				justify-content: center;
				@include button_blue;
			}
		}
	}
}
.google-map-form-section{
	margin-top: -200px;
	.google-map-form-container{
		box-shadow: 0 0.14px 2.29266px rgba(0,0,0,.03231), 0 0.37px 4.42626px rgba(0,0,0,.04769), 0 3px 7px rgba(0,0,0,.09);
		padding: 15px;
		border-radius: 10px;
		background: #fff;
		max-width: 98%;
		margin: 0 auto;
		>.row{
			align-items: center;
		}
	}
	.map-section{
		border-radius: 5px;
		overflow: hidden;
	}
}