@mixin navlink_under_design {
    font-family: 'Poppins';
    position: relative;
    &::after {
        height: 2px;
        width: 0;
        left: 50%;
        content: "";
        bottom: 3px;
        display: block;
        position: absolute;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        -webkit-transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
        -o-transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
        transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
    }
    &:hover {
        color: $eight-color !important;
        &::after {
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
            background-color: $eight-color;
            color: $eight-color;
            width: 100%;
            left: 0;
        }
    }
}