.slider-sec {
    .blog-post {
        .slick-prev {
            top: 40%;
        }
        .slick-next {
            top: 40%;
            right: 0;
            position: absolute;
        }
    }
    .blog-post .slick-slide {
        margin-left: auto;
        margin-right: auto;
        img {
            border-radius: inherit;
        }
    }
}
