.section-printing-artwork{
    position: relative;
    display: flex;
    align-items: center;
    .combined-img-sec{
        position: relative;
        .secondary-image{
            position: relative;
            z-index: 2;
            border-radius: 20px;
            box-shadow: 0px 3px 30px 0px rgba(0,0,0,.11);
            overflow: hidden;
            img{
                min-height: 450px;
                object-fit: cover;
            }
        }
        &:before{
            content: '';
            width: 100%;
            height: 100%;
            background: #4bc6b07a;
            position: absolute;
            border-radius: 5px;
            left: -60px;
            top: -60px;
        }
    }
    .combined-img-sec.right{
        &:before{
            right: -60px;
            left: unset;
        }
    }
    .sec-des{
        padding-left: 50px;
        .title{
            color: $fifth-color;
            font-weight: 900;
            font-size: 50px;
            margin-bottom: 30px;
            font-family: 'Poppins';
            position: relative;
            text-transform: capitalize;
            letter-spacing: 0.5px;
            &:before{
                content: '';
                width: 15px;
                height: 15px;
                border-radius: 50%;
                display: inline-block;
                background: #ffae00;
                position: relative;
                top: -10px;
                margin-right: 8px;
            }
            &:after{
                content: '';
                position: absolute;
                width: 95px;
                height: 5px;
                background-image: linear-gradient(120deg, #efd5a8 0%, #ffae00 100%);
                left: 1px;
                bottom: -5px;
                border-radius: 4px;
            }
        }
        .desc{
            color: $paragraph-color;
            font-size: 16px;
            line-height: 1.6;
            font-weight: 500;
        }
    }
    .sec-des.right{
        padding-right: 50px;
        padding-left: unset;
    }
}
.artwork-bottom-sec{
    .artwork-bottom-row{
        margin-bottom: 50px;
        .img-sec{
            border-radius: 20px;
            box-shadow: 0px 3px 30px 0px rgba(0,0,0,.11);
            overflow: hidden;
            padding: 50px;
        }
        .desc-sec{
            padding-left: 50px;
            height: 100%;
            display: flex;
            align-items: center;
            .title{
                color: $fifth-color;
                font-weight: 300;
                margin-bottom: 20px;
                font-size: 35px;
                line-height: 1.3;
                font-family: 'Poppins';
                position: relative;
                padding-left: 20px;
                &:before{
                    content: '';
                    width: 5px;
                    height: 85%;
                    background: #ffae00;
                    display: block;
                    position: absolute;
                    top: 10%;
                    left: 0;
                    border-radius: 10px;
                    transform: translateX(-50%);
                }
            }
        }
        .desc-sec.right{
            padding-left: unset;
            padding-right: 50px;
        }
    }
    .artwork-bottom-lastrow{
        margin-top: 40px;
        text-align: center;
        .desc{
            color: $paragraph-color;
            font-size: 16px;
            line-height: 1.6;
            font-weight: 500;
        }
        .title{
            color: $fifth-color;
            font-size: 25px;
            line-height: 1.6;
            font-weight: 600;
        }
    }
    
    
    
}