.store-owner-my-acc-details-section{
    .my-acc-details-main-title{
        color: $fifth-color;
        font-size: 25px;
        font-weight: 500;
        font-family: 'Poppins';
        line-height: 1.3;
        margin-bottom: 20px;
        span{
            color: $first-color;
        }
    }
    .my-acc-details-form-main-section{
        .my-acc-details-form-section{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            .multi-steps-form{
                width: 100%;
                .MuiStepper-vertical{
                    padding: 0;
                }
                .single-item{
                    .step-label{
                        .MuiStepLabel-iconContainer .MuiSvgIcon-root{
                            width: 40px;
                            height: 40px;
                        }
                        .MuiStepLabel-labelContainer .MuiStepLabel-label{
                            font-size: 15px;
                            font-family: 'Poppins';
                            text-transform: capitalize;
                            display: inline-block;
                            font-weight: 600;
                        }
                        .MuiStepLabel-label.MuiStepLabel-active{
                            color: $blue-color;
                        }
                        .MuiStepLabel-label.MuiStepLabel-completed{
                            color: $eight-color;
                        }
                        .MuiStepIcon-root.MuiStepIcon-active{
                            color: $blue-color;
                        }
                        .MuiStepIcon-root.MuiStepIcon-completed{
                            color: $eight-color;
                        }
                    }
                    .MuiStepContent-root{
                        position: relative;
                        border-left: none;
                        &::before{
                            content: '';
                            width: 3px;
                            height: 100%;
                            position: absolute;
                            border-left: 1px dashed $blue-color;
                            left: 9px;
                        }
                    } 
                }
                .MuiStepConnector-lineVertical{
                    border-left-style: dashed;
                    border-color: $blue-color;
                    margin-left: 9px;
                    min-height: 50px;
                }
                .MuiStepConnector-lineVertical.MuiStepConnector-active{
                    border-color: $eight-color;
                }
                .MuiCollapse-wrapperInner{
                    padding: 20px 20px 20px 30px;
                    .MuiGrid-root {
                        margin: 2px auto;
                        .MuiIcon-root {
                            color: $input-color;
                            font-size: 1.2rem;
                            width: 1.2em;
                        }
                        label {
                            .Mui-focused {
                                color: $title-color-light;
                            }
                        }
                        .MuiFormLabel-root {
                            color: $input-color ;
                            font-size: 14px;
                            font-weight: 600;
                            font-family: 'Poppins';
                        }
                        .MuiFormLabel-filled {
                            font-weight: 600;
                            color: $title-color;
                        }
                        .MuiInput-underline {
                            &::after {
                                border-bottom-color: $input-color;
                            }
                            &::before {
                                border-bottom-color: $input-color;
                            }
                        }
                    }
                    .multi-steps-btn-container{
                        margin-top: 30px;
                        display: flex;
                        justify-content: space-between;
                        min-height: 60px;
                        align-items: center;
                        .billing-back-btn{
                            @include red_button;
                            padding: 8px 30px;
                            font-size: 14px;
                            border-radius: 30px;
                            &:disabled{
                                cursor: not-allowed;
                                pointer-events: visible;
                            }
                        }
                        .billing-next-btn{
                            @include button_blue;
                            padding: 8px 30px;
                            font-size: 14px;
                            border-radius: 30px;
                            margin-left: auto;
                        }
                    }
                }
                .final-steps{
                    padding: 25px;
                    text-align: center;
                    .title{
                        color: $fifth-color;
                        font-size: 18.5px;
                        line-height: 1.5;
                        font-weight: 500;
                    }
                    .final-btn-sec{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .billing-back-final-btn{
                            @include red_button;
                            padding: 8px 30px;
                            font-size: 14px;
                            border-radius: 30px;
                            &:disabled{
                                cursor: not-allowed;
                                pointer-events: visible;
                            }
                        }
                        .billing-next-final-btn{
                            @include button_blue;
                            padding: 8px 30px;
                            font-size: 14px;
                            border-radius: 30px;
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
    }
}