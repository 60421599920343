.customer-portal{
    background: #f8f7fc;
    .account-info-main-sec{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 40px;
        .account-info-sec{
            background: #fff;
            padding: 10px;
            border-radius: 6px;
            display: flex;
            flex-wrap: wrap;
            min-height: 160px;
            height: 100%;
            .img-sec{
                width: 80px;
                padding: 5px;
                margin: 0 auto;
                img{
                    height: 60px;
                    width: 60px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
            .account-info-details{
                padding: 10px;
                width: 100%;
                text-align: center;
                .userName{
                    font-family: 'Poppins';
                    color: $fifth-color;
                    font-size: 18px;
                    font-weight: 600;
                    text-transform: capitalize;
                    margin: 0 0 5px;
                    line-height: 1.2;
                }
                .user-type{
                    color: $fifth-color;
                    font-size: 15px;
                    font-weight: 500;
                    text-transform: capitalize;
                    margin: 0 0 5px;
                    line-height: 1.3;
                }
                .edit-sec{
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    margin-top: 15px;
                    .nav-link{
                        padding: 0;
                        margin-bottom: 5px;
                        font-size: 13.5px;
                        font-family: 'Poppins';
                        font-weight: 400;
                        line-height: 1.4;
                        color: $title-color-dark;
                        width: 100%;
                        cursor: pointer;
                    }
                }
            }
        }
        .customer-info-header{
            display: flex;
            min-height: 150px;
            align-items: center;
            justify-content: center;
            height: 100%;
            background: $white;
            border-radius: 6px;
            padding: 15px 20px; 
            .customer-info-header-left{
                width: 75%;
                padding-right: 50px;
                .title{
                    color: $fifth-color;
                    font-size: 35px;
                    line-height: 1.2;
                    text-transform: capitalize;
                    font-family: 'Poppins';
                    font-weight: 800;
                    letter-spacing: 0.25px;
                    margin-bottom: 20px;
                    span:first-child{
                        color: $seven-color;
                        font-weight: 800;
                    }
                }
                .sub-title{
                    color: $paragraph-color;
                    font-family: 'Poppins';
                    font-size: 18px;
                    line-height: 1.5;
                    font-weight: 300;
                }
            }
            .customer-info-header-right{
                .customer-img{
                    height: 200px;
                }
            }
        }
    }
    .customer-portal-tab-sec{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .customer-portal-sidebar{
            background: $white;
            padding: 15px 20px;
            border-radius: 6px;
            .nav-item{
                &:last-child .nav-link{
                    margin-bottom: 0 !important;
                }
                .nav-link{
                    background-image: none !important;
                    text-align: left;
                    background: none !important;
                    color: $paragraph-color;
                    font-size: 17px;
                    font-weight: 600;
                    box-shadow: none;
                    padding: 15px 10px;
                    margin-bottom: 10px !important;
                    display: flex;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
                    transition: 0.5s ease;
                    flex-wrap: wrap;
                    margin-bottom: 10px;
                    justify-content: center;
                    border-radius: 6px;
                    i{
                        width: 45px;
                        height: 45px;
                        font-size: 18px;
                        line-height: 24px;
                        background: #f8f7fc;
                        border-radius: 50%;
                        margin-right: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    span{
                        display: block;
                        text-align: center;
                        width: 100%;
                    }
                    &:after{
                        height: 4px;
                        width: 0;
                        left: 50%;
                        content: "";
                        bottom: 1px;
                        display: block;
                        position: absolute;
                        transition: 0.3s;
                        transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
                        border-radius: 30px;
                    }
                    &:hover{
                        background: #f8f7fc !important;
                    }    
                }
                .nav-link.active{
                    color: $title-color-dark;
                    font-weight: 600;
                    transition: 0.5s ease;
                    background: #f8f7fc !important;
                    &:after{
                        transform: scale(1);
                        background-color: $title-color-dark;
                        width: 100%;
                        left: 0;
                    }
                }
            }
        }
        .customer-portal-desc-sec{
            background: $white;
            border-radius: 10px;
            height: 100%;
            padding: 15px;
            position: relative;
            overflow: hidden;
        }
    }
}

@import './my-orders/my-orders.scss';
@import './my-profile/my-profile.scss';
@import './shipping-address/shipping-address.scss';