.share-your-store-header-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    .share-your-store-header{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        .title{
            @include ribon_header;
            font-size: 45px;
            line-height: 1.3;
            text-transform: capitalize;
            span:last-child{
                left: -30px;
                text-transform: uppercase;
                &::before{
                    border-radius: 20px;
                }
            }
        }
        .create-store-back-btn{
            border-color: $white;
            background-color: unset !important;
            border-color:transparent;
            float: unset !important;
            font-size: 15px;
            position: absolute;
            left: 15px;
            i{
                margin-right: 15px;
            }
            @include red_button;
        }
    }
}

.share-your-step-main{
    .step-item{
        position: relative;
        margin-right: 50px;
        .nav-link{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            position: relative;
            margin: 0 !important;
            color: #05445E;
            background-image: none !important;
            background-color: #efefef !important;
            font-size: 20px;
            overflow: visible;
            line-height: 1.2;
            border-radius: 0 !important;
            border-right: 10px solid transparent;
            height: 70px;
            min-width: 250px;
            padding: 0;
            box-shadow: none !important;
            margin-right: 50px !important;
            left: 50px;
            &::before{
                content: "";
                position: absolute;
                left: calc(100% + 10px);
                top: -3px;
                height: 0;
                width: 0;
                border-bottom: 38px solid transparent;
                border-top: 38px solid transparent;
                border-left: 34px solid #05445E;
                z-index: 1;
            }
            &::after{
                content: "";
                position: absolute;
                left: calc(100% + 10px);
                top: -3px;
                height: 0;
                width: 0;
                border-bottom: 38px solid transparent;
                border-top: 38px solid transparent;
                border-left: 20px solid #efefef;
                z-index: 2;
            }
            .step-item-tag{
                background: #efefef;
                height: 85px;
                display: flex;
                align-items: center;
                position: absolute;
                width: 85px;
                justify-content: center;
                border-radius: 50%;
                left: -35px;
                box-shadow: 2px 5px 10px 0px #05445e69;
                &::before{
                    content: '';
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    right: 12px;
                    bottom: 7px;
                    border: 10px solid #05445E;
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                }
            }
            >span{
                display: inline-block;
                padding: 0 20px 0 65px;
            }
            &:hover{
                color: #05445E;
            }
        }
        .nav-link.active{
            color:#53a5fc;
            &::before{
                border-left: 34px solid #53a5fc !important;
            }
            .step-item-tag{
                box-shadow: 2px 5px 10px 0px #53a5fc5c;
                &:before{
                    border: 10px solid #53a5fc;
                }
            }
            &:hover{
                color: #53a5fc;
            }
        }
    }
}

@import "./personalize.scss";
@import "./billing-fundraising.scss";