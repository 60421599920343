.feature-filter-sec {
    .feature-title {
        color: $subtitle-color;
        margin-bottom: 10px;
    }
    li {
        color: $title-color;
        .MuiFormControlLabel-root {
            margin-bottom: 0;
            color: $paragraph-color;
            .Mui-checked {
                .MuiTypography-body1 {
                    color: $title-color;
                }
            }
        }
    }
}
