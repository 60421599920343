.personalize-mascot-modal {
  .modal-content {
    .modal-body {
      .upload-mascot-edit-section-subtitle {
        display: flex;
        font-size: 12px;
        font-family: "Poppins";
        font-weight: 400;
        background: $filter-background;
        padding: 5px;
        border-radius: 5px;
        width: 50%;
        margin: 14px auto 10px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 2px;
        }
      }
      .upload-mascot-edit-image {
        display: flex;
        justify-content: center;
        padding: 5px;
        border: 1px solid $filter-background;
        border-radius: 5px;
        width: 50%;
        margin: 0 auto;
        img {
          padding: 15px 0;
          height: 250px;
        }
      }
      .upload-mascot-edit-image-color {
        padding: 0 10px 20px;
        border: 1px solid $card-border;
        margin: 10px auto 0;
        position: relative;
        border-radius: 4px;
        legend {
          width: fit-content;
          padding: 0 5px;
          font-size: 16px;
          color: $fifth-color;
          font-weight: 500;
          margin: 0 0 0 15px;
          font-family: "Poppins";
        }
        .upload-mascot-edit-main {
          padding: 15px 10px;
          .upload-mascot-edit-color-heading {
            font-size: 15px;
            font-weight: 600;
            padding-bottom: 5px;
          }
          .upload-mascot-edit-radio-sec {
            display: flex;
            .upload-mascot-edit-color-content {
              width: unset;
              min-width: 200px;
            }
          }
          .upload-mascot-edit-color-content {
            width: 33%;
            .upload-mascot-radio-input {
              position: absolute;
              left: -9999px;
              &:checked + span {
                color: $fifth-color;
                &:before {
                  box-shadow: inset 0 0 0 0.4375em $title-color-dark;
                }
              }
            }
            span {
              display: flex;
              align-items: center;
              padding: 0.275em 0.55em 0.275em 0.355em;
              transition: 0.25s ease;
              color: $paragraph-color;
              font-size: 13px;
              font-weight: 600;
              font-family: "Poppins";
              line-height: 1.1;
              &:before {
                display: flex;
                flex-shrink: 0;
                content: "";
                background-color: $nav-color;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                margin-right: 0.6em;
                margin-left: 2px;
                transition: 0.25s ease;
                box-shadow: inset 0 0 0 0.125em $paragraph-color;
                position: relative;
              }
            }
          }
        }
      }
      .mascot-edit-continue-btn {
        background: $orange-btn;
        font-size: 12px;
        padding: 10px 20px;
        font-weight: 600;
        border-radius: 30px;
        color: $black-color;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
        margin-top: 30px;
        text-transform: uppercase;
        width: 150px;
        min-width: 210px;
        display: block;
        margin: 20px auto 0;
        &:hover,
        &:focus,
        &:active {
          background: $orange-btn !important;
          color: $black-color;
        }
      }
    }
  }
}
