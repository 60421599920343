//Checkbox design for all
@mixin Check_all{
    .custom-checkbox-design{ 
        height: 0; 
        width: 0; 
    }
    .custom-checkbox-design + .custom-checkbox-label{
        position: relative;
        display: inline-flex;
        align-items: center;
        color: #9197b6;
        transition: color 250ms cubic-bezier(.4,.0,.23,1);
        font-size: 20px;
        cursor: pointer;
    }
    .custom-checkbox-design + .custom-checkbox-label > ins{
        position: absolute;
        display: block;
        bottom: 0;
        left: 2em;
        height: 0;
        width: 100%;
        overflow: hidden;
        text-decoration: none;
        transition: height 300ms cubic-bezier(.4,.0,.23,1);
    }
    .custom-checkbox-design + .custom-checkbox-label > ins > i{
        position: absolute;
        bottom: 0;
        font-style: normal;
        color: $fifth-color;
    }
    .custom-checkbox-design + .custom-checkbox-label > span{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1em;
        width: 1em;
        height: 1em;
        background: transparent;
        border: 2px solid #9197b6;
        border-radius: 2px;
        cursor: pointer;  
        transition: all 250ms cubic-bezier(.4,.0,.23,1);
    }
    .custom-checkbox-design + .custom-checkbox-label:hover, 
    .custom-checkbox-design:focus + .custom-checkbox-label{
        color: #9e9e9e;
    }
    .custom-checkbox-design + .custom-checkbox-label:hover > span, 
    .custom-checkbox-design:focus + .custom-checkbox-label > span{
        background: rgba(255,255,255,.1);
    }
    .custom-checkbox-design:checked + .custom-checkbox-label > ins{ 
        height: 100%; 
    }
    .custom-checkbox-design:checked + .custom-checkbox-label > span{
        border: .5em solid #11bfa45e;
        animation: shrink-bounce 200ms cubic-bezier(.4,.0,.23,1);
    }
    .custom-checkbox-design:checked + .custom-checkbox-label > span:before{
        content: "";
        position: absolute;
        top: 5px;
        left: .2em;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        transform: rotate(45deg);
        transform-origin: 0% 100%;
        animation: checkbox-check 125ms 250ms cubic-bezier(.4,.0,.23,1) forwards;
    }
}