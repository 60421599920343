.secure-section{
	background-color: #d6f9ff;
  padding: 40px 0;
  .secure-image1,
  .secure-image2,
  .secure-image3,
  .secure-image4{
  	display: flex;
  	justify-content: center;
  	align-items: center;
  }
}