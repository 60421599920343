.personalize-mascot-modal {
  max-width: 750px;
  transform: translate(0, 0) !important;
  .modal-content {
    .modal-header {
      .modal-title {
        color: $input-color;
        margin: 5px auto;
        position: relative;
        font-size: 18px;
        &::before {
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 50%;
          display: inline-block;
          background: $orange-btn;
          position: relative;
          top: -2px;
          margin-right: 8px;
        }
        &::after {
          content: "";
          position: absolute;
          width: 75px;
          height: 3.5px;
          background-image: linear-gradient(
            120deg,
            #efd5a8 0%,
            $orange-btn 100%
          );
          left: 1px;
          bottom: -10px;
          border-radius: 4px;
        }
      }
    }
    .modal-body {
      .image-upload-term-section {
        padding: 0 10px 20px;
        border: 1px solid #ddd;
        margin: 0 auto;
        position: relative;
        border-radius: 4px;
        legend {
          width: fit-content;
          padding: 0 5px;
          font-size: 16px;
          color: #6b6b6b;
          font-weight: 600;
          margin: 0 0 0 15px;
          font-family: "Poppins";
        }
        .image-upload-term {
          display: flex;
          font-size: 15px;
          padding: 5px;
          line-height: 1.6;
        }
      }
      .term-checkbox {
        margin-top: 10px;
        .MuiIconButton-label {
          color: $sixth-color;
        }
      }
      .supported-file-type {
        margin-bottom: 10px;
        background: #ffae0014;
        min-height: 90px;
        padding: 10px 5px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 25px;
        .supported-file-title {
          color: $input-color;
          margin-bottom: 2px;
          font-size: 15px;
          font-weight: 600;
          text-transform: uppercase;
          text-align: center;
          width: 100%;
          line-height: 1.2;
          height: unset;
        }
        .supported-file-content {
          color: $nine-color;
          font-size: 14px;
          display: block;
          text-align: center;
          line-height: 1.6;
          width: 100%;
        }
      }
      .personalization-upload-mascot {
        margin: 5px 0;
        text-align: center;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
        width: 70%;
        margin: 20px auto 0;
        .thumbnail {
          min-height: 100px;
          display: flex;
          justify-content: center;
          box-shadow: unset;
          .uploaded-image {
            object-fit: contain;
            max-width: 60%;
            width: 240px;
          }
        }
        .btn-round {
          padding: 10px 20px;
        }
        .btn-primary {
          background-color: #ffae00 !important;
          background-image: unset !important;
          font-weight: 600;
        }
      }
      .material_checkbox_term {
        .material_checkbox {
          position: relative;
          margin: 25px;
          &::before {
            border: 2px solid #ccc;
            content: "";
            cursor: pointer;
            display: block;
            height: 18px;
            position: absolute;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            width: 18px;
            z-index: 1;
          }
          &::after {
            background: #fff;
            content: "";
            display: block;
            height: 18px;
            position: absolute;
            top: 0;
            width: 18px;
            z-index: 0;
          }
          &:checked:before {
            border-top-style: none;
            border-right-style: none;
            height: 10px;
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            border-color: $sixth-color;
          }
        }
        label {
          font-size: 15px;
          font-weight: 500;
          font-family: "Poppins";
        }
        .material_checkbox:checked ~ label {
          color: $sixth-color;
        }
      }
    }
  }
}
