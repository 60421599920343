.all-stores-main-section{
    .stores-main-title{
        color: $fifth-color;
        font-size: 25px;
        font-weight: 500;
        font-family: 'Poppins';
        line-height: 1.3;
        margin-bottom: 20px;
        span{
            color: $first-color;
        }
    }
    .all-store-cards{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .card{
            position: relative;
            width: 100%;
            margin-bottom: 30px;
            border-radius: 6px;
            background: #f8f7fcad;
            box-shadow: 0 2px 2px 0 #dbd7ea, 0 3px 1px -2px #d5d2ec, 0 1px 5px 0 #dad4f1;
            margin: 15px 0;
            margin-top: 30px;
        }
        .store-create-new-card{
            padding: 50px 0;
            // border: 1px dashed #ffae00;
            // background: $white;
            .build-store{
                background: #ffae00;
                box-shadow: 3px 6px 10px 0px #ffae0054;
                border-radius: 50px;
                transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
                color: $fifth-color;
                font-weight: 600;
                position: relative;
                font-size: 15px;
                padding: 10px 20px;
                letter-spacing: 0.25px;
                font-family: "Poppins", sans-serif;
                display: flex;
                align-items: center;
                align-self: center;
                img{
                    width: 28px;
                    margin-right: 5px;
                }
            }
            
        }
        .store-details-card {
            .store-details-card-content{
                padding: 10px;
                .store-details-thumb{
                    margin: 0 auto;
                    margin-top: -30px;
                    width: 95%;
                    position: relative;
                    img{
                        height: 120px;
                        object-fit: cover;
                        box-shadow: 0 3px 10px -3px #dbd7f7, 0 2px 3px -2px #dbd7ea;
                        border-radius: 5px;
                    }
                    .st-id{
                        font-size: 14px;
                        line-height: 1.2;
                        font-weight: 600;
                        display: inline-block;
                        background: $title-color-dark;
                        color: $white;
                        padding: 3px 10px;
                        border-radius: 30px;
                        letter-spacing: 1px;
                        margin-bottom: 8px;
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        text-transform: uppercase;
                    }
                }
                .store-details-card-title{
                    color: $title-color-dark;
                    font-family: "Poppins", sans-serif;
                    font-size: 18px;
                    font-weight: 600;
                    display: flex;
                    align-items: flex-start;
                    margin-top: 20px;
                    margin-bottom: 13px;
                    justify-content: center;
                    text-transform: uppercase;
                    span.live{
                        font-size: 12px;
                        font-weight: 600;
                        color: #157d15;
                        text-transform: uppercase;
                        letter-spacing: 0.75px;
                        margin-left: 10px;
                        display: inline-flex;
                        position: relative;
                        top: -4px;
                        .live-circle{
                            width: 7px;
                            height: 7px;
                            border-radius: 50%;
                            margin-left: 4px;
                            position: relative;
                            top: 4px;
                            left: -2px;
                            &::before,
                            &::after{
                                content: '';
                                background: #157d15;
                                width: 7px !important;
                                height: 7px !important;
                                border-radius: 50%;
                                display: block;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                            &::before{
                                z-index: 0;
                                animation: pulse-border-custom 1500ms ease-out infinite;
                            }
                            &::after{
                                z-index: 1;
                            }
                        }
                    }
                }
                .store-card-desc{
                    text-align: center;
                    margin-bottom: 16px;
                    .status{
                        font-size: 14px;
                        line-height: 1.3;
                        font-weight: 600;
                        display: inline-block;
                        color: $fifth-color;
                        width: 100%;
                        text-transform: capitalize;
                        .pending{
                            color: #d69508;
                        }
                        .completed{
                            color: #157d15;
                        }
                    }
                }
                .store-card-footer{
                    border-top: 1px solid #9595958c;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 7px;
                    .store-manage-btn,
                    .store-delete-btn{
                        padding: 5px 14px !important;
                        font-size: 13.5px;
                        line-height: 1.2;
                        display: inline-flex;
                        border-radius: 30px;
                        font-weight: 600;
                        
                    }
                    .store-manage-btn{
                        transform: none;
                        box-shadow: 1px 2px 5px 0px #52b0fd6b;
                        color: $white;
                        background-image: none;
                        background: #238be0d1;
                        &:hover,
                        &:focus,
                        &:visited{
                            background-image: none !important;
                            background-color: #1988e8 !important;
                            box-shadow: 2px 3px 10px 0px #52b0fd6b;
                        }
                    }
                    .store-delete-btn{
                        margin-left: 10px;
                        transform: none;
                        background-image: none;
                        background: #f44336d6;
                        box-shadow: 1px 2px 5px 0px #f4cac29c;
                        &:hover,
                        &:focus,
                        &:visited{
                            background-image: none !important;
                            background-color: #e84034 !important;
                            box-shadow: 2px 3px 10px 0px #f4cac29c;
                        }
                    }
                }
            }
        }
    }
}
@keyframes pulse-border-custom {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        -ms-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }
    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.75);
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.75);
        -ms-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.75);
        opacity: 0;
    }
}