//button style for green
@mixin button_blue{
    display: flex !important;
    align-items: center;
    color: $white !important;
    font-weight: 600;
    background-image: linear-gradient(0deg, #5878f7 0%, #52b0fd 100%) !important;
    box-shadow: 3px 6px 10px 0px #549afb3d;
    transition: 0.3s ease;
    &:hover,
    &:active,
    &:focus{
        background-color: unset !important;
        background-image: linear-gradient(0deg, #5878f7 0%, #52b0fd 100%) !important;
        box-shadow: 3px 6px 10px 0px #549afb3d !important;
        border-color: transparent !important;
        color: $white;
    }
}