@import "./_variables.scss";

@import "customized-theme/customized-theme.scss";
@import "commonstyle/commonstyle.scss";
@import "components/Navbars/custom-navbar.scss";
@import "components/Footers/custom-footer.scss";


@import "views/auth/auth.scss";
@import "views/auth/customer/customer.scss";

@import "views/home/video.scss";
@import "views/home/feature.scss";
@import "views/home/step.scss";
@import "views/home/slider.scss";
@import "views/home/create-print.scss";
@import "views/home/content.scss";
@import "views/home/secure.scss";

// @import "views/store/store.scss";
@import "views/catalog/catalog.scss";
@import "views/addLogo/addLogo.scss";

@import "views/store/store.scss";

@import "components/custom/color-picker/color-picker.scss";
@import "components/custom/loader/loader.scss";

@import "components/custom/category-card/category-card.scss";

@import "views/logo-generator/logo-generator.scss";
@import "views/faq/faq.scss";
@import "views/aboutus/aboutus.scss";
@import "views/fund-raising/fund-raising.scss";
@import "views/career/career.scss";
@import "views/artwork-decoration/artwork-decoration.scss";
@import "views/contact-us/contact-us.scss";
@import "views/terms-conditions/terms-conditions.scss";
@import "views/privacy-policy/privacy-policy.scss";
@import "views/return-policy/return-policy.scss";
@import "views/store-owner-portal/store-owner-portal.scss";

//customer portal
@import "views/customer-portal/customer-portal.scss";
//admin portal
@import "views/admin-portal/admin-portal.scss";

.container {
    max-width: 80%;
}

// over-riding theme body color
body{
    background-color: #fff;
}
