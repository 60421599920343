.design-tab-main-section {
  padding: 10px 0;
  position: relative;
  .custom-select-desgin {
    .MuiInput-underline:before {
      border-width: 1.5px;
    }
    .MuiInput-root {
      font-size: 14px;
      font-family: "Poppins";
      font-weight: 600;
      color: $paragraph-color;
    }
  }
  .title-sec {
    .title {
      color: $fifth-color;
      font-weight: 600;
      text-transform: capitalize;
      line-height: 1.3;
      margin-bottom: 15px;
      margin-top: 30px;
      font-family: "Poppins";
      position: relative;
      font-size: 16px;
      &:after {
        content: "";
        position: absolute;
        width: 80px;
        height: 4px;
        background-image: linear-gradient(120deg, #efd5a8 0%, #ffae00 100%);
        left: 1px;
        bottom: -8px;
        border-radius: 4px;
      }
    }
  }
  .design-img-sec {
    width: calc(100% + 20px);
    position: relative;
    left: -10px;
    .slick-list {
      margin-top: 25px;
    }
    .slick-prev,
    .slick-next {
      border-radius: 50%;
      padding: 0 !important;
      background-color: #242f67 !important;
      color: #ffffff !important;
      font-size: 15px !important;
      font-weight: 600;
      border-color: #242f67 !important;
      background-image: none;
      width: 27px !important;
      height: 26px !important;
      min-width: unset;
      position: absolute;
      i {
        font-weight: 600;
        font-size: 12px !important;
        width: unset !important;
        line-height: 12px !important;
        position: relative !important;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: unset !important;
        top: unset !important;
        left: unset !important;
      }
      &:hover,
      &:focus,
      &:active {
        border-radius: 50%;
        color: white !important;
        background-color: $title-color-dark !important;
        background-image: none !important;
        box-shadow: none;
        &:focus {
          border-radius: 50%;
          color: white !important;
          background-color: $title-color-dark !important;
          background-image: none !important;
          box-shadow: none;
        }
      }
    }
    .slick-prev {
      right: 43px;
      top: -45px;
    }
    .slick-next {
      top: -45px;
      right: 10px;
    }
    .single-img {
      padding: 10px;
      cursor: pointer;
    }
    .slick-slide {
      margin: 0;
    }
    .slick-dots {
      display: flex !important;
      justify-content: center;
      padding: 0;
      li button {
        font-size: 0;
        line-height: 0;
        padding: 0;
        list-style: none;
        background-color: $title-color-light;
        border: none;
      }
      li {
        position: relative;
        display: inline-block;
        margin: 0;
        button {
          height: 10px;
          width: 10px;
          padding: 0;
          transition: 200ms ease-in-out;
          position: relative;
          border-radius: 50%;
          margin: 0 5px;
          display: inline-block;
          &:before {
            content: "";
            font-size: 14px;
            height: 10px;
            width: 10px;
            opacity: 0;
            background: $title-color-light;
            top: 0;
            left: 0;
            transition: 200ms ease-in-out;
            position: absolute;
          }
          &:hover {
            opacity: 1;
          }
          &:marker {
            display: none;
          }
        }
      }
      li.slick-active {
        button {
          width: 30px;
          &:before {
            width: 30px !important;
            opacity: 1;
            border-radius: 10px;
            background: $title-color-dark;
          }
        }
      }
    }
  }
  .btn.next-design {
    position: absolute;
    right: 0px;
    bottom: -15px;
    transform: unset !important;
    padding: 7px 10px 7px 20px;
    background: unset;
    background: $title-color-dark;
    color: $filter-background;
    border-radius: 25px;
    margin: 2px !important;
    box-shadow: 1px 2px 3px 0px #242f6778;
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins";
    text-align: right;
    line-height: 1;
    i {
      border: 1px solid;
      border-radius: 15px;
      background: $filter-background;
      color: $title-color-dark;
      margin-left: 5px;
      padding: 4px 5px;
    }
    &:hover,
    &:active,
    &:focus {
      background-image: none !important;
      background: $title-color-dark !important;
      color: $filter-background;
      box-shadow: 1px 2px 5px 0px $title-color-dark !important;
    }
  }
}
