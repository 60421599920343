.catalog {
    .refine-search-sec {
        padding-top: 5%;
        background-color: #f5f5f5;
        padding-left: 2%;
        padding-right: 2%;
        padding-bottom: 5%;
        .top-heading {
            color: $title-color;
            margin-bottom: 10px;
        }
        hr {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
        @import "./cateogory-filter.scss";

        @import "./gender-filter.scss";

        @import "./size-filter.scss";

        @import "./color-filter.scss";

        @import "./feature-filter.scss";

        @import "./manufacturer-filter.scss";

        @import "./logo-image-filter.scss";

        @import "./logo-location-filter.scss";

        @import "./logo-type-filter.scss";
        .MuiTreeItem-content {
            flex-direction: row-reverse;
            text-transform: uppercase;
            padding: 15px 0;
            .MuiTreeItem-label {
                background-color: transparent;
                font-size: 16px;
                color: #15212c;
                font-weight: 700;
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
    .catalog-right-sec {
        @import "./search-box.scss";

        .top-heading {
            color: $title-color;
            margin-bottom: 10px;
        }
        hr {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            border-style: dashed;
            border-top-width: 2px;
        }
        .selection-instruction {
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            font-size: 18px;
            margin-bottom: 20px;
            p {
                color: #15212c;
                font-weight: 500;
            }
        }
        .sort-by {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            margin-bottom: 10px;
            p {
                width: 20%;
                color: #15212c;
            }
            .sort-by-select {
                width: 80%;
            }
        }
        .search-item-filter-count-sec {
            p {
                color: $paragraph-color;
                font-size: 15px;
                font-weight: 400;
                margin-bottom: 0;
                margin-right: 5px;
                display: inline-block;
                span {
                    color: $title-color;
                    font-weight: 500;
                }
            }
            .filter-tag {
                color: #ffffff;
                padding: 2px 9px;
                border-radius: 25px;
                margin-right: 5px;
                margin-bottom: 6px;
                display: inline-block;
                font-size: 15px;

                .tim-icons {
                    font-size: 15px;
                    margin-left: 6px;
                    cursor: pointer;
                }
            }
        }
        .search-item-sec {
            .item-card {
                .item-thumb {
                    height: 200px;
                }
                .item-card-content {
                    padding: 0 5px 5px;
                    .item-card-title {
                        font-size: 14px;
                        margin-top: 0;
                        padding-top: 0;
                    }
                    .item-details {
                        .item-list {
                            .item {
                                i {
                                    font-size: 14px;
                                    margin-right: 3px;
                                }
                                .item-sec {
                                    margin-left: 3px;
                                    .value {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .sponsored-result-sec {
            .col-md-2,
            .col-lg-2 {
                flex: 0 0 20%;
                max-width: 20%;
                .item-card {
                    .item-thumb {
                        height: 150px;
                    }
                    .item-card-content {
                        padding: 0 5px 5px;
                        .item-card-title {
                            font-size: 13px;
                            margin-top: 0;
                            padding-top: 0;
                        }
                        .item-details {
                            flex-direction: column;
                            .item-list {
                                .item {
                                    i {
                                        font-size: 15px;
                                        margin-right: 3px;
                                    }
                                    .item-sec {
                                        margin-left: 3px;
                                    }
                                }
                            }
                        }
                        .view-item-btn {
                            margin: 0 1rem;
                        }
                    }
                }
            }
        }
    }
}
