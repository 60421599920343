.category-header{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    .title{
        @include ribon_header;
    }
    .header-button-sec{
        align-self: center;
        .create-store-save-next{
            border-color: $fifth-color;
            background-color: unset !important;border-color:transparent;
            @include button_blue;
            font-size: 15px;
            margin: 0;
            i{
                margin-left: 15px;
            }
        }
    }
    .sub-title{
        color: #111111;
        font-size: 16.5px;
        line-height: 1.5;
        font-weight: 500;
    }
    .category-left{
        max-width: calc(100% - 230px);
        flex-basis: calc(100% - 230px);
    }
    .category-right{
        max-width: 230px;
        flex-basis: 230px;
    }
}
.custom-checkbox-design-sec{
    @include Check_all;
}
.category-card-main{
    display: flex;
    flex-wrap: wrap;
    margin: 50px 0;
    width: 100%;
    > .category-item {
        max-width: 20%;
        flex: 0 0 20%;
    }
}
.btn.create-store-save-next-last{
    border-width: 2px;
    font-size: 20px;
    background-color: transparent;
    border: none;
    padding: 12px 40px;
    @include button_blue;
    text-transform: capitalize;
    i{
        margin-left: 15px;
    }
}
.category-section-main{
    position: relative;
    .category-header.sticky{
        position: fixed;
        top: 70px;
        background: #fff;
        padding: 15px 0px;
        z-index: 500;
	}
}