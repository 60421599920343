.create-store-step-main{
    margin-bottom: 50px;
    .step-item{
        position: relative;
        margin-right: 100px;
        .nav-link{
            background-color: #05ab8e !important;
            color: #fff !important;
            line-height: 1;
            margin: 0;
            display: flex;
            align-items: center;
            transition: all 0.5s ease-in;
            width: 140px;
            height: 140px;
            border-radius: 50% !important;
            background-image: none !important;
            font-size: 19px;
            font-weight: 600;
            margin-right: 10px;
            margin-bottom: 5px;
            box-shadow: none !important;
            &::before{
                content: " ";
                position: absolute;
                z-index: 1;
                top: 2px;
                left: 2px;
                right: 12px;
                bottom: 7px;
                border: 5px solid #ffffff;
                border-radius: 50%;
            }
            &::after{
                content: '';
                width: 5px;
                height: 20px;
                background: #fff;
                display: block;
                position: absolute;
                left: 0;
                top: 45%;
                transform: translateY(-50%);
            }
            .step-item-tag{
                background: #fff;
                border: 2px solid #05ab8e;
                border-radius: 50%;
                color: #05ab8e;
                font-weight: 700;
                font-size: 17px;
                width: 40px;
                height: 40px;
                line-height: 2.15;
                position: absolute;
                text-align: center;
                left: 45%;
                margin: -35px 0 0 -33px;
                z-index: 9;
                transform: rotate(230deg) translate(3em) rotate(-230deg);
            }
            >span{
                line-height: 1.4;
                display: flex;
                width: 100%;
                justify-content: center;
                align-self: center;
                font-weight: 700;
            }
            .bar-fill{
                width: 92px;
                background: #fff;
                height: 20px;
                position: absolute;
                right: -75px;
                z-index: 9;
                padding: 4px;
                border-radius: 0;
                top: 45%;
                transform: translateY(-50%);
                &::before{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    bottom: 0px;
                    background-color: #05ab8e;
                    display: block;
                    border-radius: 11px;
                }
                .progress-bar-arrow{
                    position: absolute;
                    padding: 15px;
                    box-shadow: 1px -1px 0 1px #05ab8e inset;
                    -webkit-box-shadow: 2px -2px #05ab8e inset;
                    border: solid transparent;
                    border-width: 0 0 1.2rem 1.2rem;
                    transition: 0.2s;
                    transform: rotate(225deg);
                    top: -5px;
                    right: -20px;
                }
            }
        }
        .active.nav-link{
            background-color: #53a5fc !important;
            color: #fff !important;
            box-shadow: 2px 5px 10px 0px #53a5fc5c !important;
            .step-item-tag{
                border-color: #53a5fc;
                color: #53a5fc;
            }
            .bar-fill{
                &::before{
                    background-color: #53a5fc;
                }
                .progress-bar-arrow{
                    box-shadow: 1px -1px 0 1px #53a5fc inset;
                    -webkit-box-shadow: 2px -2px #53a5fc inset;
                }
            }
            
        }
        
    }
    .step-item:last-child{
        margin-right: 0;
    }
    .step-item.first .nav-link:after{
        content: none;
    }
}

#select-gear-accordion{
    .accordion > .card{
        background-color: #fff;
        margin-bottom: 40px;
        margin-top: 0;
        box-shadow: none;
        border-bottom: 1px solid !important;
        .card-header{
            background-color: #0dcaac0a;
            border-radius: 3px;
            padding: 10px 15px;
            h4{
                color: $fifth-color !important;
            }
            .btn.btn-link{
                color: $fifth-color !important;
                font-size: 22px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                >i{
                    width: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 50px;
                    border: 2px solid #30bba4d6;
                    border-radius: 50%;
                }
                >.acrrodion-custom-title{
                    width: calc(100% - 50px);
                    
                    span:first-child{
                        color: $first-color;
                        font-size: 30px;
                        line-height: 1.2;
                        font-weight: 300;
                    }
                    span:last-child{
                        color: $fifth-color;
                        font-size: 45px;
                        line-height: 1.3;
                        font-weight: 800;
                        letter-spacing: 0.5px;
                        position: relative;
                        padding-left: 100px;
                        &:before{
                            content: '';
                            position: absolute;
                            width: 95px;
                            height: 5px;
                            background-image: linear-gradient(120deg, #3ddcc2 0%, #07a088 100%);
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
                &:hover{
                    color: $fifth-color !important;
                }
                &:visited{
                    color: $fifth-color !important;
                }
                &:active{
                    color: $fifth-color !important;
                }
            }
            .tim-icons{
                transform: rotate(-90deg);
            }
            .btn[aria-expanded="true"] .tim-icons{
                transform: rotate(180deg);
            }
        }
    }
}

@import "./style-selection.scss";

@import "./category-selection.scss";

@import "./logo-selection.scss";

@import "./product-added-modal.scss";

@import "./product-info-modal.scss";

@import "./share-your-store/share-your-store.scss";