.shipping-address-lists-sec{
    width: 100%;
    .address-list-sec {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 0px;
        margin-bottom: 10px;
        h3 {
            color: $fifth-color;
            font-weight: 600;
            font-size: 18px;
            font-family: "Poppins";
            position: relative;
            i{
                margin-right: 10px;
            }
        }
    }
    .add-new-customer-common-btn{
        margin-top: 10px;
        @include button_blue;
        background-image: none !important;
        background: none !important;
        border-radius: 30px;
        text-transform: uppercase;
        box-shadow: none !important;
        transform:none !important;
        border: 2px solid transparent;
        padding: 9px 18px;
    }
    .add-new-customer-back-btn{
        color: #ffae00 !important;
        i{
            margin-right: 5px;
        }
        &:hover,&:active, &:focus, &:visited{
            border: 2px solid #ffae00 !important;
            background-image: none !important;
            box-shadow: none !important;
        }
    }
    .add-address-btn{
        color: $seven-color !important;
        i{
            margin-left: 5px;
            font-weight: 600;
        }
        &:hover,&:active, &:focus, &:visited{
            color: $seven-color !important;
            border: 2px solid $seven-color !important;
            background-image: none !important;
            box-shadow: none !important;
        }
    }
    .shipping-address-section{
        width: 100%;
        padding:0 10px 30px;
        border: 1px solid #ddd;
        margin: 0 auto 30px;
        position: relative;
        border-radius: 4px;
        legend {
            width: fit-content;
            padding: 0 5px;
            font-size: 16px;
            color: $paragraph-color;
            font-weight: 600;
            margin: 0 0 0 15px;
            font-family: 'Poppins';
            span{
                display: inline-block;
                line-height: 0.9;
                background: #ffae00;
                border-radius: 30px;
                padding: 5px 15px;
                margin-left: 5px;
                color: $fifth-color;
            }
        }
        .selected-address{
            padding: 0 15px;
            margin: 8px auto -18px;
            display: flex;
            flex-flow: row-reverse;
            position: relative;
            right: -4px;
            z-index: 9;
            .selected-address-label{
                display: flex;
                cursor: pointer;
                font-weight: 500;
                position: relative;
                overflow: hidden;
                margin-bottom: 0.375em;
                border: 1px solid #ffae00;
                border-radius: 30px;
                .selected-adress-radio{
                    position: absolute;
                    left: -9999px;
                    &:checked + span {
                        background-color: #ffae00b0;
                        color: $fifth-color;
                        box-shadow: 1px 2px 5px #ffae00;
                        &:before {
                            box-shadow: inset 0 0 0 0.4375em $fifth-color;
                        }
                    }
                }
                span {
                    display: flex;
                    align-items: center;
                    padding: 0.375em 0.75em 0.375em 0.375em;
                    transition: 0.25s ease;
                    color: $paragraph-color;
                    font-size: 12.5px;
                    font-weight: 600;
                    &:hover {
                        background-color: #ffae0038;
                    }
                    &:before {
                        display: flex;
                        flex-shrink: 0;
                        content: "";
                        background-color: #fff;
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        margin-right: 0.6em;
                        margin-left: 2px;
                        transition: 0.25s ease;
                        top: 0.5px;
                        box-shadow: inset 0 0 0 0.125em #6b6b6b;
                        position: relative;
                    }
                }
            }
        }
        .edit-customer-address-field {
            display: inline-block;
        }
        .address-remove-btn {
            position: absolute;
            right: -13px;
            top: -22px;
            color: #ef6863;
            padding: 0;
            background: #fff;
            .MuiSvgIcon-root{
                width: 28px;
                height: 28px;
            }
        }
        .Mui-disabled {
            cursor: not-allowed;
            pointer-events: fill;
        }
        .MuiFormLabel-root,
        .MuiInput-input{
            font-family: "Poppins";
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    .customer-add-addr-btn{
        @include button_blue;
        border-radius: 30px;
        font-size: 16px;
    }
    .customer-Edit-addr-btn{
        @include button_blue;
        background: #ffae00 !important;
        font-size: 16px;
        border-radius: 30px;
        color: $fifth-color !important;
        box-shadow: 3px 6px 10px 0px #ffae0054;
        &:hover,
        &:active,
        &:focus{
            background-image: none !important;
            background: #ffae00 !important;
            box-shadow: 3px 6px 10px 0px #ffae0054 !important;
            border-color: transparent !important;
            color: $fifth-color;
        }
    }
}