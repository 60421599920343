.container{
    max-width: 90%;
    margin: 0 auto;
  }
  .row{
    display: flex;
    flex-wrap: wrap;
  }
  .row .img-show-box{
    max-width: 50%;
    flex: 0 0 50%;
  }
  .row  .logo-section{
    max-width: 50%;
    flex: 0 0 50%;
  }
  .row  .logo-section .img-group{
    display: flex;
    flex-wrap: wrap;
  }
  .row  .logo-section .img-group img{
    height: auto;
    margin: 10px;
    max-width:  calc(25% - 20px);
  }




// .logo-selection-page{
//     .logo-image-left{
//         border: 1px solid #dbe0e4;
//     }
//     .choose-design{
//         .title{
//             color: $fifth-color;
//             font-size: 16px;
//             margin-bottom: 10px;
//             margin-left: 5px;
//             text-transform: capitalize;
//         }
//         .all-logo{
//             display: flex;
//             flex-wrap: wrap;
//             border-radius: 3px;
//             border: 2px solid transparent;
//             .single-logo{
//                 width: calc(33% - 20px);
//                 margin: 5px 5px;
//                 padding: 5px;
//                 overflow: hidden;
//                 img{
//                     width: 100%;
//                     height: auto;
//                     object-fit: cover;
//                     border-radius: 3px;
//                 }
//             }
//             .single-logo.active{
//                 border: 2px solid $fifth-color;
//             }
//         }
//     }
//     .choose-design-all-input{
//         margin-top: 20px;
//         .overlay-checkbox{
//             margin-top: 20px;
//             display: inline-block;
//             font-size: 14px;
//             font-weight: 600;
//             margin-left: 5px;
//         }
//         .transparenet-efect-checkbox{
//             position: relative;
//             top: 19px;
//         }
//         .hidden-input{
//             margin: -20px 0 30px 0;
//             transition: 0.3s ease;
//         }
//         .MuiGrid-root{
//             margin: 1.5px 0;
//         }
//         .btn-logo-submit{
//             background: linear-gradient(120deg, #27ccb2 0%, #09bda0 100%) !important;
//             box-shadow: 3px 6px 10px 0px #13b0973d;
//             font-size: 16px;
//             color: $fifth-color;
//             border-radius: 40px;
//         }

//     }
// }