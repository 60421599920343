.privacy-policy-page {
	.return-policy-list-content li {
		color: $content-color;
		line-height: 1.5;
		font-size: 15px;
		font-weight: 400;
	}
	.list-unstyled {
		li {
			margin-bottom: 30px;
			&::before{
				content: unset !important;
			}
			i {
				display: inline;
				color: #33bea6;
				font-size: 25px;
			}
			p {
				display: inline;
				padding-left: 10px;
			}
		}
	}
}