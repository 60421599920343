.feature-section {
    // float: left;
    // margin-left: calc(-1 * ((100vw - 100%) / 2));
    position: relative;
    &::before {
        height: 480px;
        right: 54%;
        width: 645px;
        background: url("../../../../img/camp-3.png") no-repeat center;
        top: 0px;
        content: "";
        position: absolute;
    }
    &::after {
        height: 698px;
        width: 642px;
        left: 55%;
        background: url("../../../../img/summer-camp-1.png") no-repeat center;
        content: "";
        position: absolute;
        bottom: 0px;
    }
    .feature-upper-section {
        .first-feature-area {
            padding: 20px 0 30px;
            width: 100%;
            float: left;
            text-align: left;
            background-color: #fbf6f4;
            .feature-area {
                // width: 50%;
                // float: right;
                padding-left: 10px;
                .feature-content-main-title {
                    margin-bottom: 50px;
                    strong {
                        font-size: 75px;
                        font-weight: bold;
                        color: #b9b7b7;
                    }
                }
                .feature-title {
                    margin-bottom: 15px;
                    .feature-number {
                        font-size: 15px;
                        font-weight: bold;
                        color: #2c2c2c;
                        position: relative;
                        line-height: 30px;
                        vertical-align: top;
                        margin-right: 10px;
                        &::after {
                            content: "";
                            position: absolute;
                            height: 4px;
                            width: 100%;
                            border-radius: 2px;
                            left: 0;
                            bottom: -5px;
                            background-color: #f26431;
                        }
                    }
                    .title-text {
                        font-size: 22px;
                        font-weight: bold;
                        color: #b9b7b7;
                    }
                }
                .feature-content {
                    font-size: 17px;
                    font-weight: normal;
                    color: #2c2c2c;
                    letter-spacing: 0.8px;
                }
            }
        }
        .second-feature-area {
            // width: 100%;
            // float: left;
            // text-align: left;
            background-color: #f26431;
            padding: 75px 0 85px;
            .feature-area {
                // width: 52%;
                // float: right;
                padding-top: 10%;
                padding-left: 10px;
                .feature-title {
                    margin-bottom: 15px;
                    .feature-number {
                        font-size: 15px;
                        font-weight: bold;
                        color: #2c2c2c;
                        position: relative;
                        line-height: 30px;
                        vertical-align: top;
                        margin-right: 10px;
                        &::after {
                            content: "";
                            position: absolute;
                            height: 4px;
                            width: 100%;
                            border-radius: 2px;
                            left: 0;
                            bottom: -5px;
                            background-color: #fff;
                        }
                    }
                    .title-text {
                        font-size: 22px;
                        font-weight: bold;
                        color: #b9b7b7;
                    }
                }
                .feature-content {
                    font-size: 17px;
                    font-weight: normal;
                    color: white;
                    letter-spacing: 0.8px;
                }
            }
        }
    }
    .feature-lower-section {
        .third-feature-area {
            background-color: #ffffff;
            padding: 80px 0 40px;
            // width: 100%;
            // float: left;
            // text-align: left;
            .feature-area {
                // width: 40%;
                // float: left;
                padding-left: 70px;
                .feature-title {
                    margin-bottom: 15px;
                    .feature-number {
                        font-size: 15px;
                        font-weight: bold;
                        color: #2c2c2c;
                        position: relative;
                        line-height: 30px;
                        vertical-align: top;
                        margin-right: 10px;
                        &::after {
                            content: "";
                            position: absolute;
                            height: 4px;
                            width: 100%;
                            border-radius: 2px;
                            left: 0;
                            bottom: -5px;
                            background-color: #f26431;
                        }
                    }
                    .title-text {
                        font-size: 22px;
                        font-weight: bold;
                        color: #b9b7b7;
                    }
                }
                .feature-content {
                    font-size: 17px;
                    font-weight: normal;
                    color: #2c2c2c;
                    letter-spacing: 0.8px;
                }
            }
        }
        .fourth-feature-area {
            background-color: #f5f6f7;
            padding: 50px 0 60px;
            // width: 100%;
            // float: left;
            // text-align: left;
            .feature-area {
                // width: 40%;
                // float: left;
                padding-left: 70px;
                .feature-title {
                    margin-bottom: 15px;
                    .feature-number {
                        font-size: 15px;
                        font-weight: bold;
                        color: #2c2c2c;
                        position: relative;
                        line-height: 30px;
                        vertical-align: top;
                        margin-right: 10px;
                        &::after {
                            content: "";
                            position: absolute;
                            height: 4px;
                            width: 100%;
                            border-radius: 2px;
                            left: 0;
                            bottom: -5px;
                            background-color: #f26431;
                        }
                    }
                    .title-text {
                        font-size: 22px;
                        font-weight: bold;
                        color: #b9b7b7;
                    }
                }
                .feature-content {
                    font-size: 17px;
                    font-weight: normal;
                    color: #2c2c2c;
                    letter-spacing: 0.8px;
                }
            }
        }
        .button-area {
            padding: 60px 0 50px;
            background-color: #502415;
            // width: 100%;
            // float: left;
            // text-align: left;
            .feature-area {
                // width: 50%;
                // float: left;
                padding-left: 70px;
                .button-title {
                    margin-bottom: 30px;
                    .title-text {
                        font-size: 22px;
                        font-weight: bold;
                        color: #fff;
                    }
                }
                .feature-button {
                    .btn {
                        background: -moz-linear-gradient(
                            90deg,
                            rgba(253, 78, 117, 1) 0%,
                            rgba(252, 120, 88, 1) 100%
                        );
                        background: -webkit-gradient(
                            linear,
                            left top,
                            left bottom,
                            color-stop(0%, rgba(252, 120, 88, 1)),
                            color-stop(100%, rgba(253, 78, 117, 1))
                        );
                        background: -webkit-linear-gradient(
                            90deg,
                            rgba(253, 78, 117, 1) 0%,
                            rgba(252, 120, 88, 1) 100%
                        );
                        background: -o-linear-gradient(
                            90deg,
                            rgba(253, 78, 117, 1) 0%,
                            rgba(252, 120, 88, 1) 100%
                        );
                        background: -ms-linear-gradient(
                            90deg,
                            rgba(253, 78, 117, 1) 0%,
                            rgba(252, 120, 88, 1) 100%
                        );
                        background: linear-gradient(
                            0deg,
                            rgba(253, 78, 117, 1) 0%,
                            rgba(252, 120, 88, 1) 100%
                        );
                        padding: 20px 40px;
                        font-size: 20px;
                        font-weight: 500;
                        color: #fff;
                        border-radius: 32px;
                        -webkit-border-radius: 32px;
                        -moz-border-radius: 32px;
                    }
                }
            }
        }
    }
}
