.customer-my-details-sec{
    .MuiInputBase-root{
        font-family: "Poppins";
    }
    .customer-img-sec{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 20px 0 30px 0;
        .thumbnail{
            border-radius: 50%;
            padding: 5px;
            box-shadow: 0 2px 20px 0px #3a62981a;
            max-width: unset;
            width: 130px;
            height: 130px;
        }
        button.btn.edit-select-btn{
            @include button_blue;
            background-image: none !important;
            background: none !important;
            box-shadow: none;
            color: $orrange_color !important;
            font-size: 13px;
            border: 2px solid $orrange_color;
            padding: 6px 12px;
            margin: 3px auto;
            &:hover, &:active, &:visited, &:focus {
                background-image: none !important;
                background: none !important;
                box-shadow: none !important;
                color: $orrange_color !important;
                border: 2px solid $orrange_color !important;
            }
        }
        img{
            height: 130px;
            width: 130px;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    .personal-info-sec,
    .location-info-sec{
        .title{
            margin-bottom: 15px;
        }
        padding: 0 15px;
        .MuiGrid-root {
            margin: 2px auto;
            width: 100%;
            .MuiIcon-root {
                color: $input-color;
                font-size: 1.2rem;
                width: 1.2em;
            }
            label {
                .Mui-focused {
                    color: $title-color-light;
                }
            }
            .MuiFormLabel-root {
                color: $input-color ;
                font-size: 14px;
                font-weight: 600;
                font-family: 'Poppins';
            }
            .MuiFormLabel-filled {
                font-weight: 600;
                color: $title-color;
            }
            .MuiInput-underline {
                &::after {
                    border-bottom-color: $input-color;
                }
                &::before {
                    border-bottom-color: $input-color;
                }
            }
        }
    }
    .my-details-updates-sec{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        .btn.my-details-edit-btn{
            @include button_blue;
            font-size: 16px;
            text-transform: uppercase;
            border-radius: 30px;
            transform: none;
            letter-spacing: 0.5px;
            i{
                margin-right: 5px;
            }
        }
        .btn.my-details-update-btn{
            @include button_blue;
            background: #ffae00 !important;
            box-shadow: 3px 6px 10px 0px #ffae0054;
            font-size: 16px;
            border-radius: 40px;
            margin-right: 10px;
            color: $black-color !important;
            &:hover, &:focus, &:visited, &:active{
                background: #ffae00 !important;
                box-shadow: 3px 6px 10px 0px #ffae0054 !important;
                color: $black-color !important;
            }
        }
        .btn.my-details-cancel-btn{
            @include red_button;
            font-size: 16px;
            border-radius: 40px;
        }
    }
}

.edit-customer-details-modal{
    max-width: 700px;
    transform: none !important;
    .modal-content {
        border: none;
        border-radius: 15px;
        box-shadow: 0 0.14px 2.29266px rgba(0, 0, 0, 0.03231), 0 0.37px 4.42626px rgba(0, 0, 0, 0.04769), 0 3px 7px rgba(0, 0, 0, 0.09);
        .modal-header {
            justify-content: center;
            .modal-title {
                color: $md-title;
                font-weight: 600;
                text-transform: capitalize;
                line-height: 1.3;
                margin-bottom: 10px;
                margin-top: 10px;
                font-family: 'Poppins';
                position: relative;
                font-size: 20px;
                &::before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    display: inline-block;
                    background: $orange-btn;
                    position: relative;
                    top: -3px;
                    margin-right: 8px;
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 80px;
                    height: 4px;
                    background-image: linear-gradient(120deg, #efd5a8 0%, #ffae00 100%);
                    left: 1px;
                    bottom: -8px;
                    border-radius: 4px;
                }
            }
            .close{
                font-size: 24px;
                font-weight: 500;
            }
        }
        .modal-body {
            padding: 25px;  
            .edit-customer-image {
                display: block !important;
                .thumbnail {
                    border-radius: 60px;
                    .uploaded-image {
                        height: 100px;
                        width: 100px;
                        object-fit: cover;
                    }
                }
                .edit-select-btn {
                    background: $orange-btn;
                    font-size: 12px;
                    padding: 10px 20px;
                    font-weight: 600;
                    border-radius: 30px;
                    color: $black-color;
                    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
                    &:hover, &:focus {
                        background: $orange-btn !important;  
                    }    
                }
            }
        } 
        .modal-footer {
            padding: 0 35px 25px;
            .edit-customer-btn {
                background: $orange-btn;
                font-size: 18px;
                font-weight: 600;
                color: $black-color;
                min-width: 200px;
                box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
                &:hover , &:active, &:focus {
                    background: $orange-btn!important;
                    transform: none !important; 
                    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4); 
                }
            }
        }
    }
} 