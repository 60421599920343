.admin-profile-details-main {
    padding: 60px 80px !important;  
    .edit-customer-image {
        display: flex !important;
        position: relative;
        margin: 0 80px 10px;
        justify-content: center;
        .uploaded-image {
            object-fit: cover;
            height: 200px;
            width: 200px;
        }
        .edit-customer-image-inner {
            position: relative;
            text-align: left;
            margin-left: 15px;
            margin-top: 70px;
            .edit-customer-image-inner-div {
                .edit-select-btn {
                    background: $orange-btn;
                    font-size: 12px;
                    padding: 10px 20px;
                    font-weight: 600;
                    border-radius: 30px;
                    color: $black-color;
                    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
                    margin: 25px 0 20px;
                    &:hover, &:focus {
                        background: $orange-btn !important;  
                    }    
                }
                .edit-customer-image-condition {
                    color: $paragraph-color;
                    margin: 5px 0 0;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
    }
    .MuiGrid-root {
        margin: 2px auto;
        .MuiIcon-root {
            color: $input-color;
            font-size: 1.2rem;
            width: 1.2em;
        }
        label {
            .Mui-focused {
                color: $title-color-light;
            }
        }
        .MuiFormLabel-root {
            color: $input-color ;
            font-size: 14px;
            font-weight: 600;
            font-family: 'Poppins';
        }
        .MuiFormLabel-filled {
            font-weight: 600;
            color: $title-color;
        }
        .MuiInput-underline {
            &::after {
                border-bottom-color: $input-color;
            }
            &::before {
                border-bottom-color: $input-color;
            }
        }
    }
    .custom-radio-item{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        .custom-radio-button{
            width: calc(50% - 5px);
            height: 100%;
            appearance: none;
            outline: none;
            cursor: pointer;
            border-radius: 2px;
            padding: 7.5px 18.5px;
            margin-right: 10px;
            background: #efefef;
            color: $fifth-color;
            font-size: 15px;
            font-weight: 600;
            transition: all 100ms linear;
            box-shadow: 1 1px 5px #efefef;
            &::before{
                content: attr(label);
                display: inline-block;
                text-align: center;
                width: 100%;
            }
            &:checked{
                background: $orrange_color;
                color: $fifth-color;
                box-shadow:1px 1px 5px #ffae0078;
                
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .edit-admin-section {
        padding:0 10px 20px;
        margin: 20px auto 30px;
        position: relative;
        border-radius: 4px;
        legend {
            width: fit-content;
            padding: 0 5px;
            font-size: 16px;
            color: $paragraph-color;
            font-weight: 600;
            margin: 0 0 0 15px;
            font-family: 'Poppins';
            position: relative;
            span{
                display: inline-block;
                line-height: 0.9;
                background: $orrange_color;
                border-radius: 30px;
                padding: 5px 15px;
                margin-left: 5px;
                color: $fifth-color;
            }
            &::before {
                content: '';
                width: 10px;
                height: 10px;
                border-radius: 50%;
                display: inline-block;
                background: $orrange_color;
                position: relative;
                top: 0;
                margin-right: 8px;
            }
            &::after {
                content: '';
                position: absolute;
                width: 60px;
                height: 4px;
                background-image: linear-gradient(120deg, #efd5a8 0%, $orrange_color 100%);
                left: 5px;
                bottom: -8px;
                border-radius: 4px;
            }
        }
        .col-md-6 {
            display: inline-block;
        }
        .edit-admin-field {
            display: inline-block;
        }
    }
    .edit-admin-pass-section {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }
    .admin-update-btn {
        text-align: center;
        .edit-admin-btn {
            background: $orange-btn;
            font-size: 18px;
            font-weight: 600;
            color: $black-color;
            min-width: 200px;
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
            &:hover , &:active, &:focus {
                color: $black-color;
                background: $orange-btn!important;
                transform: none !important; 
                box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4); 
            }
        }
    }
}
