.admin-portal-table-sec {
    .admin-details-data-card {
        margin: 0;
        .card-header {
            padding: 0 0 15px 0;
            .card-title {
                color: #0D47A1;
                font-weight: 700;
                font-size: 35px;
                font-family: 'Poppins';
                line-height: 1.3;
                margin-bottom: 20px;
            }
        }
        .admin-details-table-cardbody {
            box-shadow: 0 0.14px 2.29266px rgba(0, 0, 0, 0.03231), 0 0.37px 4.42626px rgba(0, 0, 0, 0.04769), 0 3px 7px rgba(0, 0, 0, 0.09);
            border-radius: 20px;
            background: $nav-color;
            .table-responsive {
                margin-bottom: 0 !important;
                .empty-header {
                    width: 5%;
                }
            }
            tr {
                th {
                    text-align: center;
                    color: $title-color-dark !important;
                    text-transform: uppercase;
                    font-weight: 600 !important;
                    font-size: 16px !important;
                    padding: 12px 7px;
                    min-width: 100px;
                }
                td {
                    text-align: center;
                    color: $black-color !important;
                    font-weight: 500 !important;
                    font-size: 15px !important;
                    border-top: 0 !important;
                    padding: 12px 7px;
                }
            }
            
            tbody {
                tr {
                    cursor: pointer;
                    td{
                        min-width: 130px;
                        position: relative;
                        .pending{
                            color: #8a5f05;
                            background: #fbefd0;
                            padding: 10px 15px;
                            width: fit-content;
                            margin: 0 auto;
                            border-radius: 20px;
                            text-transform: uppercase;
                            font-weight: 600;
                            letter-spacing: 0.5px;
                        }
                        .deliver{
                            color: $sixth-color;
                            background: #b8c5ea3d;
                            padding: 10px 15px;
                            width: fit-content;
                            margin: 0 auto;
                            border-radius: 20px;
                            text-transform: uppercase;
                            font-weight: 600;
                            letter-spacing: 0.5px;
                        }
                    }
                    
                    td:first-child{
                        display: flex;
                        flex-wrap: wrap-reverse;
                        &::before{
                            content: '';
                            width: 4px;
                            height: 0;
                            display: block;
                            position: absolute;
                            transition: 0.3s;
                            left: 0;
                            transition-timing-function: cubic-bezier(0.58, 0.3, 0.15, 0.46);
                            border-radius: 20px;
                        }
                    }
                    &:hover{
                        border-left: 0 !important;
                        background-color: $details-btn !important;
                        td:first-child{
                            &::before{
                                transform: scale(1);
                                background-color: #242f67de;
                                height: 100%;
                                left: 0;
                                top: 0;
                            }
                        }
                    }
                    .order-img {
                        float: left;
                        img {
                            width: 60px;
                            height: 60px;
                            margin-right: 10px;
                        }
                    }
                }
            }
            .customer-details-table{
                thead th,
                tbody td{
                    min-width: 120px;
                }
                thead th:first-child,
                tbody td:first-child{
                    min-width: 140px;
                }
                
                
            }
            .admin-order-search-sec{
                margin: 15px 30px 30px 0;
                .MuiInput-underline {
                    &::after {
                        border-bottom-color: $title-color-dark;
                    }
                    &::before {
                        border-bottom-color: $title-color-dark;
                        // border-width: 2px;
                    }
                }
                input{
                    font-weight: 500;
                    color: $title-color-dark;
                    font-family: "Poppins";
                    &::placeholder{
                        font-weight: 500;
                        color: $title-color-dark;
                        font-family: "Poppins";
                    }
                }
            }
        }
        .orders-table-pagination{
            .MuiTablePagination-caption{
                color: $paragraph-color;
                font-size: 13.5px;
                font-family: "Poppins";
                text-transform: capitalize;
                font-weight: 500;
            }
        }
    }
}