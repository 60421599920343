.personalize-main-section {
  padding: 0 10px 0;
  position: relative;
  .personalize-section-title {
    color: $fifth-color;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 1.3;
    margin-bottom: 25px;
    margin-top: 15px;
    font-family: "Poppins";
    position: relative;
    font-size: 16px;
    &::after {
      content: "";
      position: absolute;
      width: 75px;
      height: 3.5px;
      background-image: linear-gradient(120deg, #efd5a8 0%, $orange-btn 100%);
      left: 1px;
      bottom: -5px;
      border-radius: 4px;
    }
  }
  .personalize-grid-section-row {
    box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
    padding: 5px 0;
    border-radius: 4px;
    margin-top: 10px;
    .personalize-grid-section-text {
      margin: 10px 0;
      box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
      background: #e3e3e338;
      padding: 10px;
      height: 82%;
      .color-picker-text-section {
        align-items: center;
        justify-content: center;
        display: flex;
        margin-top: 12px;
      }
    }
    .personalize-grid-section-color {
      display: flex;
      margin: 10px 0;
      padding: 10px 5px;
      box-shadow: 0 0 1px 0 #185ee026, 0 1px 3px 0 rgba(24, 94, 224, 0.15);
      background: #e3e3e338;
      position: relative;
      .color-picker-section {
        position: absolute;
        right: 8px;
        top: 6px;
      }
      .color-picker-text-section {
        .select-color-heading {
          color: $fifth-color;
          font-size: 12px;
          font-weight: 600;
          font-family: "Poppins";
          text-transform: capitalize;
        }
      }
      .color-picker-section {
        > div {
          display: flex;
          > div {
            padding: unset !important;
            background: unset !important;
            border-radius: 4px !important;
            box-shadow: unset !important;
            display: inline-block !important;
            left: 10px;
            top: 30px;
            > div {
              min-width: 45px;
              min-height: 25px;
            }
          }
        }
      }
    }
    .colors-section,
    .text-section{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 25px;
      input{
        font-family: "Poppins";
        font-weight: 600;
        color: $paragraph-color;
      }
    }
    .button-section {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0;
      .col-12{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        justify-content: center;
        #upload_mascot{
          display:none;
        }
        .mascot-upload-btn{
          background: $orange-btn;
          font-size: 15px;
          padding: 12px 25px;
          font-weight: 600;
          border-radius: 30px;
          color: $black-color;
          box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
          margin-bottom: 0px;
          text-transform: capitalize;
          font-family: "Poppins";
          &:hover,
          &:focus,
          &:active {
            background: $orange-btn !important;
            color: $black-color;
          }
        }
        .btn.btn-preview{
          font-size: 15px !important;
          padding: 12px 25px;
          font-weight: 600;
          border-radius: 30px;
          @include button_blue;
          margin-right: 10px;
        }
        .mascot-upload-img-sec{
          position: relative;
          width: 80%;
          margin: 0 auto 20px;
          .btn.btn-danger{
            position: absolute;
            right: -15px;
            width: 30px;
            height: 30px;
            padding: 0;
            border-radius: 50%;
            top: -16px;
          }

        }
      }
    }
    .colors-background{
      height: 20px;
      width: 25px;
      border-radius: 2px;

    }
  }
  .MuiGrid-root {
    margin: 2px auto;
    width: 100%;
    .MuiIcon-root {
      color: $input-color;
      font-size: 1.2rem;
      width: 1.2em;
    }
    label {
      .Mui-focused {
        color: $title-color-light;
      }
    }
    .MuiFormLabel-root {
      color: $input-color;
      font-size: 14px;
      font-weight: 600;
      font-family: "Poppins";
    }
    .MuiFormLabel-filled {
      font-weight: 600;
      color: $title-color;
    }
    .MuiInput-underline {
      &::after {
        border-bottom-color: $input-color;
      }
      &::before {
        border-bottom-color: $input-color;
      }
    }
    .MuiInputBase-input {
      font-family: "Poppins";
      font-weight: 500;
      color: $fifth-color;
    }
  }
  .personalization-mascot-upload {
    justify-content: center;
    display: flex;
    .mascot-upload-btn {
      background: $orange-btn;
      font-size: 17px;
      padding: 12px 30px;
      font-weight: 600;
      border-radius: 30px;
      color: $black-color;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
      margin-top: 30px;
      margin-bottom: 0px;
      text-transform: capitalize;
      font-family: "Poppins";
      &:hover,
      &:focus,
      &:active {
        background: $orange-btn !important;
        color: $black-color;
      }
    }
  }
  .btn.prev-design {
    transform: unset !important;
    padding: 0 0 5px 0;
    background: transparent;
    color: #242F67;
    border-radius: 25px;
    margin: 2px !important;
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins";
    text-align: left;
    line-height: 1;
    left: -16px;
    border-radius:0;
    border-bottom: 1px dashed $title-color-dark;
    overflow: visible;
    i {
      color: $title-color-dark;
      margin-right: 5px;
      padding-left: 0;
      position: relative;
      transition: .3s;
      left: 0;
    }
    &:hover,
    &:active,
    &:focus {
      background-image: none !important;
      background: transparent !important;
      color: $title-color-dark;
      box-shadow: none !important;
      i{
        left: -5px;
      }
    }
  }
}

@import "./upload-mascot/upload-mastcot-finalize.scss";
@import "./upload-mascot/upload-mascot-condition.scss";
@import "./upload-mascot/upload-mascot-edit.scss";
